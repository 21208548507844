import { createIcon } from '@chakra-ui/icons';

const WarningIcon = createIcon({
  viewBox: '0 0 19 16',
  path: (
    <g data-id="0522a46f9470">
      <path
        d="M7.983 1.26021L1.29489 12.4255C1.15699 12.6643 1.08403 12.935 1.08326 13.2108C1.08249 13.4865 1.15393 13.7577 1.29048 13.9972C1.42704 14.2368 1.62394 14.4365 1.8616 14.5763C2.09927 14.7161 2.3694 14.7913 2.64514 14.7943H16.0214C16.2971 14.7913 16.5672 14.7161 16.8049 14.5763C17.0426 14.4365 17.2395 14.2368 17.376 13.9972C17.5126 13.7577 17.584 13.4865 17.5832 13.2108C17.5825 12.935 17.5095 12.6643 17.3716 12.4255L10.6835 1.26021C10.5427 1.02814 10.3445 0.836272 10.108 0.703113C9.87151 0.569954 9.60467 0.5 9.33325 0.5C9.06183 0.5 8.79499 0.569954 8.55848 0.703113C8.32196 0.836272 8.12376 1.02814 7.983 1.26021V1.26021Z"
        data-id="cea26c9a1617"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.33325 5.31885V8.47734"
        data-id="a4456a369252"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.33325 11.6357H9.34273"
        data-id="40d4756e51ca"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default WarningIcon;
