import { createIcon } from '@chakra-ui/icons';

const Trashcan = createIcon({
  viewBox: '0 0 13 15',
  path: (
    <g data-id="6d0c061b6363">
      <path
        d="M10.1869 14.3748H3.77019C3.52707 14.3748 3.29391 14.2782 3.122 14.1063C2.95009 13.9344 2.85352 13.7012 2.85352 13.4581V5.20801H11.1036V13.4581C11.1036 13.7012 11.007 13.9344 10.8351 14.1063C10.6632 14.2782 10.43 14.3748 10.1869 14.3748Z"
        data-id="db46776f917f"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.60352 11.6252V7.9585"
        data-id="2f9886f36c62"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8.35352 11.6252V7.9585"
        data-id="fad85f3e0d84"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M0.5625 3.96282L12.2189 1.48535"
        data-id="2b40dfeb1d85"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.35383 0.645033L4.66491 1.21643C4.54689 1.24133 4.43494 1.28929 4.33549 1.35754C4.23603 1.42579 4.15102 1.513 4.08534 1.61417C4.01965 1.71534 3.97458 1.82848 3.9527 1.9471C3.93083 2.06572 3.93258 2.18749 3.95785 2.30544L4.15096 3.20072L8.63412 2.24738L8.44101 1.35087C8.39041 1.11312 8.24745 0.905197 8.04358 0.772832C7.8397 0.640466 7.5916 0.594497 7.35383 0.645033V0.645033Z"
        data-id="412ef9243a59"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default Trashcan;
