import { createIcon } from '@chakra-ui/icons';

const Move = createIcon({
  viewBox: '0 0 12 9',
  path: (
    <g data-id="9414dedf059a" opacity="0.5">
      <path
        d="M1 4.5H11.1111"
        data-id="87114c719f43"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M1 1H11.1111"
        data-id="56966ea41dfc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M1 8H11.1111"
        data-id="a595a443aefb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default Move;
