import { createIcon } from '@chakra-ui/icons';

const Asterisk = createIcon({
  viewBox: '0 0 11 12',
  displayName: 'Asterisk Question',
  path: (
    <g data-id="9b8ee1cc5e93">
      <path
        d="M5.5 1.5V10.5"
        data-id="dc56102e37de"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M10 6H1"
        data-id="1e5dfd601695"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M8.68179 2.81787L2.31836 9.18173"
        data-id="d7052ea079a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M8.68179 9.18173L2.31836 2.81787"
        data-id="3c61f2e9a1ef"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default Asterisk;
