import { createIcon } from '@chakra-ui/icons';

const ComingUpIcon = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <g data-id="d64a06fb476b">
      <rect data-id="4d7c078bbfa5" height="24" rx="12" width="24" />
      <path
        d="M11.875 18.125C15.3268 18.125 18.125 15.3268 18.125 11.875C18.125 8.42322 15.3268 5.625 11.875 5.625C8.42322 5.625 5.625 8.42322 5.625 11.875C5.625 15.3268 8.42322 18.125 11.875 18.125Z"
        data-id="bd0bcd77c393"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M11.875 8.12512V11.8751L14.375 13.1251"
        data-id="f42b4006f98f"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
    </g>
  ),
});

export default ComingUpIcon;
