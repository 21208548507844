import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

import Header from '../components/Header';

function PrivacyPolicy() {
  return <Flex data-id="0817a5361b86" direction="column" h="full" w="full">
    <Header
      breadcrumbs={['Home', 'Privacy policy']}
      data-id="64a15d61b160"
      mobileBreadcrumbs={['Privacy policy']} />
    <Flex
      bg="white"
      borderRadius="20px"
      data-id="820f78a77f6b"
      flexDirection="column"
      h="full"
      maxWidth="full"
      mb={['25px', '25px']}
      ml="7"
      mr="25px"
      p="25px 30px 25px 30px">
      <Text data-id="bd5ebe7d933b" fontSize="14px" mb="30px">
        To be added.
      </Text>
    </Flex>
  </Flex>
}

export default PrivacyPolicy;
