import { createIcon } from '@chakra-ui/icons';

const UnsafeCondition = createIcon({
  viewBox: '0 0 21 23',
  path: (
    <g data-id="b7f54b7bdf84">
      <path
        d="M17 9L17 7.66667M10.3333 1H2.06667C1.47756 1 1 1.63675 1 2.42222V20.9111C1 21.6966 1.47756 22.3333 2.06667 22.3333L17 22.3333V21M10.3333 1V6.33333C10.3333 7 11 7.66667 11.6667 7.66667H17M10.3333 1L17 7.66667"
        data-id="4accf8870447"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 17H8"
        data-id="baa9a1edf220"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 14H10"
        data-id="f11b6e155d03"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 11H12"
        data-id="641e8b0e0d73"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M14.6673 10.9473C15.0064 10.3149 15.9132 10.3149 16.2523 10.9473L19.8116 17.5865C20.1328 18.1856 19.6988 18.9106 19.0192 18.9106H11.9004C11.2208 18.9106 10.7868 18.1856 11.108 17.5865L14.6673 10.9473Z"
        data-id="011636155ca1" />
      <path
        clipRule="evenodd"
        d="M15.4598 13.0659C15.7081 13.0659 15.9094 13.2672 15.9094 13.5155L15.9094 15.3138C15.9094 15.5622 15.7081 15.7634 15.4598 15.7634C15.2115 15.7634 15.0102 15.5622 15.0102 15.3138L15.0102 13.5155C15.0102 13.2672 15.2115 13.0659 15.4598 13.0659Z"
        data-id="f80279b602b2"
        fillRule="evenodd" />
      <circle cx="15.4598" cy="17.1122" data-id="e5ec143c4607" r="0.449595" />
    </g>
  ),
});

export default UnsafeCondition;
