import { createIcon } from '@chakra-ui/icons';

const RequiredIcon = createIcon({
  viewBox: '0 0 11 11',
  path: (
    <g data-id="e6852e750179">
      <path
        d="M5.5 1V10"
        data-id="cb5b4cf638b8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M10 5.5H1"
        data-id="889e97455ab6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M8.68179 2.31787L2.31836 8.68173"
        data-id="fcc3c34c0ee2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M8.68179 8.68173L2.31836 2.31787"
        data-id="e108a21c8b77"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default RequiredIcon;
