import { createIcon } from '@chakra-ui/icons';

const AuditIcon = createIcon({
  path: (
    <>
      <path
        d="M6.99922 7.38967C8.71766 7.38967 10.1107 5.99659 10.1107 4.27815C10.1107 2.5597 8.71766 1.16663 6.99922 1.16663C5.28077 1.16663 3.8877 2.5597 3.8877 4.27815C3.8877 5.99659 5.28077 7.38967 6.99922 7.38967Z"
        data-id="f5031f1fdc52"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8.03644 4.27817H6.99927V2.72241"
        data-id="8bd88418e528"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.6665 3.40954C11.96 3.38983 12.2603 3.37738 12.5642 3.37272C12.6353 3.37149 12.7039 3.39854 12.755 3.44791C12.78 3.47225 12.7998 3.50137 12.8133 3.53354C12.8267 3.5657 12.8336 3.60025 12.8333 3.63512V10.6096C12.833 10.6785 12.8056 10.7446 12.7572 10.7936C12.7087 10.8426 12.643 10.8708 12.5741 10.872C9.5787 10.9161 6.99925 11.7194 6.99925 12.7052C6.99925 11.7199 4.41979 10.9161 1.42599 10.8699C1.35707 10.8687 1.29135 10.8406 1.2429 10.7915C1.19444 10.7425 1.16709 10.6765 1.1667 10.6075V3.63305C1.16637 3.59835 1.17294 3.56393 1.18603 3.53179C1.19911 3.49965 1.21846 3.47043 1.24293 3.44584C1.29409 3.39652 1.36272 3.36948 1.43377 3.37064C1.73282 3.37479 2.03187 3.38689 2.33093 3.40694"
        data-id="1039b59f4bda"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6.99927 9.07507V12.6398"
        data-id="a5c4538050d8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 14 14',
  displayName: 'Audit Log',
});

export default AuditIcon;
