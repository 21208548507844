import { createIcon } from '@chakra-ui/icons';

const UnsafeAct = createIcon({
  viewBox: '0 0 19 20',
  path: (
    <g data-id="61483e3fe7c6">
      <path
        d="M3.56665 6.66666C3.56665 7.63912 3.99159 8.57176 4.74799 9.25939C5.50438 9.94702 6.53028 10.3333 7.59998 10.3333C8.66969 10.3333 9.69558 9.94702 10.452 9.25939C11.2084 8.57176 11.6333 7.63912 11.6333 6.66666"
        data-id="41a190201583"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6.61024 1.06584C6.90252 1.02228 7.19999 1 7.5 1C7.77697 1 8.0518 1.01899 8.32235 1.05618M6.61024 1.06584C6.40392 1.09659 6.20019 1.13794 6 1.18953C5.1049 1.42023 4.28048 1.85577 3.61091 2.46447C2.57946 3.40215 3.5 5 2 6H13C11.5 5 12.4205 3.40215 11.3891 2.46447C10.7195 1.85577 9.8951 1.42023 9 1.18953C8.77804 1.13233 8.55173 1.08771 8.32235 1.05618M6.61024 1.06584V3.5M8.32235 1.05618L8.35221 3.5"
        data-id="0d8c8a0f125c"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 17.6667C1 16.0754 1.69536 14.5492 2.9331 13.424C4.17084 12.2988 5.84957 11.6667 7.6 11.6667C8.9493 11.6667 10.0974 12.0874 11.1197 12.7957"
        data-id="32537fb0730e"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.6187 10.6439C12.9957 9.9406 14.0043 9.9406 14.3813 10.6439L18.3397 18.0275C18.6969 18.6937 18.2143 19.5 17.4584 19.5H9.54158C8.78571 19.5 8.3031 18.6937 8.66025 18.0275L12.6187 10.6439Z"
        data-id="ac5d231ffa91" />
      <path
        clipRule="evenodd"
        d="M13.5 13C13.7761 13 14 13.2239 14 13.5L14 15.5C14 15.7761 13.7761 16 13.5 16C13.2239 16 13 15.7761 13 15.5L13 13.5C13 13.2239 13.2239 13 13.5 13Z"
        data-id="742156e0081b"
        fillRule="evenodd" />
      <circle cx="13.5" cy="17.5" data-id="267b4ea1565e" r="0.5" />
    </g>
  ),
});

export default UnsafeAct;
