import { createIcon } from '@chakra-ui/icons';

const InvestigationIcon = createIcon({
  viewBox: '0 0 16 18',
  path: (
    <g data-id="07ef94aa1aa8">
      <path
        d="M1.00002 3.25693V8.67111C1.00004 10.3824 1.51863 12.0536 2.48744 13.4643C3.45624 14.8749 4.82978 15.9589 6.427 16.5734L7.16724 16.8578C7.66019 17.0474 8.20596 17.0474 8.69891 16.8578L9.43915 16.5734C11.0364 15.9589 12.4099 14.8749 13.3787 13.4643C14.3475 12.0536 14.8661 10.3824 14.8661 8.67111V3.25693C14.8675 3.05319 14.81 2.8534 14.7005 2.68158C14.591 2.50976 14.4342 2.37324 14.2489 2.28843C12.2578 1.41934 10.1055 0.980512 7.93307 1.00066C5.76062 0.980512 3.60839 1.41934 1.61724 2.28843C1.43199 2.37324 1.27518 2.50976 1.16569 2.68158C1.05619 2.8534 0.998661 3.05319 1.00002 3.25693V3.25693Z"
        data-id="00ddc9a146e2"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3" />
      <path
        d="M4.19971 7.40055C4.19971 8.24921 4.53684 9.06311 5.13693 9.6632C5.73702 10.2633 6.55092 10.6004 7.39958 10.6004C8.24823 10.6004 9.06213 10.2633 9.66223 9.6632C10.2623 9.06311 10.5994 8.24921 10.5994 7.40055C10.5994 6.55189 10.2623 5.738 9.66223 5.1379C9.06213 4.53781 8.24823 4.20068 7.39958 4.20068C6.55092 4.20068 5.73702 4.53781 5.13693 5.1379C4.53684 5.738 4.19971 6.55189 4.19971 7.40055V7.40055Z"
        data-id="dc81fd111224"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3" />
      <path
        d="M9.6626 9.66309L11.3998 11.4003"
        data-id="314bd4dc9718"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3" />
    </g>
  ),
});

export default InvestigationIcon;
