import { createIcon } from '@chakra-ui/icons';

const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 17 18',
  path: (
    <g data-id="2801fa11d9bb">
      <path
        d="M13.8 2.60001H2.6C1.71634 2.60001 1 3.31635 1 4.20001V15.4C1 16.2837 1.71634 17 2.6 17H13.8C14.6837 17 15.4 16.2837 15.4 15.4V4.20001C15.4 3.31635 14.6837 2.60001 13.8 2.60001Z"
        data-id="f3bcfd47bb03"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M11.4004 1V4.2"
        data-id="5087a394d8a4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 1V4.2"
        data-id="24f147251ffc"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M1 7.39999H15.4"
        data-id="1a0ab8c94eec"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default CalendarIcon;
