import { createIcon } from '@chakra-ui/icons';

const QuestionToggleIcon = createIcon({
  viewBox: '0 0 39 25',
  path: (
    <g data-id="36a83ecb9956">
      <path
        d="M26.0325 1H12.6818C6.23013 1 1 6.23013 1 12.6818C1 19.1335 6.23013 24.3636 12.6818 24.3636H26.0325C32.4842 24.3636 37.7143 19.1335 37.7143 12.6818C37.7143 6.23013 32.4842 1 26.0325 1Z"
        data-id="a60ea8cf9115"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M25.7607 19.7841C29.5332 19.7841 32.5913 16.7259 32.5913 12.9535C32.5913 9.18107 29.5332 6.12292 25.7607 6.12292C21.9883 6.12292 18.9302 9.18107 18.9302 12.9535C18.9302 16.7259 21.9883 19.7841 25.7607 19.7841Z"
        data-id="21616b312335"
        fill="currentColor"
        stroke="#2B3236"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M28.0605 11.566L24.6452 14.9813L23.0928 13.4289"
        data-id="72dbdd025818"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default QuestionToggleIcon;
