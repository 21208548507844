import { createIcon } from '@chakra-ui/icons';

const ChevronRight = createIcon({
  displayName: 'Chevron right',
  viewBox: '0 0 8 14',
  path: <path
    d="M1 13l6-6-6-6"
    data-id="216ea565118b"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round" />,
});

export default ChevronRight;
