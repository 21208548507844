import { createIcon } from '@chakra-ui/icons';

const GridIcon = createIcon({
  path: (
    <g data-id="0ae61680bbae">
      <path
        d="M7.26923 1H1.73077C1.05103 1 0.5 1.55103 0.5 2.23077V7.76923C0.5 8.44897 1.05103 9 1.73077 9H7.26923C7.94897 9 8.5 8.44897 8.5 7.76923V2.23077C8.5 1.55103 7.94897 1 7.26923 1Z"
        data-id="34d475dea512"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.26923 11H1.73077C1.05103 11 0.5 11.551 0.5 12.2308V17.7692C0.5 18.449 1.05103 19 1.73077 19H7.26923C7.94897 19 8.5 18.449 8.5 17.7692V12.2308C8.5 11.551 7.94897 11 7.26923 11Z"
        data-id="ce06ec8d4505"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M17.2692 1H11.7308C11.051 1 10.5 1.55103 10.5 2.23077V7.76923C10.5 8.44897 11.051 9 11.7308 9H17.2692C17.949 9 18.5 8.44897 18.5 7.76923V2.23077C18.5 1.55103 17.949 1 17.2692 1Z"
        data-id="3aacff8e83ac"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M17.2692 11H11.7308C11.051 11 10.5 11.551 10.5 12.2308V17.7692C10.5 18.449 11.051 19 11.7308 19H17.2692C17.949 19 18.5 18.449 18.5 17.7692V12.2308C18.5 11.551 17.949 11 17.2692 11Z"
        data-id="5d14a6b30275"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 19 20',
});

export default GridIcon;
