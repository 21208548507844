import { createIcon } from '@chakra-ui/icons';

const Eye = createIcon({
  path: (
    <g data-id="5cee7a5dd799">
      <path
        d="M1 5.727S3.364 1 7.5 1 14 5.727 14 5.727s-2.364 4.727-6.5 4.727S1 5.727 1 5.727z"
        data-id="dd1272a031f9"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.5 7.5a1.773 1.773 0 100-3.546 1.773 1.773 0 000 3.546z"
        data-id="a035edd97625"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
  viewBox: '0 0 15 12',
});

export default Eye;
