import { createIcon } from '@chakra-ui/icons';

const DownloadIcon = createIcon({
  viewBox: '0 0 18 16',
  displayName: 'Download Icon',
  path: (
    <g data-id="ce7518c424b2">
      <path
        d="M7 0.583252V6.93881"
        data-id="e22c322a26af"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4.6167 4.55542L7.00003 6.93875L9.38337 4.55542"
        data-id="0201fc2da1c7"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.9582 8.56372V9.35816C12.9582 9.77956 12.7908 10.1837 12.4928 10.4817C12.1948 10.7797 11.7907 10.9471 11.3693 10.9471H2.63039C2.20899 10.9471 1.80485 10.7797 1.50688 10.4817C1.2089 10.1837 1.0415 9.77956 1.0415 9.35816V8.56372"
        data-id="0b6046a6b6fd"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default DownloadIcon;
