import { createIcon } from '@chakra-ui/icons';

const OpenExternalIcon = createIcon({
  viewBox: '0 0 14 14',
  displayName: 'Open external icon',
  path: (
    <g data-id="fb1f602a933f">
      <path
        d="M11 7.66667V11.6667C11 12.0203 10.8595 12.3594 10.6095 12.6095C10.3594 12.8595 10.0203 13 9.66667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V4.33333C1 3.97971 1.14048 3.64057 1.39052 3.39052C1.64057 3.14048 1.97971 3 2.33333 3H6.33333"
        data-id="90ca4ba75c15"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M9 1H13V5"
        data-id="bbef408b0404"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5.6665 8.33333L12.9998 1"
        data-id="9deb874507ef"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default OpenExternalIcon;
