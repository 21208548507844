import { createIcon } from '@chakra-ui/icons';

const SafeBehaviour = createIcon({
  viewBox: '0 0 20 21',
  path: (
    <g data-id="f8123b453ccd">
      <path
        d="M3.56665 6.66666C3.56665 7.63912 3.99159 8.57176 4.74799 9.25939C5.50438 9.94702 6.53028 10.3333 7.59998 10.3333C8.66969 10.3333 9.69558 9.94702 10.452 9.25939C11.2084 8.57176 11.6333 7.63912 11.6333 6.66666"
        data-id="961df5fcca7d"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6.61024 1.06584C6.90252 1.02228 7.19999 1 7.5 1C7.77697 1 8.0518 1.01899 8.32235 1.05618M6.61024 1.06584C6.40392 1.09659 6.20019 1.13794 6 1.18953C5.1049 1.42023 4.28048 1.85577 3.61091 2.46447C2.57946 3.40215 3.5 5 2 6H13C11.5 5 12.4205 3.40215 11.3891 2.46447C10.7195 1.85577 9.8951 1.42023 9 1.18953C8.77804 1.13233 8.55173 1.08771 8.32235 1.05618M6.61024 1.06584V3.5M8.32235 1.05618L8.35221 3.5"
        data-id="f6ae578310ca"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 17.6667C1 16.0754 1.69536 14.5492 2.9331 13.424C4.17084 12.2988 5.84957 11.6667 7.6 11.6667C8.09222 11.6667 8.55767 11.7227 9 11.8282C9.16667 11.8855 9.5 12 10 12.5"
        data-id="ed31e7798087"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.804 18.8846C12.429 19.5555 13.211 20.042 14.2955 20.4293C14.4275 20.4765 14.5725 20.4765 14.7045 20.4293C15.789 20.042 16.571 19.5555 17.196 18.8846C18.9048 17.0503 19 14.2947 19 11.7878C19 11.6719 18.9178 11.5726 18.8043 11.5494C17.1925 11.2198 16.2984 10.7885 15.2721 9.80665C14.8415 9.39474 14.1585 9.39474 13.7279 9.80665C12.7016 10.7885 11.8075 11.2198 10.1957 11.5494C10.0822 11.5726 10 11.6719 10 11.7878C10 14.2947 10.0952 17.0503 11.804 18.8846Z"
        data-id="2b81a2945478" />
      <path
        d="M13 15L14 16L16 14"
        data-id="228873eeb97d"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default SafeBehaviour;
