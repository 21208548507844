import { createIcon } from '@chakra-ui/icons';

const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 15 14',
  path: (
    <g data-id="a088e8312b82">
      <path
        d="M7.3158 0.518188V13.4818"
        data-id="879e689c7c03"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M0.833984 7H13.7976"
        data-id="ee4aa4f5d1bc"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default PlusIcon;
