import { createIcon } from '@chakra-ui/icons';

const QuestionsIcon = createIcon({
  path: (
    <>
      <path
        d="M15.6667 3.66667C15.6667 4.37391 15.9477 5.05219 16.4478 5.55228C16.9479 6.05238 17.6262 6.33333 18.3334 6.33333C19.0407 6.33333 19.7189 6.05238 20.219 5.55228C20.7191 5.05219 21.0001 4.37391 21.0001 3.66667C21.0001 2.95942 20.7191 2.28115 20.219 1.78105C19.7189 1.28095 19.0407 1 18.3334 1C17.6262 1 16.9479 1.28095 16.4478 1.78105C15.9477 2.28115 15.6667 2.95942 15.6667 3.66667V3.66667Z"
        data-id="674810085036"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M4.61239 1.23834C4.64873 1.16678 4.70417 1.10668 4.77258 1.06469C4.84098 1.02271 4.91968 1.00049 4.99994 1.00049C5.0802 1.00049 5.1589 1.02271 5.2273 1.06469C5.29571 1.10668 5.35115 1.16678 5.3875 1.23834L6.28883 3.05345C6.32 3.11573 6.3658 3.16952 6.42231 3.21023C6.47881 3.25095 6.54433 3.27737 6.61327 3.28723L8.63372 3.57879C8.71259 3.59011 8.78676 3.62312 8.84797 3.67413C8.90918 3.72514 8.95502 3.79214 8.98039 3.86767C9.00512 3.94291 9.00815 4.0236 8.98913 4.10048C8.97011 4.17736 8.9298 4.24732 8.87283 4.30234L7.40616 5.72456C7.35691 5.77232 7.32001 5.83134 7.29864 5.89653C7.27727 5.96173 7.27207 6.03114 7.2835 6.09879L7.62927 8.10323C7.64272 8.18178 7.63366 8.26252 7.60314 8.33614C7.57263 8.40975 7.5219 8.47323 7.45683 8.51923C7.39127 8.56567 7.31414 8.59307 7.23398 8.5984C7.15382 8.60374 7.07374 8.5868 7.00261 8.54945L5.20172 7.60901C5.13953 7.57616 5.07027 7.55899 4.99994 7.55899C4.92961 7.55899 4.86035 7.57616 4.79816 7.60901L2.99994 8.54945C2.92881 8.5868 2.84873 8.60374 2.76857 8.5984C2.6884 8.59307 2.61128 8.56567 2.54572 8.51923C2.48065 8.47323 2.42992 8.40975 2.39941 8.33614C2.36889 8.26252 2.35983 8.18178 2.37327 8.10323L2.71905 6.09879C2.73048 6.03114 2.72528 5.96173 2.70391 5.89653C2.68254 5.83134 2.64564 5.77232 2.59638 5.72456L1.13327 4.30234C1.07631 4.24732 1.036 4.17736 1.01697 4.10048C0.99795 4.0236 1.00098 3.94291 1.02572 3.86767C1.05099 3.79209 1.09681 3.72503 1.15804 3.67401C1.21926 3.62299 1.29348 3.59002 1.37238 3.57879L3.39194 3.28723C3.45993 3.27656 3.52438 3.24977 3.57989 3.2091C3.63541 3.16843 3.68038 3.11506 3.71105 3.05345L4.61239 1.23834Z"
        data-id="2ac826ef2bf7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18.3333 1H9"
        data-id="093e86eaf453"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M15.6667 3.66675V17.0001C15.6667 18.0609 15.2452 19.0784 14.4951 19.8285C13.7449 20.5787 12.7275 21.0001 11.6667 21.0001H1C2.06087 21.0001 3.07828 20.5787 3.82843 19.8285C4.57857 19.0784 5 18.0609 5 17.0001V11.6667"
        data-id="830ac2cd8050"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.66675 15.6667H13.0001"
        data-id="1cd3e6bfcf51"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.66675 13H13.0001"
        data-id="cb01145242be"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M10.3333 10.3333H12.9999"
        data-id="95c4774a6f42"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M10.3333 7.66675H12.9999"
        data-id="58e1297be1bf"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </>
  ),
  viewBox: '0 0 22 22',
});

export default QuestionsIcon;
