import { createIcon } from '@chakra-ui/icons';

const ArrowDownIcon = createIcon({
  displayName: 'Arrow down',
  viewBox: '0 0 14 8',
  path: (
    <path
      d="M13.2227 1.27777L7.29357 7.20686C7.25509 7.24539 7.2094 7.27596 7.1591 7.29681C7.1088 7.31767 7.05489 7.3284 7.00043 7.3284C6.94598 7.3284 6.89207 7.31767 6.84177 7.29681C6.79147 7.27596 6.74577 7.24539 6.7073 7.20686L0.778212 1.27777"
      data-id="f9dfb673eae1"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round" />
  ),
});

export default ArrowDownIcon;
