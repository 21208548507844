import { createIcon } from '@chakra-ui/icons';

const SubmitIcon = createIcon({
  path: (
    <>
      <path
        d="M14.5324 15.4603L18.6019 9.06567L14.5324 2.67297C14.1178 2.0208 14.4541 1 15.0848 1H17.4422C17.6691 1.01128 17.8895 1.08 18.0825 1.19971C18.2756 1.31943 18.4351 1.48622 18.5461 1.68444L22.818 8.39785C22.9371 8.59883 23 8.82814 23 9.06176C23 9.29537 22.9371 9.52468 22.818 9.72567L18.551 16.4488C18.44 16.6471 18.2805 16.8139 18.0874 16.9336C17.8943 17.0533 17.674 17.122 17.4471 17.1333H15.0848C14.4541 17.1333 14.1178 16.1125 14.5324 15.4603Z"
        data-id="456847a7dfc7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6.31615 15.4603L10.3856 9.06665L6.31615 2.67297C5.90157 2.0208 6.23793 1 6.86761 1H8.59925C8.82618 1.01119 9.04654 1.07987 9.23964 1.19959C9.43274 1.31932 9.59224 1.48616 9.70316 1.68444L13.976 8.39883C14.0946 8.59998 14.1572 8.82923 14.1572 9.06273C14.1572 9.29624 14.0946 9.52549 13.976 9.72664L9.70316 16.4488C9.59224 16.6471 9.43274 16.814 9.23964 16.9337C9.04654 17.0534 8.82618 17.1221 8.59925 17.1333H6.86761C6.23793 17.1333 5.90157 16.1125 6.31615 15.4603Z"
        data-id="6bd3e0a9cff1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 16.4L6.13332 9.06664L1 1.73333"
        data-id="0d15d86418f6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: "0 0 24 18",
});

export default SubmitIcon;
