import { createIcon } from '@chakra-ui/icons';

const Dashboard = createIcon({
  displayName: 'Dashboard',
  viewBox: '0 0 22 22',

  path: (
    <g data-id="0a585f793ef9">
      <path
        d="M20.2187 15.7813L15.7813 20.2187C15.5337 20.4664 15.2396 20.6629 14.916 20.797C14.5923 20.9311 14.2454 21 13.8951 21H3.66667C2.95942 21 2.28115 20.719 1.78105 20.219C1.28095 19.7189 1 19.0406 1 18.3333V3.66667C1 2.95942 1.28095 2.28115 1.78105 1.78105C2.28115 1.28095 2.95942 1 3.66667 1H18.3333C19.0406 1 19.7189 1.28095 20.219 1.78105C20.719 2.28115 21 2.95942 21 3.66667V13.8951C21 14.2454 20.9311 14.5923 20.797 14.916C20.6629 15.2396 20.4664 15.5337 20.2187 15.7813V15.7813Z"
        data-id="a3f95cc22a0a"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M14.3333 20.9636V17C14.3333 16.2928 14.6142 15.6145 15.1143 15.1144C15.6144 14.6143 16.2927 14.3334 16.9999 14.3334H20.9635"
        data-id="3689a4a7e258"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 7H16"
        data-id="8d6263b14808"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 11H16"
        data-id="da130b31f85f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 15H11"
        data-id="e989b025f0be"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default Dashboard;
