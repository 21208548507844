import { createIcon } from '@chakra-ui/icons';

const ArrowCount = createIcon({
  displayName: 'Admin',
  viewBox: '0 0 10 10',
  path: (
    <g data-id="6360b1d47cc8">
      <path
        d="M0.916668 8.7727L8.90185 0.916992"
        data-id="a3c982b15693"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8.90185 5.40638V0.916992H4.33889"
        data-id="a0a55b100a23"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default ArrowCount;
