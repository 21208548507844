import { createIcon } from '@chakra-ui/icons';

const RedDotIcon = createIcon({
  viewBox: '0 0 11 11',
  path: (
    <g data-id="40025ba3b21b">
      <circle
        cx="5.5"
        cy="5.5"
        data-id="35415afbc07f"
        fill="#A2171E"
        r="4.5"
        stroke="white"
        strokeWidth="2" />
    </g>
  ),
});

export default RedDotIcon;
