import { createIcon } from '@chakra-ui/icons';

const NotificationIcon = createIcon({
  path: (
    <>
      <path
        d="M6.5 16.3125C6.59526 16.637 6.79307 16.9219 7.06381 17.1246C7.33456 17.3273 7.66367 17.4369 8.00187 17.4369C8.34008 17.4369 8.66919 17.3273 8.93993 17.1246C9.21068 16.9219 9.40849 16.637 9.50375 16.3125"
        data-id="6474c71ee7b8"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8 2.25V0.5625"
        data-id="8b6d88cc4109"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8 2.25C9.49184 2.25 10.9226 2.84263 11.9775 3.89752C13.0324 4.95242 13.625 6.38316 13.625 7.875C13.625 13.1595 14.75 14.0625 14.75 14.0625H1.25C1.25 14.0625 2.375 12.6255 2.375 7.875C2.375 6.38316 2.96763 4.95242 4.02252 3.89752C5.07742 2.84263 6.50816 2.25 8 2.25V2.25Z"
        data-id="756cf227d563"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 18 18',
});

export default NotificationIcon;
