import { createIcon } from '@chakra-ui/icons';

const InsightsCardDots = createIcon({
  viewBox: '0 0 175 175',
  path: (
    <g data-id="6cd2bf4a9c5a">
      <path
        d="M87.4996 174.999C87.7992 174.999 88.0421 174.756 88.0421 174.456C88.0421 174.157 87.7992 173.914 87.4996 173.914C87.1999 173.914 86.957 174.157 86.957 174.456C86.957 174.756 87.1999 174.999 87.4996 174.999Z"
        data-id="a5ebf89bdbf9"
        fill="white" />
      <path
        d="M87.4996 174.999C87.7992 174.999 88.0421 174.756 88.0421 174.456C88.0421 174.157 87.7992 173.914 87.4996 173.914C87.1999 173.914 86.957 174.157 86.957 174.456C86.957 174.756 87.1999 174.999 87.4996 174.999Z"
        data-id="005855974903"
        fill="white" />
      <path
        d="M92.6138 169.885C92.9135 169.885 93.1564 169.643 93.1564 169.343C93.1564 169.044 92.9135 168.801 92.6138 168.801C92.3142 168.801 92.0713 169.044 92.0713 169.343C92.0713 169.643 92.3142 169.885 92.6138 169.885Z"
        data-id="af96563e0753"
        fill="white" />
      <path
        d="M92.6138 169.885C92.9135 169.885 93.1564 169.643 93.1564 169.343C93.1564 169.044 92.9135 168.801 92.6138 168.801C92.3142 168.801 92.0713 169.044 92.0713 169.343C92.0713 169.643 92.3142 169.885 92.6138 169.885Z"
        data-id="69235150bb89"
        fill="white" />
      <path
        d="M97.7291 164.766C98.0287 164.766 98.2716 164.523 98.2716 164.224C98.2716 163.924 98.0287 163.682 97.7291 163.682C97.4294 163.682 97.1865 163.924 97.1865 164.224C97.1865 164.523 97.4294 164.766 97.7291 164.766Z"
        data-id="087d0eb88c0f"
        fill="white" />
      <path
        d="M97.7291 164.766C98.0287 164.766 98.2716 164.523 98.2716 164.224C98.2716 163.924 98.0287 163.682 97.7291 163.682C97.4294 163.682 97.1865 163.924 97.1865 164.224C97.1865 164.523 97.4294 164.766 97.7291 164.766Z"
        data-id="6015ec13ed6a"
        fill="white" />
      <path
        d="M102.843 159.647C103.143 159.647 103.386 159.404 103.386 159.105C103.386 158.805 103.143 158.562 102.843 158.562C102.544 158.562 102.301 158.805 102.301 159.105C102.301 159.404 102.544 159.647 102.843 159.647Z"
        data-id="3f273ef9608d"
        fill="white" />
      <path
        d="M102.843 159.647C103.143 159.647 103.386 159.404 103.386 159.105C103.386 158.805 103.143 158.562 102.843 158.562C102.544 158.562 102.301 158.805 102.301 159.105C102.301 159.404 102.544 159.647 102.843 159.647Z"
        data-id="fd793e1dcb48"
        fill="white" />
      <path
        d="M107.957 154.536C108.257 154.536 108.5 154.293 108.5 153.993C108.5 153.694 108.257 153.451 107.957 153.451C107.657 153.451 107.415 153.694 107.415 153.993C107.415 154.293 107.657 154.536 107.957 154.536Z"
        data-id="ba61ddedda9d"
        fill="white" />
      <path
        d="M107.957 154.536C108.257 154.536 108.5 154.293 108.5 153.993C108.5 153.694 108.257 153.451 107.957 153.451C107.657 153.451 107.415 153.694 107.415 153.993C107.415 154.293 107.657 154.536 107.957 154.536Z"
        data-id="7dd274d3f897"
        fill="white" />
      <path
        d="M113.463 149.26C113.253 149.469 112.906 149.469 112.696 149.26C112.487 149.05 112.487 148.703 112.696 148.493C112.906 148.284 113.253 148.284 113.463 148.493C113.673 148.703 113.673 149.05 113.463 149.26Z"
        data-id="52e9c834fbcf"
        fill="white" />
      <path
        d="M113.463 149.26C113.253 149.469 112.906 149.469 112.696 149.26C112.487 149.05 112.487 148.703 112.696 148.493C112.906 148.284 113.253 148.284 113.463 148.493C113.673 148.703 113.673 149.05 113.463 149.26Z"
        data-id="010fc89202b1"
        fill="white" />
      <path
        d="M118.194 144.305C118.494 144.305 118.736 144.063 118.736 143.763C118.736 143.464 118.494 143.221 118.194 143.221C117.894 143.221 117.651 143.464 117.651 143.763C117.651 144.063 117.894 144.305 118.194 144.305Z"
        data-id="a5ba48ee2d78"
        fill="white" />
      <path
        d="M118.194 144.305C118.494 144.305 118.736 144.063 118.736 143.763C118.736 143.464 118.494 143.221 118.194 143.221C117.894 143.221 117.651 143.464 117.651 143.763C117.651 144.063 117.894 144.305 118.194 144.305Z"
        data-id="4bfac4de8279"
        fill="white" />
      <path
        d="M123.309 139.192C123.609 139.192 123.852 138.949 123.852 138.65C123.852 138.35 123.609 138.107 123.309 138.107C123.01 138.107 122.767 138.35 122.767 138.65C122.767 138.949 123.01 139.192 123.309 139.192Z"
        data-id="06cdd9db8ce5"
        fill="white" />
      <path
        d="M123.309 139.192C123.609 139.192 123.852 138.949 123.852 138.65C123.852 138.35 123.609 138.107 123.309 138.107C123.01 138.107 122.767 138.35 122.767 138.65C122.767 138.949 123.01 139.192 123.309 139.192Z"
        data-id="0ca0fd2bd2a3"
        fill="white" />
      <path
        d="M128.805 133.918C128.596 134.128 128.248 134.128 128.039 133.918C127.829 133.708 127.829 133.361 128.039 133.151C128.248 132.942 128.596 132.942 128.805 133.151C129.015 133.361 129.015 133.708 128.805 133.918Z"
        data-id="fc52a69f3ec7"
        fill="white" />
      <path
        d="M128.805 133.918C128.596 134.128 128.248 134.128 128.039 133.918C127.829 133.708 127.829 133.361 128.039 133.151C128.248 132.942 128.596 132.942 128.805 133.151C129.015 133.361 129.015 133.708 128.805 133.918Z"
        data-id="31348beb39ad"
        fill="white" />
      <path
        d="M133.538 128.964C133.837 128.964 134.08 128.721 134.08 128.421C134.08 128.122 133.837 127.879 133.538 127.879C133.238 127.879 132.995 128.122 132.995 128.421C132.995 128.721 133.238 128.964 133.538 128.964Z"
        data-id="554a4af8ef16"
        fill="white" />
      <path
        d="M133.538 128.964C133.837 128.964 134.08 128.721 134.08 128.421C134.08 128.122 133.837 127.879 133.538 127.879C133.238 127.879 132.995 128.122 132.995 128.421C132.995 128.721 133.238 128.964 133.538 128.964Z"
        data-id="abf3581c9697"
        fill="white" />
      <path
        d="M139.035 123.689C138.825 123.899 138.478 123.899 138.268 123.689C138.058 123.48 138.058 123.133 138.268 122.923C138.478 122.713 138.825 122.713 139.035 122.923C139.245 123.133 139.245 123.472 139.035 123.689Z"
        data-id="2dcbacdb8334"
        fill="white" />
      <path
        d="M139.035 123.689C138.825 123.899 138.478 123.899 138.268 123.689C138.058 123.48 138.058 123.133 138.268 122.923C138.478 122.713 138.825 122.713 139.035 122.923C139.245 123.133 139.245 123.472 139.035 123.689Z"
        data-id="fe886a5a3dc8"
        fill="white" />
      <path
        d="M144.149 118.568C143.939 118.778 143.592 118.778 143.382 118.568C143.173 118.359 143.173 118.011 143.382 117.802C143.592 117.592 143.939 117.592 144.149 117.802C144.359 118.011 144.359 118.359 144.149 118.568Z"
        data-id="cd8bd0e0a28a"
        fill="white" />
      <path
        d="M144.149 118.568C143.939 118.778 143.592 118.778 143.382 118.568C143.173 118.359 143.173 118.011 143.382 117.802C143.592 117.592 143.939 117.592 144.149 117.802C144.359 118.011 144.359 118.359 144.149 118.568Z"
        data-id="26c3eec9c40a"
        fill="white" />
      <path
        d="M149.264 113.455C149.054 113.665 148.707 113.665 148.497 113.455C148.287 113.245 148.287 112.898 148.497 112.689C148.707 112.479 149.054 112.479 149.264 112.689C149.474 112.898 149.481 113.245 149.264 113.455Z"
        data-id="4d3c349a60aa"
        fill="white" />
      <path
        d="M149.264 113.455C149.054 113.665 148.707 113.665 148.497 113.455C148.287 113.245 148.287 112.898 148.497 112.689C148.707 112.479 149.054 112.479 149.264 112.689C149.474 112.898 149.481 113.245 149.264 113.455Z"
        data-id="39ce8447e9f5"
        fill="white" />
      <path
        d="M154.378 108.34C154.168 108.549 153.821 108.549 153.611 108.34C153.402 108.13 153.402 107.783 153.611 107.573C153.821 107.364 154.168 107.364 154.378 107.573C154.595 107.79 154.595 108.13 154.378 108.34Z"
        data-id="cf505cc4d191"
        fill="white" />
      <path
        d="M154.378 108.34C154.168 108.549 153.821 108.549 153.611 108.34C153.402 108.13 153.402 107.783 153.611 107.573C153.821 107.364 154.168 107.364 154.378 107.573C154.595 107.79 154.595 108.13 154.378 108.34Z"
        data-id="da1a75edea77"
        fill="white" />
      <path
        d="M159.492 103.225C159.283 103.434 158.935 103.434 158.726 103.225C158.516 103.015 158.516 102.668 158.726 102.458C158.935 102.248 159.283 102.248 159.492 102.458C159.702 102.668 159.71 103.015 159.492 103.225Z"
        data-id="42c8f52f37df"
        fill="white" />
      <path
        d="M159.492 103.225C159.283 103.434 158.935 103.434 158.726 103.225C158.516 103.015 158.516 102.668 158.726 102.458C158.935 102.248 159.283 102.248 159.492 102.458C159.702 102.668 159.71 103.015 159.492 103.225Z"
        data-id="7a437c1e973a"
        fill="white" />
      <path
        d="M164.615 98.1112C164.405 98.3209 164.058 98.3209 163.848 98.1112C163.638 97.9015 163.638 97.5545 163.848 97.3448C164.058 97.1351 164.405 97.1351 164.615 97.3448C164.825 97.5545 164.825 97.9015 164.615 98.1112Z"
        data-id="4301151c7c1e"
        fill="white" />
      <path
        d="M164.615 98.1112C164.405 98.3209 164.058 98.3209 163.848 98.1112C163.638 97.9015 163.638 97.5545 163.848 97.3448C164.058 97.1351 164.405 97.1351 164.615 97.3448C164.825 97.5545 164.825 97.9015 164.615 98.1112Z"
        data-id="d33a2756dac6"
        fill="white" />
      <path
        d="M169.729 92.9999C169.52 93.2096 169.172 93.2096 168.963 92.9999C168.753 92.7902 168.753 92.4431 168.963 92.2334C169.172 92.0237 169.52 92.0237 169.729 92.2334C169.939 92.4431 169.939 92.7902 169.729 92.9999Z"
        data-id="b295f4db99ba"
        fill="white" />
      <path
        d="M169.729 92.9999C169.52 93.2096 169.172 93.2096 168.963 92.9999C168.753 92.7902 168.753 92.4431 168.963 92.2334C169.172 92.0237 169.52 92.0237 169.729 92.2334C169.939 92.4431 169.939 92.7902 169.729 92.9999Z"
        data-id="ecd986f2f5a4"
        fill="white" />
      <path
        d="M174.843 87.8828C174.633 88.0925 174.286 88.0925 174.076 87.8828C173.866 87.6731 173.866 87.326 174.076 87.1163C174.286 86.9066 174.633 86.9066 174.843 87.1163C175.052 87.326 175.052 87.6731 174.843 87.8828Z"
        data-id="8de691d679be"
        fill="white" />
      <path
        d="M174.843 87.8828C174.633 88.0925 174.286 88.0925 174.076 87.8828C173.866 87.6731 173.866 87.326 174.076 87.1163C174.286 86.9066 174.633 86.9066 174.843 87.1163C175.052 87.326 175.052 87.6731 174.843 87.8828Z"
        data-id="eda4b9df8544"
        fill="white" />
      <path
        d="M82.3858 169.885C82.6855 169.885 82.9284 169.643 82.9284 169.343C82.9284 169.044 82.6855 168.801 82.3858 168.801C82.0862 168.801 81.8433 169.044 81.8433 169.343C81.8433 169.643 82.0862 169.885 82.3858 169.885Z"
        data-id="eef78a37d17c"
        fill="white" />
      <path
        d="M82.3858 169.885C82.6855 169.885 82.9284 169.643 82.9284 169.343C82.9284 169.044 82.6855 168.801 82.3858 168.801C82.0862 168.801 81.8433 169.044 81.8433 169.343C81.8433 169.643 82.0862 169.885 82.3858 169.885Z"
        data-id="c6a385743cc1"
        fill="white" />
      <path
        d="M87.5001 164.766C87.7997 164.766 88.0426 164.523 88.0426 164.224C88.0426 163.924 87.7997 163.682 87.5001 163.682C87.2004 163.682 86.9575 163.924 86.9575 164.224C86.9575 164.523 87.2004 164.766 87.5001 164.766Z"
        data-id="22a67326402d"
        fill="white" />
      <path
        d="M87.5001 164.766C87.7997 164.766 88.0426 164.523 88.0426 164.224C88.0426 163.924 87.7997 163.682 87.5001 163.682C87.2004 163.682 86.9575 163.924 86.9575 164.224C86.9575 164.523 87.2004 164.766 87.5001 164.766Z"
        data-id="c2914a2fafc4"
        fill="white" />
      <path
        d="M92.6148 159.645C92.9145 159.645 93.1574 159.402 93.1574 159.103C93.1574 158.803 92.9145 158.561 92.6148 158.561C92.3152 158.561 92.0723 158.803 92.0723 159.103C92.0723 159.402 92.3152 159.645 92.6148 159.645Z"
        data-id="b13595ce6dc4"
        fill="white" />
      <path
        d="M92.6148 159.645C92.9145 159.645 93.1574 159.402 93.1574 159.103C93.1574 158.803 92.9145 158.561 92.6148 158.561C92.3152 158.561 92.0723 158.803 92.0723 159.103C92.0723 159.402 92.3152 159.645 92.6148 159.645Z"
        data-id="98d6fd37666f"
        fill="white" />
      <path
        d="M97.7296 154.536C98.0292 154.536 98.2721 154.293 98.2721 153.993C98.2721 153.694 98.0292 153.451 97.7296 153.451C97.4299 153.451 97.187 153.694 97.187 153.993C97.187 154.293 97.4299 154.536 97.7296 154.536Z"
        data-id="7087973fad59"
        fill="white" />
      <path
        d="M97.7296 154.536C98.0292 154.536 98.2721 154.293 98.2721 153.993C98.2721 153.694 98.0292 153.451 97.7296 153.451C97.4299 153.451 97.187 153.694 97.187 153.993C97.187 154.293 97.4299 154.536 97.7296 154.536Z"
        data-id="bfa817405d86"
        fill="white" />
      <path
        d="M102.843 149.419C103.143 149.419 103.386 149.176 103.386 148.876C103.386 148.577 103.143 148.334 102.843 148.334C102.544 148.334 102.301 148.577 102.301 148.876C102.301 149.176 102.544 149.419 102.843 149.419Z"
        data-id="df62a6c70eb1"
        fill="white" />
      <path
        d="M102.843 149.419C103.143 149.419 103.386 149.176 103.386 148.876C103.386 148.577 103.143 148.334 102.843 148.334C102.544 148.334 102.301 148.577 102.301 148.876C102.301 149.176 102.544 149.419 102.843 149.419Z"
        data-id="2d6464910574"
        fill="white" />
      <path
        d="M107.959 144.307C108.258 144.307 108.501 144.064 108.501 143.765C108.501 143.465 108.258 143.223 107.959 143.223C107.659 143.223 107.416 143.465 107.416 143.765C107.416 144.064 107.659 144.307 107.959 144.307Z"
        data-id="44d050e471e8"
        fill="white" />
      <path
        d="M107.959 144.307C108.258 144.307 108.501 144.064 108.501 143.765C108.501 143.465 108.258 143.223 107.959 143.223C107.659 143.223 107.416 143.465 107.416 143.765C107.416 144.064 107.659 144.307 107.959 144.307Z"
        data-id="4cc24e14400c"
        fill="white" />
      <path
        d="M113.464 139.035C113.254 139.245 112.907 139.245 112.697 139.035C112.487 138.825 112.487 138.478 112.697 138.269C112.907 138.059 113.254 138.059 113.464 138.269C113.673 138.478 113.673 138.825 113.464 139.035Z"
        data-id="0a4375ae6bd5"
        fill="white" />
      <path
        d="M113.464 139.035C113.254 139.245 112.907 139.245 112.697 139.035C112.487 138.825 112.487 138.478 112.697 138.269C112.907 138.059 113.254 138.059 113.464 138.269C113.673 138.478 113.673 138.825 113.464 139.035Z"
        data-id="26028642c219"
        fill="white" />
      <path
        d="M118.194 134.079C118.494 134.079 118.737 133.836 118.737 133.536C118.737 133.237 118.494 132.994 118.194 132.994C117.895 132.994 117.652 133.237 117.652 133.536C117.652 133.836 117.895 134.079 118.194 134.079Z"
        data-id="424eb7720632"
        fill="white" />
      <path
        d="M118.194 134.079C118.494 134.079 118.737 133.836 118.737 133.536C118.737 133.237 118.494 132.994 118.194 132.994C117.895 132.994 117.652 133.237 117.652 133.536C117.652 133.836 117.895 134.079 118.194 134.079Z"
        data-id="e6c24b9898eb"
        fill="white" />
      <path
        d="M123.309 128.965C123.609 128.965 123.852 128.723 123.852 128.423C123.852 128.124 123.609 127.881 123.309 127.881C123.01 127.881 122.767 128.124 122.767 128.423C122.767 128.723 123.01 128.965 123.309 128.965Z"
        data-id="6e1faa9e3c99"
        fill="white" />
      <path
        d="M123.309 128.965C123.609 128.965 123.852 128.723 123.852 128.423C123.852 128.124 123.609 127.881 123.309 127.881C123.01 127.881 122.767 128.124 122.767 128.423C122.767 128.723 123.01 128.965 123.309 128.965Z"
        data-id="03684aab5d37"
        fill="white" />
      <path
        d="M128.806 123.689C128.596 123.899 128.249 123.899 128.039 123.689C127.829 123.48 127.829 123.133 128.039 122.923C128.249 122.713 128.596 122.713 128.806 122.923C129.016 123.133 129.016 123.472 128.806 123.689Z"
        data-id="a12bfe0b3b06"
        fill="white" />
      <path
        d="M128.806 123.689C128.596 123.899 128.249 123.899 128.039 123.689C127.829 123.48 127.829 123.133 128.039 122.923C128.249 122.713 128.596 122.713 128.806 122.923C129.016 123.133 129.016 123.472 128.806 123.689Z"
        data-id="86d0034f8875"
        fill="white" />
      <path
        d="M133.537 118.729C133.837 118.729 134.08 118.486 134.08 118.187C134.08 117.887 133.837 117.645 133.537 117.645C133.238 117.645 132.995 117.887 132.995 118.187C132.995 118.486 133.238 118.729 133.537 118.729Z"
        data-id="bb92206e4e31"
        fill="white" />
      <path
        d="M133.537 118.729C133.837 118.729 134.08 118.486 134.08 118.187C134.08 117.887 133.837 117.645 133.537 117.645C133.238 117.645 132.995 117.887 132.995 118.187C132.995 118.486 133.238 118.729 133.537 118.729Z"
        data-id="dec3c91fac21"
        fill="white" />
      <path
        d="M139.035 113.455C138.826 113.665 138.478 113.665 138.269 113.455C138.059 113.245 138.059 112.898 138.269 112.689C138.478 112.479 138.826 112.479 139.035 112.689C139.245 112.905 139.245 113.245 139.035 113.455Z"
        data-id="4f8dd858348f"
        fill="white" />
      <path
        d="M139.035 113.455C138.826 113.665 138.478 113.665 138.269 113.455C138.059 113.245 138.059 112.898 138.269 112.689C138.478 112.479 138.826 112.479 139.035 112.689C139.245 112.905 139.245 113.245 139.035 113.455Z"
        data-id="d88504933294"
        fill="white" />
      <path
        d="M144.15 108.34C143.94 108.549 143.593 108.549 143.383 108.34C143.174 108.13 143.174 107.783 143.383 107.573C143.593 107.364 143.94 107.364 144.15 107.573C144.36 107.783 144.36 108.13 144.15 108.34Z"
        data-id="82b896cdfee3"
        fill="white" />
      <path
        d="M144.15 108.34C143.94 108.549 143.593 108.549 143.383 108.34C143.174 108.13 143.174 107.783 143.383 107.573C143.593 107.364 143.94 107.364 144.15 107.573C144.36 107.783 144.36 108.13 144.15 108.34Z"
        data-id="de150afc5618"
        fill="white" />
      <path
        d="M148.88 103.385C149.18 103.385 149.423 103.143 149.423 102.843C149.423 102.544 149.18 102.301 148.88 102.301C148.581 102.301 148.338 102.544 148.338 102.843C148.338 103.143 148.581 103.385 148.88 103.385Z"
        data-id="4fc43d5d978c"
        fill="white" />
      <path
        d="M148.88 103.385C149.18 103.385 149.423 103.143 149.423 102.843C149.423 102.544 149.18 102.301 148.88 102.301C148.581 102.301 148.338 102.544 148.338 102.843C148.338 103.143 148.581 103.385 148.88 103.385Z"
        data-id="c046f8915111"
        fill="white" />
      <path
        d="M154.379 98.1112C154.169 98.3209 153.822 98.3209 153.612 98.1112C153.402 97.9015 153.402 97.5545 153.612 97.3448C153.822 97.1351 154.169 97.1351 154.379 97.3448C154.596 97.5545 154.596 97.9015 154.379 98.1112Z"
        data-id="b3eeae71ca5e"
        fill="white" />
      <path
        d="M154.379 98.1112C154.169 98.3209 153.822 98.3209 153.612 98.1112C153.402 97.9015 153.402 97.5545 153.612 97.3448C153.822 97.1351 154.169 97.1351 154.379 97.3448C154.596 97.5545 154.596 97.9015 154.379 98.1112Z"
        data-id="45d3e60a3a5e"
        fill="white" />
      <path
        d="M159.493 92.996C159.284 93.2057 158.936 93.2057 158.727 92.996C158.517 92.7863 158.517 92.4392 158.727 92.2295C158.936 92.0198 159.284 92.0198 159.493 92.2295C159.703 92.4392 159.71 92.7863 159.493 92.996Z"
        data-id="1f4ac4e1fa47"
        fill="white" />
      <path
        d="M159.493 92.996C159.284 93.2057 158.936 93.2057 158.727 92.996C158.517 92.7863 158.517 92.4392 158.727 92.2295C158.936 92.0198 159.284 92.0198 159.493 92.2295C159.703 92.4392 159.71 92.7863 159.493 92.996Z"
        data-id="8e46faa8eb19"
        fill="white" />
      <path
        d="M164.615 87.8847C164.405 88.0944 164.058 88.0944 163.848 87.8847C163.638 87.675 163.638 87.3279 163.848 87.1182C164.058 86.9085 164.405 86.9085 164.615 87.1182C164.825 87.3279 164.825 87.675 164.615 87.8847Z"
        data-id="e2958ca88d31"
        fill="white" />
      <path
        d="M164.615 87.8847C164.405 88.0944 164.058 88.0944 163.848 87.8847C163.638 87.675 163.638 87.3279 163.848 87.1182C164.058 86.9085 164.405 86.9085 164.615 87.1182C164.825 87.3279 164.825 87.675 164.615 87.8847Z"
        data-id="00ca7f0b63d4"
        fill="white" />
      <path
        d="M169.729 82.7694C169.52 82.9791 169.172 82.9791 168.963 82.7694C168.753 82.5597 168.753 82.2127 168.963 82.003C169.172 81.7933 169.52 81.7933 169.729 82.003C169.939 82.2127 169.939 82.5525 169.729 82.7694Z"
        data-id="625f09b40f39"
        fill="white" />
      <path
        d="M169.729 82.7694C169.52 82.9791 169.172 82.9791 168.963 82.7694C168.753 82.5597 168.753 82.2127 168.963 82.003C169.172 81.7933 169.52 81.7933 169.729 82.003C169.939 82.2127 169.939 82.5525 169.729 82.7694Z"
        data-id="cbd93e2f8ad6"
        fill="white" />
      <path
        d="M77.2711 164.768C77.5707 164.768 77.8136 164.525 77.8136 164.226C77.8136 163.926 77.5707 163.684 77.2711 163.684C76.9714 163.684 76.7285 163.926 76.7285 164.226C76.7285 164.525 76.9714 164.768 77.2711 164.768Z"
        data-id="f319e55722c2"
        fill="white" />
      <path
        d="M77.2711 164.768C77.5707 164.768 77.8136 164.525 77.8136 164.226C77.8136 163.926 77.5707 163.684 77.2711 163.684C76.9714 163.684 76.7285 163.926 76.7285 164.226C76.7285 164.525 76.9714 164.768 77.2711 164.768Z"
        data-id="5d83ffb408b6"
        fill="white" />
      <path
        d="M82.3853 159.649C82.685 159.649 82.9279 159.406 82.9279 159.107C82.9279 158.807 82.685 158.564 82.3853 158.564C82.0857 158.564 81.8428 158.807 81.8428 159.107C81.8428 159.406 82.0857 159.649 82.3853 159.649Z"
        data-id="731d1e62fc7c"
        fill="white" />
      <path
        d="M82.3853 159.649C82.685 159.649 82.9279 159.406 82.9279 159.107C82.9279 158.807 82.685 158.564 82.3853 158.564C82.0857 158.564 81.8428 158.807 81.8428 159.107C81.8428 159.406 82.0857 159.649 82.3853 159.649Z"
        data-id="ff58a2166098"
        fill="white" />
      <path
        d="M87.5001 154.538C87.7997 154.538 88.0426 154.295 88.0426 153.995C88.0426 153.696 87.7997 153.453 87.5001 153.453C87.2004 153.453 86.9575 153.696 86.9575 153.995C86.9575 154.295 87.2004 154.538 87.5001 154.538Z"
        data-id="127a18176a60"
        fill="white" />
      <path
        d="M87.5001 154.538C87.7997 154.538 88.0426 154.295 88.0426 153.995C88.0426 153.696 87.7997 153.453 87.5001 153.453C87.2004 153.453 86.9575 153.696 86.9575 153.995C86.9575 154.295 87.2004 154.538 87.5001 154.538Z"
        data-id="8757312d2791"
        fill="white" />
      <path
        d="M92.6138 149.421C92.9135 149.421 93.1564 149.178 93.1564 148.878C93.1564 148.579 92.9135 148.336 92.6138 148.336C92.3142 148.336 92.0713 148.579 92.0713 148.878C92.0713 149.178 92.3142 149.421 92.6138 149.421Z"
        data-id="38e0c71f8ca1"
        fill="white" />
      <path
        d="M92.6138 149.421C92.9135 149.421 93.1564 149.178 93.1564 148.878C93.1564 148.579 92.9135 148.336 92.6138 148.336C92.3142 148.336 92.0713 148.579 92.0713 148.878C92.0713 149.178 92.3142 149.421 92.6138 149.421Z"
        data-id="52694f36e0c0"
        fill="white" />
      <path
        d="M97.7286 144.307C98.0282 144.307 98.2711 144.064 98.2711 143.765C98.2711 143.465 98.0282 143.223 97.7286 143.223C97.4289 143.223 97.186 143.465 97.186 143.765C97.186 144.064 97.4289 144.307 97.7286 144.307Z"
        data-id="b033a6a2573e"
        fill="white" />
      <path
        d="M97.7286 144.307C98.0282 144.307 98.2711 144.064 98.2711 143.765C98.2711 143.465 98.0282 143.223 97.7286 143.223C97.4289 143.223 97.186 143.465 97.186 143.765C97.186 144.064 97.4289 144.307 97.7286 144.307Z"
        data-id="aeeeabf74380"
        fill="white" />
      <path
        d="M102.844 139.194C103.143 139.194 103.386 138.951 103.386 138.652C103.386 138.352 103.143 138.109 102.844 138.109C102.544 138.109 102.301 138.352 102.301 138.652C102.301 138.951 102.544 139.194 102.844 139.194Z"
        data-id="0096627ddd8b"
        fill="white" />
      <path
        d="M102.844 139.194C103.143 139.194 103.386 138.951 103.386 138.652C103.386 138.352 103.143 138.109 102.844 138.109C102.544 138.109 102.301 138.352 102.301 138.652C102.301 138.951 102.544 139.194 102.844 139.194Z"
        data-id="a9bbf9641668"
        fill="white" />
      <path
        d="M107.958 134.079C108.257 134.079 108.5 133.836 108.5 133.536C108.5 133.237 108.257 132.994 107.958 132.994C107.658 132.994 107.415 133.237 107.415 133.536C107.415 133.836 107.658 134.079 107.958 134.079Z"
        data-id="efb5b0097795"
        fill="white" />
      <path
        d="M107.958 134.079C108.257 134.079 108.5 133.836 108.5 133.536C108.5 133.237 108.257 132.994 107.958 132.994C107.658 132.994 107.415 133.237 107.415 133.536C107.415 133.836 107.658 134.079 107.958 134.079Z"
        data-id="5d262ccfac4c"
        fill="white" />
      <path
        d="M113.463 128.807C113.253 129.016 112.906 129.016 112.696 128.807C112.486 128.597 112.486 128.25 112.696 128.04C112.906 127.83 113.253 127.83 113.463 128.04C113.672 128.25 113.672 128.59 113.463 128.807Z"
        data-id="2e344bab819a"
        fill="white" />
      <path
        d="M113.463 128.807C113.253 129.016 112.906 129.016 112.696 128.807C112.486 128.597 112.486 128.25 112.696 128.04C112.906 127.83 113.253 127.83 113.463 128.04C113.672 128.25 113.672 128.59 113.463 128.807Z"
        data-id="062610b8e7b6"
        fill="white" />
      <path
        d="M118.194 123.85C118.494 123.85 118.736 123.607 118.736 123.308C118.736 123.008 118.494 122.766 118.194 122.766C117.894 122.766 117.651 123.008 117.651 123.308C117.651 123.607 117.894 123.85 118.194 123.85Z"
        data-id="bc167f0fa087"
        fill="white" />
      <path
        d="M118.194 123.85C118.494 123.85 118.736 123.607 118.736 123.308C118.736 123.008 118.494 122.766 118.194 122.766C117.894 122.766 117.651 123.008 117.651 123.308C117.651 123.607 117.894 123.85 118.194 123.85Z"
        data-id="04305c1eef70"
        fill="white" />
      <path
        d="M123.309 118.731C123.608 118.731 123.851 118.488 123.851 118.189C123.851 117.889 123.608 117.646 123.309 117.646C123.009 117.646 122.766 117.889 122.766 118.189C122.766 118.488 123.009 118.731 123.309 118.731Z"
        data-id="4f33eadcacf2"
        fill="white" />
      <path
        d="M123.309 118.731C123.608 118.731 123.851 118.488 123.851 118.189C123.851 117.889 123.608 117.646 123.309 117.646C123.009 117.646 122.766 117.889 122.766 118.189C122.766 118.488 123.009 118.731 123.309 118.731Z"
        data-id="83813ae87d78"
        fill="white" />
      <path
        d="M128.806 113.455C128.597 113.665 128.249 113.665 128.04 113.455C127.83 113.245 127.83 112.898 128.04 112.689C128.249 112.479 128.597 112.479 128.806 112.689C129.016 112.898 129.016 113.245 128.806 113.455Z"
        data-id="0a2fee5f338c"
        fill="white" />
      <path
        d="M128.806 113.455C128.597 113.665 128.249 113.665 128.04 113.455C127.83 113.245 127.83 112.898 128.04 112.689C128.249 112.479 128.597 112.479 128.806 112.689C129.016 112.898 129.016 113.245 128.806 113.455Z"
        data-id="1f599fbf0b0b"
        fill="white" />
      <path
        d="M133.537 108.501C133.837 108.501 134.08 108.258 134.08 107.958C134.08 107.659 133.837 107.416 133.537 107.416C133.238 107.416 132.995 107.659 132.995 107.958C132.995 108.258 133.238 108.501 133.537 108.501Z"
        data-id="3fa9d011a112"
        fill="white" />
      <path
        d="M133.537 108.501C133.837 108.501 134.08 108.258 134.08 107.958C134.08 107.659 133.837 107.416 133.537 107.416C133.238 107.416 132.995 107.659 132.995 107.958C132.995 108.258 133.238 108.501 133.537 108.501Z"
        data-id="5601337d1f30"
        fill="white" />
      <path
        d="M139.035 103.226C138.826 103.436 138.478 103.436 138.269 103.226C138.059 103.017 138.059 102.67 138.269 102.46C138.478 102.25 138.826 102.25 139.035 102.46C139.245 102.67 139.245 103.017 139.035 103.226Z"
        data-id="ee75f2a2e6a1"
        fill="white" />
      <path
        d="M139.035 103.226C138.826 103.436 138.478 103.436 138.269 103.226C138.059 103.017 138.059 102.67 138.269 102.46C138.478 102.25 138.826 102.25 139.035 102.46C139.245 102.67 139.245 103.017 139.035 103.226Z"
        data-id="4a3cf9c83fe6"
        fill="white" />
      <path
        d="M144.149 98.1132C143.939 98.3229 143.592 98.3229 143.382 98.1132C143.173 97.9035 143.173 97.5564 143.382 97.3467C143.592 97.137 143.939 97.137 144.149 97.3467C144.359 97.5564 144.359 97.9035 144.149 98.1132Z"
        data-id="fbf3d70a4e7d"
        fill="white" />
      <path
        d="M144.149 98.1132C143.939 98.3229 143.592 98.3229 143.382 98.1132C143.173 97.9035 143.173 97.5564 143.382 97.3467C143.592 97.137 143.939 97.137 144.149 97.3467C144.359 97.5564 144.359 97.9035 144.149 98.1132Z"
        data-id="4dbaa5c1ce2e"
        fill="white" />
      <path
        d="M148.88 93.1549C149.18 93.1549 149.422 92.9121 149.422 92.6126C149.422 92.3131 149.18 92.0703 148.88 92.0703C148.58 92.0703 148.337 92.3131 148.337 92.6126C148.337 92.9121 148.58 93.1549 148.88 93.1549Z"
        data-id="e5c785c7f5ae"
        fill="white" />
      <path
        d="M148.88 93.1549C149.18 93.1549 149.422 92.9121 149.422 92.6126C149.422 92.3131 149.18 92.0703 148.88 92.0703C148.58 92.0703 148.337 92.3131 148.337 92.6126C148.337 92.9121 148.58 93.1549 148.88 93.1549Z"
        data-id="7954c594b32c"
        fill="white" />
      <path
        d="M154.378 87.8847C154.168 88.0944 153.821 88.0944 153.611 87.8847C153.402 87.675 153.402 87.3279 153.611 87.1182C153.821 86.9085 154.168 86.9085 154.378 87.1182C154.595 87.3279 154.595 87.675 154.378 87.8847Z"
        data-id="ce4c65445266"
        fill="white" />
      <path
        d="M154.378 87.8847C154.168 88.0944 153.821 88.0944 153.611 87.8847C153.402 87.675 153.402 87.3279 153.611 87.1182C153.821 86.9085 154.168 86.9085 154.378 87.1182C154.595 87.3279 154.595 87.675 154.378 87.8847Z"
        data-id="282d987cb784"
        fill="white" />
      <path
        d="M159.493 82.7694C159.283 82.9791 158.936 82.9791 158.726 82.7694C158.516 82.5597 158.516 82.2127 158.726 82.003C158.936 81.7933 159.283 81.7933 159.493 82.003C159.703 82.2127 159.71 82.5525 159.493 82.7694Z"
        data-id="4cd46813c986"
        fill="white" />
      <path
        d="M159.493 82.7694C159.283 82.9791 158.936 82.9791 158.726 82.7694C158.516 82.5597 158.516 82.2127 158.726 82.003C158.936 81.7933 159.283 81.7933 159.493 82.003C159.703 82.2127 159.71 82.5525 159.493 82.7694Z"
        data-id="94d21ed8fa16"
        fill="white" />
      <path
        d="M164.232 77.8151C164.531 77.8151 164.774 77.5723 164.774 77.2728C164.774 76.9733 164.531 76.7305 164.232 76.7305C163.932 76.7305 163.689 76.9733 163.689 77.2728C163.689 77.5723 163.932 77.8151 164.232 77.8151Z"
        data-id="cdc402fcdd79"
        fill="white" />
      <path
        d="M164.232 77.8151C164.531 77.8151 164.774 77.5723 164.774 77.2728C164.774 76.9733 164.531 76.7305 164.232 76.7305C163.932 76.7305 163.689 76.9733 163.689 77.2728C163.689 77.5723 163.932 77.8151 164.232 77.8151Z"
        data-id="bc763885810b"
        fill="white" />
      <path
        d="M72.1573 159.645C72.4569 159.645 72.6998 159.402 72.6998 159.103C72.6998 158.803 72.4569 158.561 72.1573 158.561C71.8577 158.561 71.6147 158.803 71.6147 159.103C71.6147 159.402 71.8577 159.645 72.1573 159.645Z"
        data-id="7b55120a69ef"
        fill="white" />
      <path
        d="M72.1573 159.645C72.4569 159.645 72.6998 159.402 72.6998 159.103C72.6998 158.803 72.4569 158.561 72.1573 158.561C71.8577 158.561 71.6147 158.803 71.6147 159.103C71.6147 159.402 71.8577 159.645 72.1573 159.645Z"
        data-id="e67e0c79fded"
        fill="white" />
      <path
        d="M77.2711 154.536C77.5707 154.536 77.8136 154.293 77.8136 153.993C77.8136 153.694 77.5707 153.451 77.2711 153.451C76.9714 153.451 76.7285 153.694 76.7285 153.993C76.7285 154.293 76.9714 154.536 77.2711 154.536Z"
        data-id="1234b423895b"
        fill="white" />
      <path
        d="M77.2711 154.536C77.5707 154.536 77.8136 154.293 77.8136 153.993C77.8136 153.694 77.5707 153.451 77.2711 153.451C76.9714 153.451 76.7285 153.694 76.7285 153.993C76.7285 154.293 76.9714 154.536 77.2711 154.536Z"
        data-id="34d2f5b1c6f8"
        fill="white" />
      <path
        d="M82.3863 149.419C82.6859 149.419 82.9288 149.176 82.9288 148.876C82.9288 148.577 82.6859 148.334 82.3863 148.334C82.0867 148.334 81.8438 148.577 81.8438 148.876C81.8438 149.176 82.0867 149.419 82.3863 149.419Z"
        data-id="bdc6dd3d5f1b"
        fill="white" />
      <path
        d="M82.3863 149.419C82.6859 149.419 82.9288 149.176 82.9288 148.876C82.9288 148.577 82.6859 148.334 82.3863 148.334C82.0867 148.334 81.8438 148.577 81.8438 148.876C81.8438 149.176 82.0867 149.419 82.3863 149.419Z"
        data-id="e5cc7854e7af"
        fill="white" />
      <path
        d="M87.4996 144.305C87.7992 144.305 88.0421 144.063 88.0421 143.763C88.0421 143.464 87.7992 143.221 87.4996 143.221C87.1999 143.221 86.957 143.464 86.957 143.763C86.957 144.063 87.1999 144.305 87.4996 144.305Z"
        data-id="d300d33aae37"
        fill="white" />
      <path
        d="M87.4996 144.305C87.7992 144.305 88.0421 144.063 88.0421 143.763C88.0421 143.464 87.7992 143.221 87.4996 143.221C87.1999 143.221 86.957 143.464 86.957 143.763C86.957 144.063 87.1999 144.305 87.4996 144.305Z"
        data-id="a505f7f9aa2f"
        fill="white" />
      <path
        d="M92.6143 139.188C92.914 139.188 93.1569 138.945 93.1569 138.646C93.1569 138.346 92.914 138.104 92.6143 138.104C92.3147 138.104 92.0718 138.346 92.0718 138.646C92.0718 138.945 92.3147 139.188 92.6143 139.188Z"
        data-id="230ed11414fd"
        fill="white" />
      <path
        d="M92.6143 139.188C92.914 139.188 93.1569 138.945 93.1569 138.646C93.1569 138.346 92.914 138.104 92.6143 138.104C92.3147 138.104 92.0718 138.346 92.0718 138.646C92.0718 138.945 92.3147 139.188 92.6143 139.188Z"
        data-id="6705c8f52a09"
        fill="white" />
      <path
        d="M97.7291 134.077C98.0287 134.077 98.2716 133.834 98.2716 133.535C98.2716 133.235 98.0287 132.992 97.7291 132.992C97.4294 132.992 97.1865 133.235 97.1865 133.535C97.1865 133.834 97.4294 134.077 97.7291 134.077Z"
        data-id="f03c5571edee"
        fill="white" />
      <path
        d="M97.7291 134.077C98.0287 134.077 98.2716 133.834 98.2716 133.535C98.2716 133.235 98.0287 132.992 97.7291 132.992C97.4294 132.992 97.1865 133.235 97.1865 133.535C97.1865 133.834 97.4294 134.077 97.7291 134.077Z"
        data-id="2a59a2f25f8b"
        fill="white" />
      <path
        d="M102.843 128.964C103.143 128.964 103.386 128.721 103.386 128.421C103.386 128.122 103.143 127.879 102.843 127.879C102.544 127.879 102.301 128.122 102.301 128.421C102.301 128.721 102.544 128.964 102.843 128.964Z"
        data-id="bb8184b2f62c"
        fill="white" />
      <path
        d="M102.843 128.964C103.143 128.964 103.386 128.721 103.386 128.421C103.386 128.122 103.143 127.879 102.843 127.879C102.544 127.879 102.301 128.122 102.301 128.421C102.301 128.721 102.544 128.964 102.843 128.964Z"
        data-id="6be830e4cb1e"
        fill="white" />
      <path
        d="M107.958 123.848C108.258 123.848 108.501 123.605 108.501 123.306C108.501 123.006 108.258 122.764 107.958 122.764C107.658 122.764 107.416 123.006 107.416 123.306C107.416 123.605 107.658 123.848 107.958 123.848Z"
        data-id="8cdd460ca87f"
        fill="white" />
      <path
        d="M107.958 123.848C108.258 123.848 108.501 123.605 108.501 123.306C108.501 123.006 108.258 122.764 107.958 122.764C107.658 122.764 107.416 123.006 107.416 123.306C107.416 123.605 107.658 123.848 107.958 123.848Z"
        data-id="80a5ddecf8e9"
        fill="white" />
      <path
        d="M113.463 118.568C113.253 118.778 112.906 118.778 112.696 118.568C112.487 118.359 112.487 118.011 112.696 117.802C112.906 117.592 113.253 117.592 113.463 117.802C113.673 118.011 113.673 118.359 113.463 118.568Z"
        data-id="a309f5e0dea7"
        fill="white" />
      <path
        d="M113.463 118.568C113.253 118.778 112.906 118.778 112.696 118.568C112.487 118.359 112.487 118.011 112.696 117.802C112.906 117.592 113.253 117.592 113.463 117.802C113.673 118.011 113.673 118.359 113.463 118.568Z"
        data-id="c6497c4a599e"
        fill="white" />
      <path
        d="M118.577 113.453C118.368 113.663 118.02 113.663 117.811 113.453C117.601 113.243 117.601 112.896 117.811 112.687C118.02 112.477 118.368 112.477 118.577 112.687C118.787 112.903 118.787 113.243 118.577 113.453Z"
        data-id="4a3cdd6be111"
        fill="white" />
      <path
        d="M118.577 113.453C118.368 113.663 118.02 113.663 117.811 113.453C117.601 113.243 117.601 112.896 117.811 112.687C118.02 112.477 118.368 112.477 118.577 112.687C118.787 112.903 118.787 113.243 118.577 113.453Z"
        data-id="f8b9fb34952e"
        fill="white" />
      <path
        d="M123.309 108.499C123.609 108.499 123.852 108.256 123.852 107.956C123.852 107.657 123.609 107.414 123.309 107.414C123.01 107.414 122.767 107.657 122.767 107.956C122.767 108.256 123.01 108.499 123.309 108.499Z"
        data-id="a8c2ff496d3b"
        fill="white" />
      <path
        d="M123.309 108.499C123.609 108.499 123.852 108.256 123.852 107.956C123.852 107.657 123.609 107.414 123.309 107.414C123.01 107.414 122.767 107.657 122.767 107.956C122.767 108.256 123.01 108.499 123.309 108.499Z"
        data-id="a9d64ba4f16d"
        fill="white" />
      <path
        d="M128.806 103.225C128.596 103.434 128.249 103.434 128.039 103.225C127.829 103.015 127.829 102.668 128.039 102.458C128.249 102.248 128.596 102.248 128.806 102.458C129.016 102.668 129.016 103.015 128.806 103.225Z"
        data-id="7a9d8056700c"
        fill="white" />
      <path
        d="M128.806 103.225C128.596 103.434 128.249 103.434 128.039 103.225C127.829 103.015 127.829 102.668 128.039 102.458C128.249 102.248 128.596 102.248 128.806 102.458C129.016 102.668 129.016 103.015 128.806 103.225Z"
        data-id="e692ff155fd5"
        fill="white" />
      <path
        d="M133.921 98.1093C133.711 98.319 133.364 98.319 133.154 98.1093C132.945 97.8996 132.945 97.5525 133.154 97.3428C133.364 97.1331 133.711 97.1331 133.921 97.3428C134.131 97.5525 134.131 97.8996 133.921 98.1093Z"
        data-id="0ec63de0d2a7"
        fill="white" />
      <path
        d="M133.921 98.1093C133.711 98.319 133.364 98.319 133.154 98.1093C132.945 97.8996 132.945 97.5525 133.154 97.3428C133.364 97.1331 133.711 97.1331 133.921 97.3428C134.131 97.5525 134.131 97.8996 133.921 98.1093Z"
        data-id="12569085a278"
        fill="white" />
      <path
        d="M139.035 92.9941C138.825 93.2037 138.478 93.2037 138.268 92.9941C138.058 92.7844 138.058 92.4373 138.268 92.2276C138.478 92.0179 138.825 92.0179 139.035 92.2276C139.245 92.4373 139.245 92.7844 139.035 92.9941Z"
        data-id="286d28ffff00"
        fill="white" />
      <path
        d="M139.035 92.9941C138.825 93.2037 138.478 93.2037 138.268 92.9941C138.058 92.7844 138.058 92.4373 138.268 92.2276C138.478 92.0179 138.825 92.0179 139.035 92.2276C139.245 92.4373 139.245 92.7844 139.035 92.9941Z"
        data-id="f762e9efdd9a"
        fill="white" />
      <path
        d="M144.15 87.8808C143.94 88.0905 143.593 88.0905 143.383 87.8808C143.173 87.6711 143.173 87.324 143.383 87.1143C143.593 86.9046 143.94 86.9046 144.15 87.1143C144.36 87.324 144.36 87.6711 144.15 87.8808Z"
        data-id="b6ae2524e58a"
        fill="white" />
      <path
        d="M144.15 87.8808C143.94 88.0905 143.593 88.0905 143.383 87.8808C143.173 87.6711 143.173 87.324 143.383 87.1143C143.593 86.9046 143.94 86.9046 144.15 87.1143C144.36 87.324 144.36 87.6711 144.15 87.8808Z"
        data-id="8162b2dba42c"
        fill="white" />
      <path
        d="M149.264 82.7675C149.054 82.9772 148.707 82.9772 148.497 82.7675C148.287 82.5578 148.287 82.2107 148.497 82.001C148.707 81.7913 149.054 81.7913 149.264 82.001C149.474 82.2107 149.481 82.5506 149.264 82.7675Z"
        data-id="821fb225292e"
        fill="white" />
      <path
        d="M149.264 82.7675C149.054 82.9772 148.707 82.9772 148.497 82.7675C148.287 82.5578 148.287 82.2107 148.497 82.001C148.707 81.7913 149.054 81.7913 149.264 82.001C149.474 82.2107 149.481 82.5506 149.264 82.7675Z"
        data-id="57c4dc403ab6"
        fill="white" />
      <path
        d="M154.379 77.6542C154.169 77.8639 153.822 77.8639 153.612 77.6542C153.402 77.4445 153.402 77.0974 153.612 76.8877C153.822 76.678 154.169 76.678 154.379 76.8877C154.596 77.0974 154.596 77.4373 154.379 77.6542Z"
        data-id="3f5ff527c930"
        fill="white" />
      <path
        d="M154.379 77.6542C154.169 77.8639 153.822 77.8639 153.612 77.6542C153.402 77.4445 153.402 77.0974 153.612 76.8877C153.822 76.678 154.169 76.678 154.379 76.8877C154.596 77.0974 154.596 77.4373 154.379 77.6542Z"
        data-id="ae68110fc0e7"
        fill="white" />
      <path
        d="M159.493 72.539C159.283 72.7487 158.936 72.7487 158.726 72.539C158.516 72.3293 158.516 71.9822 158.726 71.7725C158.936 71.5628 159.283 71.5628 159.493 71.7725C159.71 71.9822 159.71 72.3221 159.493 72.539Z"
        data-id="b4f9f1e02b49"
        fill="white" />
      <path
        d="M159.493 72.539C159.283 72.7487 158.936 72.7487 158.726 72.539C158.516 72.3293 158.516 71.9822 158.726 71.7725C158.936 71.5628 159.283 71.5628 159.493 71.7725C159.71 71.9822 159.71 72.3221 159.493 72.539Z"
        data-id="99cc51802381"
        fill="white" />
      <path
        d="M67.0435 154.534C67.3432 154.534 67.5861 154.291 67.5861 153.992C67.5861 153.692 67.3432 153.449 67.0435 153.449C66.7439 153.449 66.501 153.692 66.501 153.992C66.501 154.291 66.7439 154.534 67.0435 154.534Z"
        data-id="b6af05ffd080"
        fill="white" />
      <path
        d="M67.0435 154.534C67.3432 154.534 67.5861 154.291 67.5861 153.992C67.5861 153.692 67.3432 153.449 67.0435 153.449C66.7439 153.449 66.501 153.692 66.501 153.992C66.501 154.291 66.7439 154.534 67.0435 154.534Z"
        data-id="d2366c0e1292"
        fill="white" />
      <path
        d="M72.1578 149.419C72.4574 149.419 72.7003 149.176 72.7003 148.876C72.7003 148.577 72.4574 148.334 72.1578 148.334C71.8581 148.334 71.6152 148.577 71.6152 148.876C71.6152 149.176 71.8581 149.419 72.1578 149.419Z"
        data-id="8cf722524173"
        fill="white" />
      <path
        d="M72.1578 149.419C72.4574 149.419 72.7003 149.176 72.7003 148.876C72.7003 148.577 72.4574 148.334 72.1578 148.334C71.8581 148.334 71.6152 148.577 71.6152 148.876C71.6152 149.176 71.8581 149.419 72.1578 149.419Z"
        data-id="03b6bb121368"
        fill="white" />
      <path
        d="M77.272 144.305C77.5717 144.305 77.8146 144.063 77.8146 143.763C77.8146 143.464 77.5717 143.221 77.272 143.221C76.9724 143.221 76.7295 143.464 76.7295 143.763C76.7295 144.063 76.9724 144.305 77.272 144.305Z"
        data-id="89b993f4a922"
        fill="white" />
      <path
        d="M77.272 144.305C77.5717 144.305 77.8146 144.063 77.8146 143.763C77.8146 143.464 77.5717 143.221 77.272 143.221C76.9724 143.221 76.7295 143.464 76.7295 143.763C76.7295 144.063 76.9724 144.305 77.272 144.305Z"
        data-id="391179d8edab"
        fill="white" />
      <path
        d="M82.3863 139.192C82.6859 139.192 82.9288 138.949 82.9288 138.65C82.9288 138.35 82.6859 138.107 82.3863 138.107C82.0867 138.107 81.8438 138.35 81.8438 138.65C81.8438 138.949 82.0867 139.192 82.3863 139.192Z"
        data-id="133b4704d170"
        fill="white" />
      <path
        d="M82.3863 139.192C82.6859 139.192 82.9288 138.949 82.9288 138.65C82.9288 138.35 82.6859 138.107 82.3863 138.107C82.0867 138.107 81.8438 138.35 81.8438 138.65C81.8438 138.949 82.0867 139.192 82.3863 139.192Z"
        data-id="4efba121f06b"
        fill="white" />
      <path
        d="M87.5001 134.077C87.7997 134.077 88.0426 133.834 88.0426 133.535C88.0426 133.235 87.7997 132.992 87.5001 132.992C87.2004 132.992 86.9575 133.235 86.9575 133.535C86.9575 133.834 87.2004 134.077 87.5001 134.077Z"
        data-id="803e0e0c1215"
        fill="white" />
      <path
        d="M87.5001 134.077C87.7997 134.077 88.0426 133.834 88.0426 133.535C88.0426 133.235 87.7997 132.992 87.5001 132.992C87.2004 132.992 86.9575 133.235 86.9575 133.535C86.9575 133.834 87.2004 134.077 87.5001 134.077Z"
        data-id="cb9cf02ad053"
        fill="white" />
      <path
        d="M92.6148 128.964C92.9145 128.964 93.1574 128.721 93.1574 128.421C93.1574 128.122 92.9145 127.879 92.6148 127.879C92.3152 127.879 92.0723 128.122 92.0723 128.421C92.0723 128.721 92.3152 128.964 92.6148 128.964Z"
        data-id="87972251e660"
        fill="white" />
      <path
        d="M92.6148 128.964C92.9145 128.964 93.1574 128.721 93.1574 128.421C93.1574 128.122 92.9145 127.879 92.6148 127.879C92.3152 127.879 92.0723 128.122 92.0723 128.421C92.0723 128.721 92.3152 128.964 92.6148 128.964Z"
        data-id="1e16340d8809"
        fill="white" />
      <path
        d="M97.7296 123.848C98.0292 123.848 98.2721 123.605 98.2721 123.306C98.2721 123.006 98.0292 122.764 97.7296 122.764C97.4299 122.764 97.187 123.006 97.187 123.306C97.187 123.605 97.4299 123.848 97.7296 123.848Z"
        data-id="2d53d850b857"
        fill="white" />
      <path
        d="M97.7296 123.848C98.0292 123.848 98.2721 123.605 98.2721 123.306C98.2721 123.006 98.0292 122.764 97.7296 122.764C97.4299 122.764 97.187 123.006 97.187 123.306C97.187 123.605 97.4299 123.848 97.7296 123.848Z"
        data-id="17bf87e3d2e5"
        fill="white" />
      <path
        d="M102.843 118.729C103.143 118.729 103.386 118.486 103.386 118.187C103.386 117.887 103.143 117.645 102.843 117.645C102.544 117.645 102.301 117.887 102.301 118.187C102.301 118.486 102.544 118.729 102.843 118.729Z"
        data-id="c2dacedc3ce5"
        fill="white" />
      <path
        d="M102.843 118.729C103.143 118.729 103.386 118.486 103.386 118.187C103.386 117.887 103.143 117.645 102.843 117.645C102.544 117.645 102.301 117.887 102.301 118.187C102.301 118.486 102.544 118.729 102.843 118.729Z"
        data-id="b30c67542400"
        fill="white" />
      <path
        d="M108.342 113.453C108.132 113.663 107.785 113.663 107.575 113.453C107.365 113.243 107.365 112.896 107.575 112.687C107.785 112.477 108.132 112.477 108.342 112.687C108.551 112.896 108.559 113.243 108.342 113.453Z"
        data-id="488aaedf2fe1"
        fill="white" />
      <path
        d="M108.342 113.453C108.132 113.663 107.785 113.663 107.575 113.453C107.365 113.243 107.365 112.896 107.575 112.687C107.785 112.477 108.132 112.477 108.342 112.687C108.551 112.896 108.559 113.243 108.342 113.453Z"
        data-id="6c35f54b8af3"
        fill="white" />
      <path
        d="M113.464 108.34C113.254 108.549 112.907 108.549 112.697 108.34C112.487 108.13 112.487 107.783 112.697 107.573C112.907 107.364 113.254 107.364 113.464 107.573C113.673 107.783 113.673 108.13 113.464 108.34Z"
        data-id="cd878f962cf9"
        fill="white" />
      <path
        d="M113.464 108.34C113.254 108.549 112.907 108.549 112.697 108.34C112.487 108.13 112.487 107.783 112.697 107.573C112.907 107.364 113.254 107.364 113.464 107.573C113.673 107.783 113.673 108.13 113.464 108.34Z"
        data-id="17918868d991"
        fill="white" />
      <path
        d="M118.194 103.383C118.494 103.383 118.737 103.141 118.737 102.841C118.737 102.542 118.494 102.299 118.194 102.299C117.895 102.299 117.652 102.542 117.652 102.841C117.652 103.141 117.895 103.383 118.194 103.383Z"
        data-id="3ab867aaa582"
        fill="white" />
      <path
        d="M118.194 103.383C118.494 103.383 118.737 103.141 118.737 102.841C118.737 102.542 118.494 102.299 118.194 102.299C117.895 102.299 117.652 102.542 117.652 102.841C117.652 103.141 117.895 103.383 118.194 103.383Z"
        data-id="c8aa8ccd7ec0"
        fill="white" />
      <path
        d="M123.693 98.1112C123.483 98.3209 123.136 98.3209 122.926 98.1112C122.716 97.9015 122.716 97.5545 122.926 97.3448C123.136 97.1351 123.483 97.1351 123.693 97.3448C123.902 97.5545 123.902 97.9015 123.693 98.1112Z"
        data-id="ad58246a85bd"
        fill="white" />
      <path
        d="M123.693 98.1112C123.483 98.3209 123.136 98.3209 122.926 98.1112C122.716 97.9015 122.716 97.5545 122.926 97.3448C123.136 97.1351 123.483 97.1351 123.693 97.3448C123.902 97.5545 123.902 97.9015 123.693 98.1112Z"
        data-id="5657b1109913"
        fill="white" />
      <path
        d="M128.807 92.9999C128.597 93.2096 128.25 93.2096 128.04 92.9999C127.83 92.7902 127.83 92.4431 128.04 92.2334C128.25 92.0237 128.597 92.0237 128.807 92.2334C129.017 92.4431 129.017 92.7902 128.807 92.9999Z"
        data-id="ce9ccfb103c2"
        fill="white" />
      <path
        d="M128.807 92.9999C128.597 93.2096 128.25 93.2096 128.04 92.9999C127.83 92.7902 127.83 92.4431 128.04 92.2334C128.25 92.0237 128.597 92.0237 128.807 92.2334C129.017 92.4431 129.017 92.7902 128.807 92.9999Z"
        data-id="a91531f4b259"
        fill="white" />
      <path
        d="M133.921 87.8828C133.711 88.0925 133.364 88.0925 133.154 87.8828C132.944 87.6731 132.944 87.326 133.154 87.1163C133.364 86.9066 133.711 86.9066 133.921 87.1163C134.131 87.326 134.131 87.6731 133.921 87.8828Z"
        data-id="d9f18c5375fc"
        fill="white" />
      <path
        d="M133.921 87.8828C133.711 88.0925 133.364 88.0925 133.154 87.8828C132.944 87.6731 132.944 87.326 133.154 87.1163C133.364 86.9066 133.711 86.9066 133.921 87.1163C134.131 87.326 134.131 87.6731 133.921 87.8828Z"
        data-id="b92fec63433a"
        fill="white" />
      <path
        d="M139.036 82.7675C138.826 82.9772 138.479 82.9772 138.269 82.7675C138.059 82.5578 138.059 82.2107 138.269 82.001C138.479 81.7913 138.826 81.7913 139.036 82.001C139.246 82.2107 139.246 82.5506 139.036 82.7675Z"
        data-id="5b9090bd4c87"
        fill="white" />
      <path
        d="M139.036 82.7675C138.826 82.9772 138.479 82.9772 138.269 82.7675C138.059 82.5578 138.059 82.2107 138.269 82.001C138.479 81.7913 138.826 81.7913 139.036 82.001C139.246 82.2107 139.246 82.5506 139.036 82.7675Z"
        data-id="3c0e01e937da"
        fill="white" />
      <path
        d="M144.15 77.6523C143.94 77.862 143.593 77.862 143.383 77.6523C143.174 77.4426 143.174 77.0955 143.383 76.8858C143.593 76.6761 143.94 76.6761 144.15 76.8858C144.36 77.0955 144.36 77.4353 144.15 77.6523Z"
        data-id="64df29de17d8"
        fill="white" />
      <path
        d="M144.15 77.6523C143.94 77.862 143.593 77.862 143.383 77.6523C143.174 77.4426 143.174 77.0955 143.383 76.8858C143.593 76.6761 143.94 76.6761 144.15 76.8858C144.36 77.0955 144.36 77.4353 144.15 77.6523Z"
        data-id="ee6115af866d"
        fill="white" />
      <path
        d="M149.264 72.5409C149.054 72.7506 148.707 72.7506 148.497 72.5409C148.287 72.3312 148.287 71.9842 148.497 71.7745C148.707 71.5648 149.054 71.5648 149.264 71.7745C149.481 71.9842 149.481 72.324 149.264 72.5409Z"
        data-id="d3dc740076c9"
        fill="white" />
      <path
        d="M149.264 72.5409C149.054 72.7506 148.707 72.7506 148.497 72.5409C148.287 72.3312 148.287 71.9842 148.497 71.7745C148.707 71.5648 149.054 71.5648 149.264 71.7745C149.481 71.9842 149.481 72.324 149.264 72.5409Z"
        data-id="2695258a259f"
        fill="white" />
      <path
        d="M154.38 67.4179C154.17 67.6276 153.823 67.6276 153.613 67.4179C153.403 67.2082 153.403 66.8611 153.613 66.6514C153.823 66.4417 154.17 66.4417 154.38 66.6514C154.597 66.8683 154.597 67.2082 154.38 67.4179Z"
        data-id="56b3151ddb2b"
        fill="white" />
      <path
        d="M154.38 67.4179C154.17 67.6276 153.823 67.6276 153.613 67.4179C153.403 67.2082 153.403 66.8611 153.613 66.6514C153.823 66.4417 154.17 66.4417 154.38 66.6514C154.597 66.8683 154.597 67.2082 154.38 67.4179Z"
        data-id="730ce7af8e2f"
        fill="white" />
      <path
        d="M61.9219 149.419C62.2216 149.419 62.4645 149.176 62.4645 148.876C62.4645 148.577 62.2216 148.334 61.9219 148.334C61.6223 148.334 61.3794 148.577 61.3794 148.876C61.3794 149.176 61.6223 149.419 61.9219 149.419Z"
        data-id="254d469a3c58"
        fill="white" />
      <path
        d="M61.9219 149.419C62.2216 149.419 62.4645 149.176 62.4645 148.876C62.4645 148.577 62.2216 148.334 61.9219 148.334C61.6223 148.334 61.3794 148.577 61.3794 148.876C61.3794 149.176 61.6223 149.419 61.9219 149.419Z"
        data-id="d03f05c67f0a"
        fill="white" />
      <path
        d="M67.044 144.307C67.3437 144.307 67.5866 144.064 67.5866 143.765C67.5866 143.465 67.3437 143.223 67.044 143.223C66.7444 143.223 66.5015 143.465 66.5015 143.765C66.5015 144.064 66.7444 144.307 67.044 144.307Z"
        data-id="249d7b820588"
        fill="white" />
      <path
        d="M67.044 144.307C67.3437 144.307 67.5866 144.064 67.5866 143.765C67.5866 143.465 67.3437 143.223 67.044 143.223C66.7444 143.223 66.5015 143.465 66.5015 143.765C66.5015 144.064 66.7444 144.307 67.044 144.307Z"
        data-id="4e267ad29844"
        fill="white" />
      <path
        d="M72.1583 139.192C72.4579 139.192 72.7008 138.949 72.7008 138.65C72.7008 138.35 72.4579 138.107 72.1583 138.107C71.8586 138.107 71.6157 138.35 71.6157 138.65C71.6157 138.949 71.8586 139.192 72.1583 139.192Z"
        data-id="48a970eac511"
        fill="white" />
      <path
        d="M72.1583 139.192C72.4579 139.192 72.7008 138.949 72.7008 138.65C72.7008 138.35 72.4579 138.107 72.1583 138.107C71.8586 138.107 71.6157 138.35 71.6157 138.65C71.6157 138.949 71.8586 139.192 72.1583 139.192Z"
        data-id="88d4366fa414"
        fill="white" />
      <path
        d="M77.2716 134.079C77.5712 134.079 77.8141 133.836 77.8141 133.536C77.8141 133.237 77.5712 132.994 77.2716 132.994C76.9719 132.994 76.729 133.237 76.729 133.536C76.729 133.836 76.9719 134.079 77.2716 134.079Z"
        data-id="06df639814c6"
        fill="white" />
      <path
        d="M77.2716 134.079C77.5712 134.079 77.8141 133.836 77.8141 133.536C77.8141 133.237 77.5712 132.994 77.2716 132.994C76.9719 132.994 76.729 133.237 76.729 133.536C76.729 133.836 76.9719 134.079 77.2716 134.079Z"
        data-id="b189aa169e3e"
        fill="white" />
      <path
        d="M82.3868 128.964C82.6864 128.964 82.9293 128.721 82.9293 128.421C82.9293 128.122 82.6864 127.879 82.3868 127.879C82.0871 127.879 81.8442 128.122 81.8442 128.421C81.8442 128.721 82.0871 128.964 82.3868 128.964Z"
        data-id="6b3c9ce5591b"
        fill="white" />
      <path
        d="M82.3868 128.964C82.6864 128.964 82.9293 128.721 82.9293 128.421C82.9293 128.122 82.6864 127.879 82.3868 127.879C82.0871 127.879 81.8442 128.122 81.8442 128.421C81.8442 128.721 82.0871 128.964 82.3868 128.964Z"
        data-id="d686623f89b0"
        fill="white" />
      <path
        d="M87.5006 123.85C87.8002 123.85 88.0431 123.607 88.0431 123.308C88.0431 123.008 87.8002 122.766 87.5006 122.766C87.2009 122.766 86.958 123.008 86.958 123.308C86.958 123.607 87.2009 123.85 87.5006 123.85Z"
        data-id="bc397b537786"
        fill="white" />
      <path
        d="M87.5006 123.85C87.8002 123.85 88.0431 123.607 88.0431 123.308C88.0431 123.008 87.8002 122.766 87.5006 122.766C87.2009 122.766 86.958 123.008 86.958 123.308C86.958 123.607 87.2009 123.85 87.5006 123.85Z"
        data-id="5f61b0e7b7b8"
        fill="white" />
      <path
        d="M92.6148 118.731C92.9145 118.731 93.1574 118.488 93.1574 118.189C93.1574 117.889 92.9145 117.646 92.6148 117.646C92.3152 117.646 92.0723 117.889 92.0723 118.189C92.0723 118.488 92.3152 118.731 92.6148 118.731Z"
        data-id="89afea871592"
        fill="white" />
      <path
        d="M92.6148 118.731C92.9145 118.731 93.1574 118.488 93.1574 118.189C93.1574 117.889 92.9145 117.646 92.6148 117.646C92.3152 117.646 92.0723 117.889 92.0723 118.189C92.0723 118.488 92.3152 118.731 92.6148 118.731Z"
        data-id="d2903d4159f1"
        fill="white" />
      <path
        d="M98.1131 113.453C97.9033 113.663 97.5561 113.663 97.3463 113.453C97.1365 113.243 97.1365 112.896 97.3463 112.687C97.5561 112.477 97.9033 112.477 98.1131 112.687C98.3229 112.903 98.3229 113.243 98.1131 113.453Z"
        data-id="16c58d52e526"
        fill="white" />
      <path
        d="M98.1131 113.453C97.9033 113.663 97.5561 113.663 97.3463 113.453C97.1365 113.243 97.1365 112.896 97.3463 112.687C97.5561 112.477 97.9033 112.477 98.1131 112.687C98.3229 112.903 98.3229 113.243 98.1131 113.453Z"
        data-id="41adc9a72e79"
        fill="white" />
      <path
        d="M102.845 108.501C103.144 108.501 103.387 108.258 103.387 107.958C103.387 107.659 103.144 107.416 102.845 107.416C102.545 107.416 102.302 107.659 102.302 107.958C102.302 108.258 102.545 108.501 102.845 108.501Z"
        data-id="9e042c48b641"
        fill="white" />
      <path
        d="M102.845 108.501C103.144 108.501 103.387 108.258 103.387 107.958C103.387 107.659 103.144 107.416 102.845 107.416C102.545 107.416 102.302 107.659 102.302 107.958C102.302 108.258 102.545 108.501 102.845 108.501Z"
        data-id="5405ba0adaa4"
        fill="white" />
      <path
        d="M107.959 103.385C108.258 103.385 108.501 103.143 108.501 102.843C108.501 102.544 108.258 102.301 107.959 102.301C107.659 102.301 107.416 102.544 107.416 102.843C107.416 103.143 107.659 103.385 107.959 103.385Z"
        data-id="31de435f0dd8"
        fill="white" />
      <path
        d="M107.959 103.385C108.258 103.385 108.501 103.143 108.501 102.843C108.501 102.544 108.258 102.301 107.959 102.301C107.659 102.301 107.416 102.544 107.416 102.843C107.416 103.143 107.659 103.385 107.959 103.385Z"
        data-id="5ca8a767ab93"
        fill="white" />
      <path
        d="M113.464 98.1132C113.254 98.3229 112.907 98.3229 112.697 98.1132C112.487 97.9035 112.487 97.5564 112.697 97.3467C112.907 97.137 113.254 97.137 113.464 97.3467C113.673 97.5564 113.673 97.9035 113.464 98.1132Z"
        data-id="2a2516eeef60"
        fill="white" />
      <path
        d="M113.464 98.1132C113.254 98.3229 112.907 98.3229 112.697 98.1132C112.487 97.9035 112.487 97.5564 112.697 97.3467C112.907 97.137 113.254 97.137 113.464 97.3467C113.673 97.5564 113.673 97.9035 113.464 98.1132Z"
        data-id="734c56000b5c"
        fill="white" />
      <path
        d="M118.194 93.1549C118.494 93.1549 118.737 92.9121 118.737 92.6126C118.737 92.3131 118.494 92.0703 118.194 92.0703C117.895 92.0703 117.652 92.3131 117.652 92.6126C117.652 92.9121 117.895 93.1549 118.194 93.1549Z"
        data-id="dacc9a4d5d93"
        fill="white" />
      <path
        d="M118.194 93.1549C118.494 93.1549 118.737 92.9121 118.737 92.6126C118.737 92.3131 118.494 92.0703 118.194 92.0703C117.895 92.0703 117.652 92.3131 117.652 92.6126C117.652 92.9121 117.895 93.1549 118.194 93.1549Z"
        data-id="17136575272d"
        fill="white" />
      <path
        d="M123.693 87.8828C123.483 88.0925 123.136 88.0925 122.926 87.8828C122.716 87.6731 122.716 87.326 122.926 87.1163C123.136 86.9066 123.483 86.9066 123.693 87.1163C123.902 87.326 123.902 87.6731 123.693 87.8828Z"
        data-id="026d19ee17db"
        fill="white" />
      <path
        d="M123.693 87.8828C123.483 88.0925 123.136 88.0925 122.926 87.8828C122.716 87.6731 122.716 87.326 122.926 87.1163C123.136 86.9066 123.483 86.9066 123.693 87.1163C123.902 87.326 123.902 87.6731 123.693 87.8828Z"
        data-id="cbf00b2e463e"
        fill="white" />
      <path
        d="M128.807 82.7694C128.597 82.9791 128.25 82.9791 128.04 82.7694C127.83 82.5597 127.83 82.2127 128.04 82.003C128.25 81.7933 128.597 81.7933 128.807 82.003C129.017 82.2127 129.017 82.5525 128.807 82.7694Z"
        data-id="44b2050f0cd8"
        fill="white" />
      <path
        d="M128.807 82.7694C128.597 82.9791 128.25 82.9791 128.04 82.7694C127.83 82.5597 127.83 82.2127 128.04 82.003C128.25 81.7933 128.597 81.7933 128.807 82.003C129.017 82.2127 129.017 82.5525 128.807 82.7694Z"
        data-id="e0d0a77466b1"
        fill="white" />
      <path
        d="M133.538 77.8131C133.837 77.8131 134.08 77.5703 134.08 77.2708C134.08 76.9713 133.837 76.7285 133.538 76.7285C133.238 76.7285 132.995 76.9713 132.995 77.2708C132.995 77.5703 133.238 77.8131 133.538 77.8131Z"
        data-id="4021a80edc0d"
        fill="white" />
      <path
        d="M133.538 77.8131C133.837 77.8131 134.08 77.5703 134.08 77.2708C134.08 76.9713 133.837 76.7285 133.538 76.7285C133.238 76.7285 132.995 76.9713 132.995 77.2708C132.995 77.5703 133.238 77.8131 133.538 77.8131Z"
        data-id="3bdadcf2f810"
        fill="white" />
      <path
        d="M139.036 72.539C138.826 72.7487 138.479 72.7487 138.269 72.539C138.059 72.3293 138.059 71.9822 138.269 71.7725C138.479 71.5628 138.826 71.5628 139.036 71.7725C139.246 71.9822 139.246 72.3221 139.036 72.539Z"
        data-id="36dc38242694"
        fill="white" />
      <path
        d="M139.036 72.539C138.826 72.7487 138.479 72.7487 138.269 72.539C138.059 72.3293 138.059 71.9822 138.269 71.7725C138.479 71.5628 138.826 71.5628 139.036 71.7725C139.246 71.9822 139.246 72.3221 139.036 72.539Z"
        data-id="308c8c77cdfa"
        fill="white" />
      <path
        d="M144.15 67.4179C143.94 67.6276 143.593 67.6276 143.383 67.4179C143.174 67.2082 143.174 66.8611 143.383 66.6514C143.593 66.4417 143.94 66.4417 144.15 66.6514C144.36 66.8611 144.36 67.2082 144.15 67.4179Z"
        data-id="c86ddb0fee4c"
        fill="white" />
      <path
        d="M144.15 67.4179C143.94 67.6276 143.593 67.6276 143.383 67.4179C143.174 67.2082 143.174 66.8611 143.383 66.6514C143.593 66.4417 143.94 66.4417 144.15 66.6514C144.36 66.8611 144.36 67.2082 144.15 67.4179Z"
        data-id="765f4ad4d0ce"
        fill="white" />
      <path
        d="M148.881 62.4655C149.181 62.4655 149.424 62.2227 149.424 61.9232C149.424 61.6237 149.181 61.3809 148.881 61.3809C148.582 61.3809 148.339 61.6237 148.339 61.9232C148.339 62.2227 148.582 62.4655 148.881 62.4655Z"
        data-id="92dfd1f0f28e"
        fill="white" />
      <path
        d="M148.881 62.4655C149.181 62.4655 149.424 62.2227 149.424 61.9232C149.424 61.6237 149.181 61.3809 148.881 61.3809C148.582 61.3809 148.339 61.6237 148.339 61.9232C148.339 62.2227 148.582 62.4655 148.881 62.4655Z"
        data-id="bda788ae0619"
        fill="white" />
      <path
        d="M56.8072 144.307C57.1068 144.307 57.3497 144.064 57.3497 143.765C57.3497 143.465 57.1068 143.223 56.8072 143.223C56.5076 143.223 56.2646 143.465 56.2646 143.765C56.2646 144.064 56.5076 144.307 56.8072 144.307Z"
        data-id="8fe70b2f2417"
        fill="white" />
      <path
        d="M56.8072 144.307C57.1068 144.307 57.3497 144.064 57.3497 143.765C57.3497 143.465 57.1068 143.223 56.8072 143.223C56.5076 143.223 56.2646 143.465 56.2646 143.765C56.2646 144.064 56.5076 144.307 56.8072 144.307Z"
        data-id="6b62ad0a8fad"
        fill="white" />
      <path
        d="M61.9219 139.192C62.2216 139.192 62.4645 138.949 62.4645 138.65C62.4645 138.35 62.2216 138.107 61.9219 138.107C61.6223 138.107 61.3794 138.35 61.3794 138.65C61.3794 138.949 61.6223 139.192 61.9219 139.192Z"
        data-id="3c245177270c"
        fill="white" />
      <path
        d="M61.9219 139.192C62.2216 139.192 62.4645 138.949 62.4645 138.65C62.4645 138.35 62.2216 138.107 61.9219 138.107C61.6223 138.107 61.3794 138.35 61.3794 138.65C61.3794 138.949 61.6223 139.192 61.9219 139.192Z"
        data-id="6534f728dfec"
        fill="white" />
      <path
        d="M67.0435 134.079C67.3432 134.079 67.5861 133.836 67.5861 133.536C67.5861 133.237 67.3432 132.994 67.0435 132.994C66.7439 132.994 66.501 133.237 66.501 133.536C66.501 133.836 66.7439 134.079 67.0435 134.079Z"
        data-id="e471aebffffa"
        fill="white" />
      <path
        d="M67.0435 134.079C67.3432 134.079 67.5861 133.836 67.5861 133.536C67.5861 133.237 67.3432 132.994 67.0435 132.994C66.7439 132.994 66.501 133.237 66.501 133.536C66.501 133.836 66.7439 134.079 67.0435 134.079Z"
        data-id="4e71ac38e6bd"
        fill="white" />
      <path
        d="M72.1573 128.965C72.4569 128.965 72.6998 128.723 72.6998 128.423C72.6998 128.124 72.4569 127.881 72.1573 127.881C71.8577 127.881 71.6147 128.124 71.6147 128.423C71.6147 128.723 71.8577 128.965 72.1573 128.965Z"
        data-id="66633acf76e9"
        fill="white" />
      <path
        d="M72.1573 128.965C72.4569 128.965 72.6998 128.723 72.6998 128.423C72.6998 128.124 72.4569 127.881 72.1573 127.881C71.8577 127.881 71.6147 128.124 71.6147 128.423C71.6147 128.723 71.8577 128.965 72.1573 128.965Z"
        data-id="09a3ff8c0a7d"
        fill="white" />
      <path
        d="M77.2716 123.85C77.5712 123.85 77.8141 123.607 77.8141 123.308C77.8141 123.008 77.5712 122.766 77.2716 122.766C76.9719 122.766 76.729 123.008 76.729 123.308C76.729 123.607 76.9719 123.85 77.2716 123.85Z"
        data-id="b6655296e6ba"
        fill="white" />
      <path
        d="M77.2716 123.85C77.5712 123.85 77.8141 123.607 77.8141 123.308C77.8141 123.008 77.5712 122.766 77.2716 122.766C76.9719 122.766 76.729 123.008 76.729 123.308C76.729 123.607 76.9719 123.85 77.2716 123.85Z"
        data-id="c79739911f00"
        fill="white" />
      <path
        d="M82.3858 118.731C82.6855 118.731 82.9284 118.488 82.9284 118.189C82.9284 117.889 82.6855 117.646 82.3858 117.646C82.0862 117.646 81.8433 117.889 81.8433 118.189C81.8433 118.488 82.0862 118.731 82.3858 118.731Z"
        data-id="86a2d186716d"
        fill="white" />
      <path
        d="M82.3858 118.731C82.6855 118.731 82.9284 118.488 82.9284 118.189C82.9284 117.889 82.6855 117.646 82.3858 117.646C82.0862 117.646 81.8433 117.889 81.8433 118.189C81.8433 118.488 82.0862 118.731 82.3858 118.731Z"
        data-id="79e091fc4158"
        fill="white" />
      <path
        d="M87.8841 113.455C87.6743 113.665 87.3271 113.665 87.1173 113.455C86.9075 113.245 86.9075 112.898 87.1173 112.689C87.3271 112.479 87.6743 112.479 87.8841 112.689C88.0939 112.905 88.0939 113.245 87.8841 113.455Z"
        data-id="4b7080086a10"
        fill="white" />
      <path
        d="M87.8841 113.455C87.6743 113.665 87.3271 113.665 87.1173 113.455C86.9075 113.245 86.9075 112.898 87.1173 112.689C87.3271 112.479 87.6743 112.479 87.8841 112.689C88.0939 112.905 88.0939 113.245 87.8841 113.455Z"
        data-id="d22c2b617373"
        fill="white" />
      <path
        d="M92.6148 108.501C92.9145 108.501 93.1574 108.258 93.1574 107.958C93.1574 107.659 92.9145 107.416 92.6148 107.416C92.3152 107.416 92.0723 107.659 92.0723 107.958C92.0723 108.258 92.3152 108.501 92.6148 108.501Z"
        data-id="00d902024ca1"
        fill="white" />
      <path
        d="M92.6148 108.501C92.9145 108.501 93.1574 108.258 93.1574 107.958C93.1574 107.659 92.9145 107.416 92.6148 107.416C92.3152 107.416 92.0723 107.659 92.0723 107.958C92.0723 108.258 92.3152 108.501 92.6148 108.501Z"
        data-id="ae5850483c1e"
        fill="white" />
      <path
        d="M97.7296 103.385C98.0292 103.385 98.2721 103.143 98.2721 102.843C98.2721 102.544 98.0292 102.301 97.7296 102.301C97.4299 102.301 97.187 102.544 97.187 102.843C97.187 103.143 97.4299 103.385 97.7296 103.385Z"
        data-id="5d70af98d516"
        fill="white" />
      <path
        d="M97.7296 103.385C98.0292 103.385 98.2721 103.143 98.2721 102.843C98.2721 102.544 98.0292 102.301 97.7296 102.301C97.4299 102.301 97.187 102.544 97.187 102.843C97.187 103.143 97.4299 103.385 97.7296 103.385Z"
        data-id="be88a1a59485"
        fill="white" />
      <path
        d="M103.227 98.1132C103.017 98.3229 102.67 98.3229 102.46 98.1132C102.25 97.9035 102.25 97.5564 102.46 97.3467C102.67 97.137 103.017 97.137 103.227 97.3467C103.444 97.5564 103.444 97.9035 103.227 98.1132Z"
        data-id="57a1ccecc020"
        fill="white" />
      <path
        d="M103.227 98.1132C103.017 98.3229 102.67 98.3229 102.46 98.1132C102.25 97.9035 102.25 97.5564 102.46 97.3467C102.67 97.137 103.017 97.137 103.227 97.3467C103.444 97.5564 103.444 97.9035 103.227 98.1132Z"
        data-id="32fecdb741a6"
        fill="white" />
      <path
        d="M107.959 93.1569C108.258 93.1569 108.501 92.9141 108.501 92.6146C108.501 92.3151 108.258 92.0723 107.959 92.0723C107.659 92.0723 107.416 92.3151 107.416 92.6146C107.416 92.9141 107.659 93.1569 107.959 93.1569Z"
        data-id="510589103173"
        fill="white" />
      <path
        d="M107.959 93.1569C108.258 93.1569 108.501 92.9141 108.501 92.6146C108.501 92.3151 108.258 92.0723 107.959 92.0723C107.659 92.0723 107.416 92.3151 107.416 92.6146C107.416 92.9141 107.659 93.1569 107.959 93.1569Z"
        data-id="1423c184ce15"
        fill="white" />
      <path
        d="M113.463 87.8847C113.253 88.0944 112.906 88.0944 112.696 87.8847C112.487 87.675 112.487 87.3279 112.696 87.1182C112.906 86.9085 113.253 86.9085 113.463 87.1182C113.673 87.3279 113.673 87.675 113.463 87.8847Z"
        data-id="bac05939db24"
        fill="white" />
      <path
        d="M113.463 87.8847C113.253 88.0944 112.906 88.0944 112.696 87.8847C112.487 87.675 112.487 87.3279 112.696 87.1182C112.906 86.9085 113.253 86.9085 113.463 87.1182C113.673 87.3279 113.673 87.675 113.463 87.8847Z"
        data-id="7cf3b78b6de6"
        fill="white" />
      <path
        d="M118.577 82.7694C118.368 82.9791 118.02 82.9791 117.811 82.7694C117.601 82.5597 117.601 82.2127 117.811 82.003C118.02 81.7933 118.368 81.7933 118.577 82.003C118.787 82.2127 118.787 82.5525 118.577 82.7694Z"
        data-id="b56b4454ae12"
        fill="white" />
      <path
        d="M118.577 82.7694C118.368 82.9791 118.02 82.9791 117.811 82.7694C117.601 82.5597 117.601 82.2127 117.811 82.003C118.02 81.7933 118.368 81.7933 118.577 82.003C118.787 82.2127 118.787 82.5525 118.577 82.7694Z"
        data-id="611f157d96f6"
        fill="white" />
      <path
        d="M123.309 77.8151C123.609 77.8151 123.852 77.5723 123.852 77.2728C123.852 76.9733 123.609 76.7305 123.309 76.7305C123.01 76.7305 122.767 76.9733 122.767 77.2728C122.767 77.5723 123.01 77.8151 123.309 77.8151Z"
        data-id="11a2db7a2964"
        fill="white" />
      <path
        d="M123.309 77.8151C123.609 77.8151 123.852 77.5723 123.852 77.2728C123.852 76.9733 123.609 76.7305 123.309 76.7305C123.01 76.7305 122.767 76.9733 122.767 77.2728C122.767 77.5723 123.01 77.8151 123.309 77.8151Z"
        data-id="1a483fde3019"
        fill="white" />
      <path
        d="M128.806 72.5429C128.596 72.7526 128.249 72.7526 128.039 72.5429C127.829 72.3332 127.829 71.9861 128.039 71.7764C128.249 71.5667 128.596 71.5667 128.806 71.7764C129.016 71.9861 129.016 72.326 128.806 72.5429Z"
        data-id="61c0a458b7fd"
        fill="white" />
      <path
        d="M128.806 72.5429C128.596 72.7526 128.249 72.7526 128.039 72.5429C127.829 72.3332 127.829 71.9861 128.039 71.7764C128.249 71.5667 128.596 71.5667 128.806 71.7764C129.016 71.9861 129.016 72.326 128.806 72.5429Z"
        data-id="c6057c6eda7e"
        fill="white" />
      <path
        d="M133.921 67.4198C133.711 67.6295 133.364 67.6295 133.154 67.4198C132.945 67.2101 132.945 66.8631 133.154 66.6534C133.364 66.4437 133.711 66.4437 133.921 66.6534C134.131 66.8631 134.131 67.2101 133.921 67.4198Z"
        data-id="7d0b36626de1"
        fill="white" />
      <path
        d="M133.921 67.4198C133.711 67.6295 133.364 67.6295 133.154 67.4198C132.945 67.2101 132.945 66.8631 133.154 66.6534C133.364 66.4437 133.711 66.4437 133.921 66.6534C134.131 66.8631 134.131 67.2101 133.921 67.4198Z"
        data-id="0648751c56a8"
        fill="white" />
      <path
        d="M139.036 62.3066C138.826 62.5162 138.479 62.5162 138.269 62.3066C138.059 62.0969 138.059 61.7498 138.269 61.5401C138.479 61.3304 138.826 61.3304 139.036 61.5401C139.246 61.757 139.246 62.0969 139.036 62.3066Z"
        data-id="d7b36afe959f"
        fill="white" />
      <path
        d="M139.036 62.3066C138.826 62.5162 138.479 62.5162 138.269 62.3066C138.059 62.0969 138.059 61.7498 138.269 61.5401C138.479 61.3304 138.826 61.3304 139.036 61.5401C139.246 61.757 139.246 62.0969 139.036 62.3066Z"
        data-id="ae3b3eaf2d8b"
        fill="white" />
      <path
        d="M144.15 57.1913C143.94 57.401 143.593 57.401 143.383 57.1913C143.173 56.9816 143.173 56.6345 143.383 56.4248C143.593 56.2152 143.94 56.2152 144.15 56.4248C144.36 56.6345 144.36 56.9816 144.15 57.1913Z"
        data-id="fb2368eae232"
        fill="white" />
      <path
        d="M144.15 57.1913C143.94 57.401 143.593 57.401 143.383 57.1913C143.173 56.9816 143.173 56.6345 143.383 56.4248C143.593 56.2152 143.94 56.2152 144.15 56.4248C144.36 56.6345 144.36 56.9816 144.15 57.1913Z"
        data-id="2115183e4368"
        fill="white" />
      <path
        d="M51.692 139.192C51.9916 139.192 52.2345 138.949 52.2345 138.65C52.2345 138.35 51.9916 138.107 51.692 138.107C51.3923 138.107 51.1494 138.35 51.1494 138.65C51.1494 138.949 51.3923 139.192 51.692 139.192Z"
        data-id="715cfb6f2371"
        fill="white" />
      <path
        d="M51.692 139.192C51.9916 139.192 52.2345 138.949 52.2345 138.65C52.2345 138.35 51.9916 138.107 51.692 138.107C51.3923 138.107 51.1494 138.35 51.1494 138.65C51.1494 138.949 51.3923 139.192 51.692 139.192Z"
        data-id="4e5e81b44f39"
        fill="white" />
      <path
        d="M56.8057 134.077C57.1054 134.077 57.3483 133.834 57.3483 133.535C57.3483 133.235 57.1054 132.992 56.8057 132.992C56.5061 132.992 56.2632 133.235 56.2632 133.535C56.2632 133.834 56.5061 134.077 56.8057 134.077Z"
        data-id="1e5d3908b78a"
        fill="white" />
      <path
        d="M56.8057 134.077C57.1054 134.077 57.3483 133.834 57.3483 133.535C57.3483 133.235 57.1054 132.992 56.8057 132.992C56.5061 132.992 56.2632 133.235 56.2632 133.535C56.2632 133.834 56.5061 134.077 56.8057 134.077Z"
        data-id="084017573f0e"
        fill="white" />
      <path
        d="M61.9215 128.964C62.2211 128.964 62.464 128.721 62.464 128.421C62.464 128.122 62.2211 127.879 61.9215 127.879C61.6218 127.879 61.3789 128.122 61.3789 128.421C61.3789 128.721 61.6218 128.964 61.9215 128.964Z"
        data-id="2967a2737096"
        fill="white" />
      <path
        d="M61.9215 128.964C62.2211 128.964 62.464 128.721 62.464 128.421C62.464 128.122 62.2211 127.879 61.9215 127.879C61.6218 127.879 61.3789 128.122 61.3789 128.421C61.3789 128.721 61.6218 128.964 61.9215 128.964Z"
        data-id="09d2ad367f14"
        fill="white" />
      <path
        d="M67.0425 123.85C67.3422 123.85 67.5851 123.607 67.5851 123.308C67.5851 123.008 67.3422 122.766 67.0425 122.766C66.7429 122.766 66.5 123.008 66.5 123.308C66.5 123.607 66.7429 123.85 67.0425 123.85Z"
        data-id="0b8f47b3e729"
        fill="white" />
      <path
        d="M67.0425 123.85C67.3422 123.85 67.5851 123.607 67.5851 123.308C67.5851 123.008 67.3422 122.766 67.0425 122.766C66.7429 122.766 66.5 123.008 66.5 123.308C66.5 123.607 66.7429 123.85 67.0425 123.85Z"
        data-id="633377b5aca6"
        fill="white" />
      <path
        d="M72.1568 118.729C72.4564 118.729 72.6994 118.486 72.6994 118.187C72.6994 117.887 72.4564 117.645 72.1568 117.645C71.8572 117.645 71.6143 117.887 71.6143 118.187C71.6143 118.486 71.8572 118.729 72.1568 118.729Z"
        data-id="4bf77d3c8dad"
        fill="white" />
      <path
        d="M72.1568 118.729C72.4564 118.729 72.6994 118.486 72.6994 118.187C72.6994 117.887 72.4564 117.645 72.1568 117.645C71.8572 117.645 71.6143 117.887 71.6143 118.187C71.6143 118.486 71.8572 118.729 72.1568 118.729Z"
        data-id="4eb2dc30467d"
        fill="white" />
      <path
        d="M77.6551 113.453C77.4453 113.663 77.0981 113.663 76.8883 113.453C76.6785 113.243 76.6785 112.896 76.8883 112.687C77.0981 112.477 77.4453 112.477 77.6551 112.687C77.8649 112.903 77.8649 113.243 77.6551 113.453Z"
        data-id="b235defca626"
        fill="white" />
      <path
        d="M77.6551 113.453C77.4453 113.663 77.0981 113.663 76.8883 113.453C76.6785 113.243 76.6785 112.896 76.8883 112.687C77.0981 112.477 77.4453 112.477 77.6551 112.687C77.8649 112.903 77.8649 113.243 77.6551 113.453Z"
        data-id="59d6f23d7b3c"
        fill="white" />
      <path
        d="M82.3863 108.499C82.6859 108.499 82.9288 108.256 82.9288 107.956C82.9288 107.657 82.6859 107.414 82.3863 107.414C82.0867 107.414 81.8438 107.657 81.8438 107.956C81.8438 108.256 82.0867 108.499 82.3863 108.499Z"
        data-id="2342925e0f0b"
        fill="white" />
      <path
        d="M82.3863 108.499C82.6859 108.499 82.9288 108.256 82.9288 107.956C82.9288 107.657 82.6859 107.414 82.3863 107.414C82.0867 107.414 81.8438 107.657 81.8438 107.956C81.8438 108.256 82.0867 108.499 82.3863 108.499Z"
        data-id="2c4a953d5634"
        fill="white" />
      <path
        d="M87.4996 103.385C87.7992 103.385 88.0421 103.143 88.0421 102.843C88.0421 102.544 87.7992 102.301 87.4996 102.301C87.1999 102.301 86.957 102.544 86.957 102.843C86.957 103.143 87.1999 103.385 87.4996 103.385Z"
        data-id="0eddec933fa3"
        fill="white" />
      <path
        d="M87.4996 103.385C87.7992 103.385 88.0421 103.143 88.0421 102.843C88.0421 102.544 87.7992 102.301 87.4996 102.301C87.1999 102.301 86.957 102.544 86.957 102.843C86.957 103.143 87.1999 103.385 87.4996 103.385Z"
        data-id="0a69ac5575e0"
        fill="white" />
      <path
        d="M92.9979 98.1112C92.7881 98.3209 92.4409 98.3209 92.2311 98.1112C92.0213 97.9015 92.0213 97.5545 92.2311 97.3448C92.4409 97.1351 92.7881 97.1351 92.9979 97.3448C93.2077 97.5545 93.2077 97.9015 92.9979 98.1112Z"
        data-id="78c52f1fcde1"
        fill="white" />
      <path
        d="M92.9979 98.1112C92.7881 98.3209 92.4409 98.3209 92.2311 98.1112C92.0213 97.9015 92.0213 97.5545 92.2311 97.3448C92.4409 97.1351 92.7881 97.1351 92.9979 97.3448C93.2077 97.5545 93.2077 97.9015 92.9979 98.1112Z"
        data-id="5a95e0536520"
        fill="white" />
      <path
        d="M97.7296 93.153C98.0292 93.153 98.2721 92.9102 98.2721 92.6107C98.2721 92.3112 98.0292 92.0684 97.7296 92.0684C97.4299 92.0684 97.187 92.3112 97.187 92.6107C97.187 92.9102 97.4299 93.153 97.7296 93.153Z"
        data-id="d51974dece0c"
        fill="white" />
      <path
        d="M97.7296 93.153C98.0292 93.153 98.2721 92.9102 98.2721 92.6107C98.2721 92.3112 98.0292 92.0684 97.7296 92.0684C97.4299 92.0684 97.187 92.3112 97.187 92.6107C97.187 92.9102 97.4299 93.153 97.7296 93.153Z"
        data-id="3ef8252b77d3"
        fill="white" />
      <path
        d="M103.227 87.8828C103.017 88.0925 102.67 88.0925 102.46 87.8828C102.25 87.6731 102.25 87.326 102.46 87.1163C102.67 86.9066 103.017 86.9066 103.227 87.1163C103.444 87.326 103.444 87.6731 103.227 87.8828Z"
        data-id="6ea0e73a0fad"
        fill="white" />
      <path
        d="M103.227 87.8828C103.017 88.0925 102.67 88.0925 102.46 87.8828C102.25 87.6731 102.25 87.326 102.46 87.1163C102.67 86.9066 103.017 86.9066 103.227 87.1163C103.444 87.326 103.444 87.6731 103.227 87.8828Z"
        data-id="040974d504e2"
        fill="white" />
      <path
        d="M108.341 82.7694C108.131 82.9791 107.784 82.9791 107.574 82.7694C107.365 82.5597 107.365 82.2127 107.574 82.003C107.784 81.7933 108.131 81.7933 108.341 82.003C108.551 82.2127 108.558 82.5525 108.341 82.7694Z"
        data-id="082e3e55592a"
        fill="white" />
      <path
        d="M108.341 82.7694C108.131 82.9791 107.784 82.9791 107.574 82.7694C107.365 82.5597 107.365 82.2127 107.574 82.003C107.784 81.7933 108.131 81.7933 108.341 82.003C108.551 82.2127 108.558 82.5525 108.341 82.7694Z"
        data-id="7ba16246b462"
        fill="white" />
      <path
        d="M113.463 77.6542C113.253 77.8639 112.906 77.8639 112.696 77.6542C112.486 77.4445 112.486 77.0974 112.696 76.8877C112.906 76.678 113.253 76.678 113.463 76.8877C113.672 77.0974 113.672 77.4373 113.463 77.6542Z"
        data-id="628aa05f6e34"
        fill="white" />
      <path
        d="M113.463 77.6542C113.253 77.8639 112.906 77.8639 112.696 77.6542C112.486 77.4445 112.486 77.0974 112.696 76.8877C112.906 76.678 113.253 76.678 113.463 76.8877C113.672 77.0974 113.672 77.4373 113.463 77.6542Z"
        data-id="355624e5581b"
        fill="white" />
      <path
        d="M118.577 72.539C118.368 72.7487 118.02 72.7487 117.811 72.539C117.601 72.3293 117.601 71.9822 117.811 71.7725C118.02 71.5628 118.368 71.5628 118.577 71.7725C118.787 71.9822 118.787 72.3221 118.577 72.539Z"
        data-id="9a1fc7b4864a"
        fill="white" />
      <path
        d="M118.577 72.539C118.368 72.7487 118.02 72.7487 117.811 72.539C117.601 72.3293 117.601 71.9822 117.811 71.7725C118.02 71.5628 118.368 71.5628 118.577 71.7725C118.787 71.9822 118.787 72.3221 118.577 72.539Z"
        data-id="cb33e0406dd8"
        fill="white" />
      <path
        d="M123.692 67.4179C123.482 67.6276 123.135 67.6276 122.925 67.4179C122.716 67.2082 122.716 66.8611 122.925 66.6514C123.135 66.4417 123.482 66.4417 123.692 66.6514C123.902 66.8611 123.902 67.2082 123.692 67.4179Z"
        data-id="94d34fbe2993"
        fill="white" />
      <path
        d="M123.692 67.4179C123.482 67.6276 123.135 67.6276 122.925 67.4179C122.716 67.2082 122.716 66.8611 122.925 66.6514C123.135 66.4417 123.482 66.4417 123.692 66.6514C123.902 66.8611 123.902 67.2082 123.692 67.4179Z"
        data-id="f5de35580770"
        fill="white" />
      <path
        d="M128.806 62.3046C128.596 62.5143 128.249 62.5143 128.039 62.3046C127.829 62.0949 127.829 61.7478 128.039 61.5381C128.249 61.3284 128.596 61.3284 128.806 61.5381C129.016 61.7478 129.016 62.0949 128.806 62.3046Z"
        data-id="13845029f67b"
        fill="white" />
      <path
        d="M128.806 62.3046C128.596 62.5143 128.249 62.5143 128.039 62.3046C127.829 62.0949 127.829 61.7478 128.039 61.5381C128.249 61.3284 128.596 61.3284 128.806 61.5381C129.016 61.7478 129.016 62.0949 128.806 62.3046Z"
        data-id="ccc36474bd42"
        fill="white" />
      <path
        d="M133.921 57.1913C133.711 57.401 133.364 57.401 133.154 57.1913C132.944 56.9816 132.944 56.6345 133.154 56.4248C133.364 56.2152 133.711 56.2152 133.921 56.4248C134.131 56.6345 134.131 56.9816 133.921 57.1913Z"
        data-id="4a345ffd683c"
        fill="white" />
      <path
        d="M133.921 57.1913C133.711 57.401 133.364 57.401 133.154 57.1913C132.944 56.9816 132.944 56.6345 133.154 56.4248C133.364 56.2152 133.711 56.2152 133.921 56.4248C134.131 56.6345 134.131 56.9816 133.921 57.1913Z"
        data-id="16dbbf230084"
        fill="white" />
      <path
        d="M139.035 52.0761C138.825 52.2858 138.478 52.2858 138.268 52.0761C138.058 51.8664 138.058 51.5193 138.268 51.3096C138.478 51.0999 138.825 51.0999 139.035 51.3096C139.245 51.5193 139.245 51.8664 139.035 52.0761Z"
        data-id="3bd1222e9cf4"
        fill="white" />
      <path
        d="M139.035 52.0761C138.825 52.2858 138.478 52.2858 138.268 52.0761C138.058 51.8664 138.058 51.5193 138.268 51.3096C138.478 51.0999 138.825 51.0999 139.035 51.3096C139.245 51.5193 139.245 51.8664 139.035 52.0761Z"
        data-id="36dfe54a8590"
        fill="white" />
      <path
        d="M46.5782 134.077C46.8778 134.077 47.1207 133.834 47.1207 133.535C47.1207 133.235 46.8778 132.992 46.5782 132.992C46.2786 132.992 46.0356 133.235 46.0356 133.535C46.0356 133.834 46.2786 134.077 46.5782 134.077Z"
        data-id="597e302b491e"
        fill="white" />
      <path
        d="M46.5782 134.077C46.8778 134.077 47.1207 133.834 47.1207 133.535C47.1207 133.235 46.8778 132.992 46.5782 132.992C46.2786 132.992 46.0356 133.235 46.0356 133.535C46.0356 133.834 46.2786 134.077 46.5782 134.077Z"
        data-id="588e59b23718"
        fill="white" />
      <path
        d="M51.692 128.964C51.9916 128.964 52.2345 128.721 52.2345 128.421C52.2345 128.122 51.9916 127.879 51.692 127.879C51.3923 127.879 51.1494 128.122 51.1494 128.421C51.1494 128.721 51.3923 128.964 51.692 128.964Z"
        data-id="a7ad3af46e05"
        fill="white" />
      <path
        d="M51.692 128.964C51.9916 128.964 52.2345 128.721 52.2345 128.421C52.2345 128.122 51.9916 127.879 51.692 127.879C51.3923 127.879 51.1494 128.122 51.1494 128.421C51.1494 128.721 51.3923 128.964 51.692 128.964Z"
        data-id="2c24a1f51c80"
        fill="white" />
      <path
        d="M56.8072 123.848C57.1068 123.848 57.3497 123.605 57.3497 123.306C57.3497 123.006 57.1068 122.764 56.8072 122.764C56.5076 122.764 56.2646 123.006 56.2646 123.306C56.2646 123.605 56.5076 123.848 56.8072 123.848Z"
        data-id="f362f56d2be1"
        fill="white" />
      <path
        d="M56.8072 123.848C57.1068 123.848 57.3497 123.605 57.3497 123.306C57.3497 123.006 57.1068 122.764 56.8072 122.764C56.5076 122.764 56.2646 123.006 56.2646 123.306C56.2646 123.605 56.5076 123.848 56.8072 123.848Z"
        data-id="c1b9d1ec4752"
        fill="white" />
      <path
        d="M61.9219 118.729C62.2216 118.729 62.4645 118.486 62.4645 118.187C62.4645 117.887 62.2216 117.645 61.9219 117.645C61.6223 117.645 61.3794 117.887 61.3794 118.187C61.3794 118.486 61.6223 118.729 61.9219 118.729Z"
        data-id="26b8b54d4ffa"
        fill="white" />
      <path
        d="M61.9219 118.729C62.2216 118.729 62.4645 118.486 62.4645 118.187C62.4645 117.887 62.2216 117.645 61.9219 117.645C61.6223 117.645 61.3794 117.887 61.3794 118.187C61.3794 118.486 61.6223 118.729 61.9219 118.729Z"
        data-id="591e77df5a2e"
        fill="white" />
      <path
        d="M67.4266 113.455C67.2168 113.665 66.8696 113.665 66.6598 113.455C66.45 113.245 66.45 112.898 66.6598 112.689C66.8696 112.479 67.2168 112.479 67.4266 112.689C67.6364 112.905 67.6364 113.245 67.4266 113.455Z"
        data-id="3585fa0ae1c8"
        fill="white" />
      <path
        d="M67.4266 113.455C67.2168 113.665 66.8696 113.665 66.6598 113.455C66.45 113.245 66.45 112.898 66.6598 112.689C66.8696 112.479 67.2168 112.479 67.4266 112.689C67.6364 112.905 67.6364 113.245 67.4266 113.455Z"
        data-id="a7041c6a0e15"
        fill="white" />
      <path
        d="M72.1573 108.499C72.4569 108.499 72.6998 108.256 72.6998 107.956C72.6998 107.657 72.4569 107.414 72.1573 107.414C71.8577 107.414 71.6147 107.657 71.6147 107.956C71.6147 108.256 71.8577 108.499 72.1573 108.499Z"
        data-id="a3e5f2d2f0a3"
        fill="white" />
      <path
        d="M72.1573 108.499C72.4569 108.499 72.6998 108.256 72.6998 107.956C72.6998 107.657 72.4569 107.414 72.1573 107.414C71.8577 107.414 71.6147 107.657 71.6147 107.956C71.6147 108.256 71.8577 108.499 72.1573 108.499Z"
        data-id="344dc2c77130"
        fill="white" />
      <path
        d="M77.272 103.385C77.5717 103.385 77.8146 103.143 77.8146 102.843C77.8146 102.544 77.5717 102.301 77.272 102.301C76.9724 102.301 76.7295 102.544 76.7295 102.843C76.7295 103.143 76.9724 103.385 77.272 103.385Z"
        data-id="130bc87226f7"
        fill="white" />
      <path
        d="M77.272 103.385C77.5717 103.385 77.8146 103.143 77.8146 102.843C77.8146 102.544 77.5717 102.301 77.272 102.301C76.9724 102.301 76.7295 102.544 76.7295 102.843C76.7295 103.143 76.9724 103.385 77.272 103.385Z"
        data-id="ac82ca6dc76a"
        fill="white" />
      <path
        d="M82.7694 98.1112C82.5596 98.3209 82.2123 98.3209 82.0026 98.1112C81.7928 97.9015 81.7928 97.5545 82.0026 97.3448C82.2123 97.1351 82.5596 97.1351 82.7694 97.3448C82.9791 97.5545 82.9791 97.9015 82.7694 98.1112Z"
        data-id="81b8fc5ff739"
        fill="white" />
      <path
        d="M82.7694 98.1112C82.5596 98.3209 82.2123 98.3209 82.0026 98.1112C81.7928 97.9015 81.7928 97.5545 82.0026 97.3448C82.2123 97.1351 82.5596 97.1351 82.7694 97.3448C82.9791 97.5545 82.9791 97.9015 82.7694 98.1112Z"
        data-id="5a2b5b166dce"
        fill="white" />
      <path
        d="M87.501 93.1549C87.8007 93.1549 88.0436 92.9121 88.0436 92.6126C88.0436 92.3131 87.8007 92.0703 87.501 92.0703C87.2014 92.0703 86.9585 92.3131 86.9585 92.6126C86.9585 92.9121 87.2014 93.1549 87.501 93.1549Z"
        data-id="7fe4f716efd9"
        fill="white" />
      <path
        d="M87.501 93.1549C87.8007 93.1549 88.0436 92.9121 88.0436 92.6126C88.0436 92.3131 87.8007 92.0703 87.501 92.0703C87.2014 92.0703 86.9585 92.3131 86.9585 92.6126C86.9585 92.9121 87.2014 93.1549 87.501 93.1549Z"
        data-id="f26d3feeed98"
        fill="white" />
      <path
        d="M92.9984 87.8828C92.7886 88.0925 92.4413 88.0925 92.2316 87.8828C92.0218 87.6731 92.0218 87.326 92.2316 87.1163C92.4413 86.9066 92.7886 86.9066 92.9984 87.1163C93.2081 87.326 93.2081 87.6731 92.9984 87.8828Z"
        data-id="7464a1a773c1"
        fill="white" />
      <path
        d="M92.9984 87.8828C92.7886 88.0925 92.4413 88.0925 92.2316 87.8828C92.0218 87.6731 92.0218 87.326 92.2316 87.1163C92.4413 86.9066 92.7886 86.9066 92.9984 87.1163C93.2081 87.326 93.2081 87.6731 92.9984 87.8828Z"
        data-id="1707a4dd6823"
        fill="white" />
      <path
        d="M98.1131 82.7675C97.9033 82.9772 97.5561 82.9772 97.3463 82.7675C97.1365 82.5578 97.1365 82.2107 97.3463 82.001C97.5561 81.7913 97.9033 81.7913 98.1131 82.001C98.3229 82.2107 98.3229 82.5506 98.1131 82.7675Z"
        data-id="167a19479013"
        fill="white" />
      <path
        d="M98.1131 82.7675C97.9033 82.9772 97.5561 82.9772 97.3463 82.7675C97.1365 82.5578 97.1365 82.2107 97.3463 82.001C97.5561 81.7913 97.9033 81.7913 98.1131 82.001C98.3229 82.2107 98.3229 82.5506 98.1131 82.7675Z"
        data-id="9f171f5e60f1"
        fill="white" />
      <path
        d="M102.844 77.8131C103.143 77.8131 103.386 77.5703 103.386 77.2708C103.386 76.9713 103.143 76.7285 102.844 76.7285C102.544 76.7285 102.301 76.9713 102.301 77.2708C102.301 77.5703 102.544 77.8131 102.844 77.8131Z"
        data-id="b49a1cf032b6"
        fill="white" />
      <path
        d="M102.844 77.8131C103.143 77.8131 103.386 77.5703 103.386 77.2708C103.386 76.9713 103.143 76.7285 102.844 76.7285C102.544 76.7285 102.301 76.9713 102.301 77.2708C102.301 77.5703 102.544 77.8131 102.844 77.8131Z"
        data-id="c27badb3879a"
        fill="white" />
      <path
        d="M108.342 72.5409C108.132 72.7506 107.785 72.7506 107.575 72.5409C107.366 72.3312 107.366 71.9842 107.575 71.7745C107.785 71.5648 108.132 71.5648 108.342 71.7745C108.559 71.9842 108.559 72.324 108.342 72.5409Z"
        data-id="499e22209ec1"
        fill="white" />
      <path
        d="M108.342 72.5409C108.132 72.7506 107.785 72.7506 107.575 72.5409C107.366 72.3312 107.366 71.9842 107.575 71.7745C107.785 71.5648 108.132 71.5648 108.342 71.7745C108.559 71.9842 108.559 72.324 108.342 72.5409Z"
        data-id="de472af9e13e"
        fill="white" />
      <path
        d="M113.464 67.4179C113.254 67.6276 112.907 67.6276 112.697 67.4179C112.487 67.2082 112.487 66.8611 112.697 66.6514C112.907 66.4417 113.254 66.4417 113.464 66.6514C113.673 66.8611 113.673 67.2082 113.464 67.4179Z"
        data-id="545f04eafe29"
        fill="white" />
      <path
        d="M113.464 67.4179C113.254 67.6276 112.907 67.6276 112.697 67.4179C112.487 67.2082 112.487 66.8611 112.697 66.6514C112.907 66.4417 113.254 66.4417 113.464 66.6514C113.673 66.8611 113.673 67.2082 113.464 67.4179Z"
        data-id="c0a3553e6123"
        fill="white" />
      <path
        d="M118.194 62.4655C118.494 62.4655 118.737 62.2227 118.737 61.9232C118.737 61.6237 118.494 61.3809 118.194 61.3809C117.895 61.3809 117.652 61.6237 117.652 61.9232C117.652 62.2227 117.895 62.4655 118.194 62.4655Z"
        data-id="858f4ea4f1ad"
        fill="white" />
      <path
        d="M118.194 62.4655C118.494 62.4655 118.737 62.2227 118.737 61.9232C118.737 61.6237 118.494 61.3809 118.194 61.3809C117.895 61.3809 117.652 61.6237 117.652 61.9232C117.652 62.2227 117.895 62.4655 118.194 62.4655Z"
        data-id="386928ae932c"
        fill="white" />
      <path
        d="M123.693 57.1913C123.483 57.401 123.136 57.401 122.926 57.1913C122.716 56.9816 122.716 56.6345 122.926 56.4248C123.136 56.2152 123.483 56.2152 123.693 56.4248C123.902 56.6345 123.902 56.9816 123.693 57.1913Z"
        data-id="60d85e4ec46d"
        fill="white" />
      <path
        d="M123.693 57.1913C123.483 57.401 123.136 57.401 122.926 57.1913C122.716 56.9816 122.716 56.6345 122.926 56.4248C123.136 56.2152 123.483 56.2152 123.693 56.4248C123.902 56.6345 123.902 56.9816 123.693 57.1913Z"
        data-id="c570fec1ed4c"
        fill="white" />
      <path
        d="M128.807 52.0781C128.597 52.2878 128.25 52.2878 128.04 52.0781C127.83 51.8684 127.83 51.5213 128.04 51.3116C128.25 51.1019 128.597 51.1019 128.807 51.3116C129.017 51.5213 129.017 51.8684 128.807 52.0781Z"
        data-id="11ac595ba381"
        fill="white" />
      <path
        d="M128.807 52.0781C128.597 52.2878 128.25 52.2878 128.04 52.0781C127.83 51.8684 127.83 51.5213 128.04 51.3116C128.25 51.1019 128.597 51.1019 128.807 51.3116C129.017 51.5213 129.017 51.8684 128.807 52.0781Z"
        data-id="2bc2b89ab1cc"
        fill="white" />
      <path
        d="M133.538 47.1198C133.837 47.1198 134.08 46.877 134.08 46.5775C134.08 46.278 133.837 46.0352 133.538 46.0352C133.238 46.0352 132.995 46.278 132.995 46.5775C132.995 46.877 133.238 47.1198 133.538 47.1198Z"
        data-id="c014608b8661"
        fill="white" />
      <path
        d="M133.538 47.1198C133.837 47.1198 134.08 46.877 134.08 46.5775C134.08 46.278 133.837 46.0352 133.538 46.0352C133.238 46.0352 132.995 46.278 132.995 46.5775C132.995 46.877 133.238 47.1198 133.538 47.1198Z"
        data-id="21dec618a8ca"
        fill="white" />
      <path
        d="M41.8465 128.805C41.6367 129.014 41.2895 129.014 41.0797 128.805C40.8699 128.595 40.8699 128.248 41.0797 128.038C41.2895 127.828 41.6367 127.828 41.8465 128.038C42.0563 128.248 42.0563 128.588 41.8465 128.805Z"
        data-id="033c2805225a"
        fill="white" />
      <path
        d="M41.8465 128.805C41.6367 129.014 41.2895 129.014 41.0797 128.805C40.8699 128.595 40.8699 128.248 41.0797 128.038C41.2895 127.828 41.6367 127.828 41.8465 128.038C42.0563 128.248 42.0563 128.588 41.8465 128.805Z"
        data-id="8a06010e5942"
        fill="white" />
      <path
        d="M46.5777 123.85C46.8773 123.85 47.1203 123.607 47.1203 123.308C47.1203 123.008 46.8773 122.766 46.5777 122.766C46.2781 122.766 46.0352 123.008 46.0352 123.308C46.0352 123.607 46.2781 123.85 46.5777 123.85Z"
        data-id="22e96a6e20ad"
        fill="white" />
      <path
        d="M46.5777 123.85C46.8773 123.85 47.1203 123.607 47.1203 123.308C47.1203 123.008 46.8773 122.766 46.5777 122.766C46.2781 122.766 46.0352 123.008 46.0352 123.308C46.0352 123.607 46.2781 123.85 46.5777 123.85Z"
        data-id="1d5847e52c0d"
        fill="white" />
      <path
        d="M51.692 118.729C51.9916 118.729 52.2345 118.486 52.2345 118.187C52.2345 117.887 51.9916 117.645 51.692 117.645C51.3923 117.645 51.1494 117.887 51.1494 118.187C51.1494 118.486 51.3923 118.729 51.692 118.729Z"
        data-id="35bea8b6c0b3"
        fill="white" />
      <path
        d="M51.692 118.729C51.9916 118.729 52.2345 118.486 52.2345 118.187C52.2345 117.887 51.9916 117.645 51.692 117.645C51.3923 117.645 51.1494 117.887 51.1494 118.187C51.1494 118.486 51.3923 118.729 51.692 118.729Z"
        data-id="d68f3974ba3b"
        fill="white" />
      <path
        d="M57.1903 113.453C56.9805 113.663 56.6332 113.663 56.4235 113.453C56.2137 113.243 56.2137 112.896 56.4235 112.687C56.6332 112.477 56.9805 112.477 57.1903 112.687C57.4 112.896 57.4073 113.243 57.1903 113.453Z"
        data-id="11138dc8ffea"
        fill="white" />
      <path
        d="M57.1903 113.453C56.9805 113.663 56.6332 113.663 56.4235 113.453C56.2137 113.243 56.2137 112.896 56.4235 112.687C56.6332 112.477 56.9805 112.477 57.1903 112.687C57.4 112.896 57.4073 113.243 57.1903 113.453Z"
        data-id="ce15960d2e4d"
        fill="white" />
      <path
        d="M61.921 108.501C62.2206 108.501 62.4635 108.258 62.4635 107.958C62.4635 107.659 62.2206 107.416 61.921 107.416C61.6213 107.416 61.3784 107.659 61.3784 107.958C61.3784 108.258 61.6213 108.501 61.921 108.501Z"
        data-id="a4e64c1b11e5"
        fill="white" />
      <path
        d="M61.921 108.501C62.2206 108.501 62.4635 108.258 62.4635 107.958C62.4635 107.659 62.2206 107.416 61.921 107.416C61.6213 107.416 61.3784 107.659 61.3784 107.958C61.3784 108.258 61.6213 108.501 61.921 108.501Z"
        data-id="c1e166a7caf8"
        fill="white" />
      <path
        d="M67.0425 103.385C67.3422 103.385 67.5851 103.143 67.5851 102.843C67.5851 102.544 67.3422 102.301 67.0425 102.301C66.7429 102.301 66.5 102.544 66.5 102.843C66.5 103.143 66.7429 103.385 67.0425 103.385Z"
        data-id="42ba8a197bfb"
        fill="white" />
      <path
        d="M67.0425 103.385C67.3422 103.385 67.5851 103.143 67.5851 102.843C67.5851 102.544 67.3422 102.301 67.0425 102.301C66.7429 102.301 66.5 102.544 66.5 102.843C66.5 103.143 66.7429 103.385 67.0425 103.385Z"
        data-id="eca09ac06ccb"
        fill="white" />
      <path
        d="M72.5403 98.1132C72.3306 98.3229 71.9833 98.3229 71.7735 98.1132C71.5638 97.9035 71.5638 97.5564 71.7735 97.3467C71.9833 97.137 72.3306 97.137 72.5403 97.3467C72.7501 97.5564 72.7501 97.9035 72.5403 98.1132Z"
        data-id="0d71046e0f44"
        fill="white" />
      <path
        d="M72.5403 98.1132C72.3306 98.3229 71.9833 98.3229 71.7735 98.1132C71.5638 97.9035 71.5638 97.5564 71.7735 97.3467C71.9833 97.137 72.3306 97.137 72.5403 97.3467C72.7501 97.5564 72.7501 97.9035 72.5403 98.1132Z"
        data-id="1d33a6c41ce2"
        fill="white" />
      <path
        d="M77.2716 93.1549C77.5712 93.1549 77.8141 92.9121 77.8141 92.6126C77.8141 92.3131 77.5712 92.0703 77.2716 92.0703C76.9719 92.0703 76.729 92.3131 76.729 92.6126C76.729 92.9121 76.9719 93.1549 77.2716 93.1549Z"
        data-id="cd4e55991d7b"
        fill="white" />
      <path
        d="M77.2716 93.1549C77.5712 93.1549 77.8141 92.9121 77.8141 92.6126C77.8141 92.3131 77.5712 92.0703 77.2716 92.0703C76.9719 92.0703 76.729 92.3131 76.729 92.6126C76.729 92.9121 76.9719 93.1549 77.2716 93.1549Z"
        data-id="293ce3221bb2"
        fill="white" />
      <path
        d="M82.7694 87.8828C82.5596 88.0925 82.2123 88.0925 82.0026 87.8828C81.7928 87.6731 81.7928 87.326 82.0026 87.1163C82.2123 86.9066 82.5596 86.9066 82.7694 87.1163C82.9791 87.326 82.9791 87.6731 82.7694 87.8828Z"
        data-id="63a33ef872e1"
        fill="white" />
      <path
        d="M82.7694 87.8828C82.5596 88.0925 82.2123 88.0925 82.0026 87.8828C81.7928 87.6731 81.7928 87.326 82.0026 87.1163C82.2123 86.9066 82.5596 86.9066 82.7694 87.1163C82.9791 87.326 82.9791 87.6731 82.7694 87.8828Z"
        data-id="b453337dd498"
        fill="white" />
      <path
        d="M87.8831 82.7694C87.6733 82.9791 87.3261 82.9791 87.1163 82.7694C86.9065 82.5597 86.9065 82.2127 87.1163 82.003C87.3261 81.7933 87.6733 81.7933 87.8831 82.003C88.0929 82.2127 88.0929 82.5525 87.8831 82.7694Z"
        data-id="8ac629eebbe7"
        fill="white" />
      <path
        d="M87.8831 82.7694C87.6733 82.9791 87.3261 82.9791 87.1163 82.7694C86.9065 82.5597 86.9065 82.2127 87.1163 82.003C87.3261 81.7933 87.6733 81.7933 87.8831 82.003C88.0929 82.2127 88.0929 82.5525 87.8831 82.7694Z"
        data-id="5cd6f1fbe2f7"
        fill="white" />
      <path
        d="M92.6148 77.8131C92.9145 77.8131 93.1574 77.5703 93.1574 77.2708C93.1574 76.9713 92.9145 76.7285 92.6148 76.7285C92.3152 76.7285 92.0723 76.9713 92.0723 77.2708C92.0723 77.5703 92.3152 77.8131 92.6148 77.8131Z"
        data-id="c731796038b9"
        fill="white" />
      <path
        d="M92.6148 77.8131C92.9145 77.8131 93.1574 77.5703 93.1574 77.2708C93.1574 76.9713 92.9145 76.7285 92.6148 76.7285C92.3152 76.7285 92.0723 76.9713 92.0723 77.2708C92.0723 77.5703 92.3152 77.8131 92.6148 77.8131Z"
        data-id="c5c7470d0d20"
        fill="white" />
      <path
        d="M98.1126 72.5409C97.9028 72.7506 97.5556 72.7506 97.3458 72.5409C97.136 72.3312 97.136 71.9842 97.3458 71.7745C97.5556 71.5648 97.9028 71.5648 98.1126 71.7745C98.3224 71.9842 98.3224 72.324 98.1126 72.5409Z"
        data-id="caa2c0a02165"
        fill="white" />
      <path
        d="M98.1126 72.5409C97.9028 72.7506 97.5556 72.7506 97.3458 72.5409C97.136 72.3312 97.136 71.9842 97.3458 71.7745C97.5556 71.5648 97.9028 71.5648 98.1126 71.7745C98.3224 71.9842 98.3224 72.324 98.1126 72.5409Z"
        data-id="10cb3cca9679"
        fill="white" />
      <path
        d="M103.227 67.4179C103.017 67.6276 102.67 67.6276 102.46 67.4179C102.25 67.2082 102.25 66.8611 102.46 66.6514C102.67 66.4417 103.017 66.4417 103.227 66.6514C103.444 66.8683 103.444 67.2082 103.227 67.4179Z"
        data-id="7bfeff379c1e"
        fill="white" />
      <path
        d="M103.227 67.4179C103.017 67.6276 102.67 67.6276 102.46 67.4179C102.25 67.2082 102.25 66.8611 102.46 66.6514C102.67 66.4417 103.017 66.4417 103.227 66.6514C103.444 66.8683 103.444 67.2082 103.227 67.4179Z"
        data-id="e6cd6c835f5b"
        fill="white" />
      <path
        d="M107.959 62.4655C108.258 62.4655 108.501 62.2227 108.501 61.9232C108.501 61.6237 108.258 61.3809 107.959 61.3809C107.659 61.3809 107.416 61.6237 107.416 61.9232C107.416 62.2227 107.659 62.4655 107.959 62.4655Z"
        data-id="fd04cd7128f5"
        fill="white" />
      <path
        d="M107.959 62.4655C108.258 62.4655 108.501 62.2227 108.501 61.9232C108.501 61.6237 108.258 61.3809 107.959 61.3809C107.659 61.3809 107.416 61.6237 107.416 61.9232C107.416 62.2227 107.659 62.4655 107.959 62.4655Z"
        data-id="6182506d50dd"
        fill="white" />
      <path
        d="M113.463 57.1913C113.253 57.401 112.906 57.401 112.696 57.1913C112.487 56.9816 112.487 56.6345 112.696 56.4248C112.906 56.2152 113.253 56.2152 113.463 56.4248C113.673 56.6345 113.673 56.9816 113.463 57.1913Z"
        data-id="420d819f63dc"
        fill="white" />
      <path
        d="M113.463 57.1913C113.253 57.401 112.906 57.401 112.696 57.1913C112.487 56.9816 112.487 56.6345 112.696 56.4248C112.906 56.2152 113.253 56.2152 113.463 56.4248C113.673 56.6345 113.673 56.9816 113.463 57.1913Z"
        data-id="49e498c7b9d9"
        fill="white" />
      <path
        d="M118.194 52.235C118.494 52.235 118.736 51.9922 118.736 51.6927C118.736 51.3932 118.494 51.1504 118.194 51.1504C117.894 51.1504 117.651 51.3932 117.651 51.6927C117.651 51.9922 117.894 52.235 118.194 52.235Z"
        data-id="70edd27e3aa2"
        fill="white" />
      <path
        d="M118.194 52.235C118.494 52.235 118.736 51.9922 118.736 51.6927C118.736 51.3932 118.494 51.1504 118.194 51.1504C117.894 51.1504 117.651 51.3932 117.651 51.6927C117.651 51.9922 117.894 52.235 118.194 52.235Z"
        data-id="065fda87c257"
        fill="white" />
      <path
        d="M123.309 47.1198C123.608 47.1198 123.851 46.877 123.851 46.5775C123.851 46.278 123.608 46.0352 123.309 46.0352C123.009 46.0352 122.766 46.278 122.766 46.5775C122.766 46.877 123.009 47.1198 123.309 47.1198Z"
        data-id="4dc7588baf89"
        fill="white" />
      <path
        d="M123.309 47.1198C123.608 47.1198 123.851 46.877 123.851 46.5775C123.851 46.278 123.608 46.0352 123.309 46.0352C123.009 46.0352 122.766 46.278 122.766 46.5775C122.766 46.877 123.009 47.1198 123.309 47.1198Z"
        data-id="16bb9cf46e76"
        fill="white" />
      <path
        d="M128.806 41.8476C128.596 42.0573 128.249 42.0573 128.039 41.8476C127.829 41.6379 127.829 41.2908 128.039 41.0811C128.249 40.8714 128.596 40.8714 128.806 41.0811C129.016 41.2908 129.016 41.6379 128.806 41.8476Z"
        data-id="5bdb09f5f459"
        fill="white" />
      <path
        d="M128.806 41.8476C128.596 42.0573 128.249 42.0573 128.039 41.8476C127.829 41.6379 127.829 41.2908 128.039 41.0811C128.249 40.8714 128.596 40.8714 128.806 41.0811C129.016 41.2908 129.016 41.6379 128.806 41.8476Z"
        data-id="4efa7d5dd1a7"
        fill="white" />
      <path
        d="M36.3497 123.85C36.6493 123.85 36.8922 123.607 36.8922 123.308C36.8922 123.008 36.6493 122.766 36.3497 122.766C36.05 122.766 35.8071 123.008 35.8071 123.308C35.8071 123.607 36.05 123.85 36.3497 123.85Z"
        data-id="7994fd773a5f"
        fill="white" />
      <path
        d="M36.3497 123.85C36.6493 123.85 36.8922 123.607 36.8922 123.308C36.8922 123.008 36.6493 122.766 36.3497 122.766C36.05 122.766 35.8071 123.008 35.8071 123.308C35.8071 123.607 36.05 123.85 36.3497 123.85Z"
        data-id="47d6b7532a9c"
        fill="white" />
      <path
        d="M41.8475 118.57C41.6377 118.78 41.2905 118.78 41.0807 118.57C40.8709 118.361 40.8709 118.013 41.0807 117.804C41.2905 117.594 41.6377 117.594 41.8475 117.804C42.0573 118.021 42.0573 118.361 41.8475 118.57Z"
        data-id="ffb37c961206"
        fill="white" />
      <path
        d="M41.8475 118.57C41.6377 118.78 41.2905 118.78 41.0807 118.57C40.8709 118.361 40.8709 118.013 41.0807 117.804C41.2905 117.594 41.6377 117.594 41.8475 117.804C42.0573 118.021 42.0573 118.361 41.8475 118.57Z"
        data-id="3b5f587f1776"
        fill="white" />
      <path
        d="M46.9617 113.455C46.752 113.665 46.4047 113.665 46.1949 113.455C45.9852 113.245 45.9852 112.898 46.1949 112.689C46.4047 112.479 46.752 112.479 46.9617 112.689C47.1715 112.905 47.1715 113.245 46.9617 113.455Z"
        data-id="14ee001c72a0"
        fill="white" />
      <path
        d="M46.9617 113.455C46.752 113.665 46.4047 113.665 46.1949 113.455C45.9852 113.245 45.9852 112.898 46.1949 112.689C46.4047 112.479 46.752 112.479 46.9617 112.689C47.1715 112.905 47.1715 113.245 46.9617 113.455Z"
        data-id="84c6bbf60f88"
        fill="white" />
      <path
        d="M51.6929 108.501C51.9926 108.501 52.2355 108.258 52.2355 107.958C52.2355 107.659 51.9926 107.416 51.6929 107.416C51.3933 107.416 51.1504 107.659 51.1504 107.958C51.1504 108.258 51.3933 108.501 51.6929 108.501Z"
        data-id="4b3bd00ff601"
        fill="white" />
      <path
        d="M51.6929 108.501C51.9926 108.501 52.2355 108.258 52.2355 107.958C52.2355 107.659 51.9926 107.416 51.6929 107.416C51.3933 107.416 51.1504 107.659 51.1504 107.958C51.1504 108.258 51.3933 108.501 51.6929 108.501Z"
        data-id="cc5dfb29323b"
        fill="white" />
      <path
        d="M56.8072 103.385C57.1068 103.385 57.3497 103.143 57.3497 102.843C57.3497 102.544 57.1068 102.301 56.8072 102.301C56.5076 102.301 56.2646 102.544 56.2646 102.843C56.2646 103.143 56.5076 103.385 56.8072 103.385Z"
        data-id="fd5404fd7d1d"
        fill="white" />
      <path
        d="M56.8072 103.385C57.1068 103.385 57.3497 103.143 57.3497 102.843C57.3497 102.544 57.1068 102.301 56.8072 102.301C56.5076 102.301 56.2646 102.544 56.2646 102.843C56.2646 103.143 56.5076 103.385 56.8072 103.385Z"
        data-id="c82eef025c72"
        fill="white" />
      <path
        d="M62.3055 98.1132C62.0957 98.3229 61.7485 98.3229 61.5387 98.1132C61.3289 97.9035 61.3289 97.5564 61.5387 97.3467C61.7485 97.137 62.0957 97.137 62.3055 97.3467C62.5225 97.5564 62.5225 97.9035 62.3055 98.1132Z"
        data-id="4341688f5831"
        fill="white" />
      <path
        d="M62.3055 98.1132C62.0957 98.3229 61.7485 98.3229 61.5387 98.1132C61.3289 97.9035 61.3289 97.5564 61.5387 97.3467C61.7485 97.137 62.0957 97.137 62.3055 97.3467C62.5225 97.5564 62.5225 97.9035 62.3055 98.1132Z"
        data-id="de13caa58afb"
        fill="white" />
      <path
        d="M67.044 93.1569C67.3437 93.1569 67.5866 92.9141 67.5866 92.6146C67.5866 92.3151 67.3437 92.0723 67.044 92.0723C66.7444 92.0723 66.5015 92.3151 66.5015 92.6146C66.5015 92.9141 66.7444 93.1569 67.044 93.1569Z"
        data-id="4485cfdc790a"
        fill="white" />
      <path
        d="M67.044 93.1569C67.3437 93.1569 67.5866 92.9141 67.5866 92.6146C67.5866 92.3151 67.3437 92.0723 67.044 92.0723C66.7444 92.0723 66.5015 92.3151 66.5015 92.6146C66.5015 92.9141 66.7444 93.1569 67.044 93.1569Z"
        data-id="b074d3308b17"
        fill="white" />
      <path
        d="M72.5408 87.8828C72.3311 88.0925 71.9838 88.0925 71.774 87.8828C71.5643 87.6731 71.5643 87.326 71.774 87.1163C71.9838 86.9066 72.3311 86.9066 72.5408 87.1163C72.7506 87.326 72.7506 87.6731 72.5408 87.8828Z"
        data-id="03f9aeb3544a"
        fill="white" />
      <path
        d="M72.5408 87.8828C72.3311 88.0925 71.9838 88.0925 71.774 87.8828C71.5643 87.6731 71.5643 87.326 71.774 87.1163C71.9838 86.9066 72.3311 86.9066 72.5408 87.1163C72.7506 87.326 72.7506 87.6731 72.5408 87.8828Z"
        data-id="ac9abb467777"
        fill="white" />
      <path
        d="M77.6551 82.7694C77.4453 82.9791 77.0981 82.9791 76.8883 82.7694C76.6785 82.5597 76.6785 82.2127 76.8883 82.003C77.0981 81.7933 77.4453 81.7933 77.6551 82.003C77.8649 82.2127 77.8649 82.5525 77.6551 82.7694Z"
        data-id="6ecffbb2f87f"
        fill="white" />
      <path
        d="M77.6551 82.7694C77.4453 82.9791 77.0981 82.9791 76.8883 82.7694C76.6785 82.5597 76.6785 82.2127 76.8883 82.003C77.0981 81.7933 77.4453 81.7933 77.6551 82.003C77.8649 82.2127 77.8649 82.5525 77.6551 82.7694Z"
        data-id="85987e8a0a66"
        fill="white" />
      <path
        d="M82.3873 77.8131C82.6869 77.8131 82.9298 77.5703 82.9298 77.2708C82.9298 76.9713 82.6869 76.7285 82.3873 76.7285C82.0876 76.7285 81.8447 76.9713 81.8447 77.2708C81.8447 77.5703 82.0876 77.8131 82.3873 77.8131Z"
        data-id="801c0cfb00ea"
        fill="white" />
      <path
        d="M82.3873 77.8131C82.6869 77.8131 82.9298 77.5703 82.9298 77.2708C82.9298 76.9713 82.6869 76.7285 82.3873 76.7285C82.0876 76.7285 81.8447 76.9713 81.8447 77.2708C81.8447 77.5703 82.0876 77.8131 82.3873 77.8131Z"
        data-id="d609fa11a242"
        fill="white" />
      <path
        d="M87.8841 72.5429C87.6743 72.7526 87.3271 72.7526 87.1173 72.5429C86.9075 72.3332 86.9075 71.9861 87.1173 71.7764C87.3271 71.5667 87.6743 71.5667 87.8841 71.7764C88.0939 71.9861 88.0939 72.326 87.8841 72.5429Z"
        data-id="461ad8b02f23"
        fill="white" />
      <path
        d="M87.8841 72.5429C87.6743 72.7526 87.3271 72.7526 87.1173 72.5429C86.9075 72.3332 86.9075 71.9861 87.1173 71.7764C87.3271 71.5667 87.6743 71.5667 87.8841 71.7764C88.0939 71.9861 88.0939 72.326 87.8841 72.5429Z"
        data-id="459523e0f0cb"
        fill="white" />
      <path
        d="M92.9984 67.4198C92.7886 67.6295 92.4413 67.6295 92.2316 67.4198C92.0218 67.2101 92.0218 66.8631 92.2316 66.6534C92.4413 66.4437 92.7886 66.4437 92.9984 66.6534C93.2081 66.8631 93.2081 67.2101 92.9984 67.4198Z"
        data-id="feb3ba5a6a2c"
        fill="white" />
      <path
        d="M92.9984 67.4198C92.7886 67.6295 92.4413 67.6295 92.2316 67.4198C92.0218 67.2101 92.0218 66.8631 92.2316 66.6534C92.4413 66.4437 92.7886 66.4437 92.9984 66.6534C93.2081 66.8631 93.2081 67.2101 92.9984 67.4198Z"
        data-id="1f3a3a5243e2"
        fill="white" />
      <path
        d="M97.7296 62.4655C98.0292 62.4655 98.2721 62.2227 98.2721 61.9232C98.2721 61.6237 98.0292 61.3809 97.7296 61.3809C97.4299 61.3809 97.187 61.6237 97.187 61.9232C97.187 62.2227 97.4299 62.4655 97.7296 62.4655Z"
        data-id="61ed82ee073c"
        fill="white" />
      <path
        d="M97.7296 62.4655C98.0292 62.4655 98.2721 62.2227 98.2721 61.9232C98.2721 61.6237 98.0292 61.3809 97.7296 61.3809C97.4299 61.3809 97.187 61.6237 97.187 61.9232C97.187 62.2227 97.4299 62.4655 97.7296 62.4655Z"
        data-id="47fe182fd955"
        fill="white" />
      <path
        d="M103.228 57.1913C103.018 57.401 102.671 57.401 102.461 57.1913C102.251 56.9816 102.251 56.6345 102.461 56.4248C102.671 56.2152 103.018 56.2152 103.228 56.4248C103.445 56.6345 103.445 56.9816 103.228 57.1913Z"
        data-id="2d6a639e4298"
        fill="white" />
      <path
        d="M103.228 57.1913C103.018 57.401 102.671 57.401 102.461 57.1913C102.251 56.9816 102.251 56.6345 102.461 56.4248C102.671 56.2152 103.018 56.2152 103.228 56.4248C103.445 56.6345 103.445 56.9816 103.228 57.1913Z"
        data-id="f395ff2d6dea"
        fill="white" />
      <path
        d="M107.959 52.235C108.259 52.235 108.502 51.9922 108.502 51.6927C108.502 51.3932 108.259 51.1504 107.959 51.1504C107.659 51.1504 107.417 51.3932 107.417 51.6927C107.417 51.9922 107.659 52.235 107.959 52.235Z"
        data-id="f4d1273ba4c1"
        fill="white" />
      <path
        d="M107.959 52.235C108.259 52.235 108.502 51.9922 108.502 51.6927C108.502 51.3932 108.259 51.1504 107.959 51.1504C107.659 51.1504 107.417 51.3932 107.417 51.6927C107.417 51.9922 107.659 52.235 107.959 52.235Z"
        data-id="e2f2655c8c12"
        fill="white" />
      <path
        d="M113.464 46.9628C113.254 47.1725 112.907 47.1725 112.697 46.9628C112.487 46.7531 112.487 46.406 112.697 46.1963C112.907 45.9866 113.254 45.9866 113.464 46.1963C113.673 46.406 113.673 46.7531 113.464 46.9628Z"
        data-id="444434cec746"
        fill="white" />
      <path
        d="M113.464 46.9628C113.254 47.1725 112.907 47.1725 112.697 46.9628C112.487 46.7531 112.487 46.406 112.697 46.1963C112.907 45.9866 113.254 45.9866 113.464 46.1963C113.673 46.406 113.673 46.7531 113.464 46.9628Z"
        data-id="1cd76d9bb7c1"
        fill="white" />
      <path
        d="M118.578 41.8495C118.368 42.0592 118.021 42.0592 117.811 41.8495C117.601 41.6398 117.601 41.2927 117.811 41.0831C118.021 40.8734 118.368 40.8734 118.578 41.0831C118.788 41.2927 118.788 41.6398 118.578 41.8495Z"
        data-id="c6dd863caafe"
        fill="white" />
      <path
        d="M118.578 41.8495C118.368 42.0592 118.021 42.0592 117.811 41.8495C117.601 41.6398 117.601 41.2927 117.811 41.0831C118.021 40.8734 118.368 40.8734 118.578 41.0831C118.788 41.2927 118.788 41.6398 118.578 41.8495Z"
        data-id="f186ce5e9b85"
        fill="white" />
      <path
        d="M123.309 36.8913C123.609 36.8913 123.852 36.6485 123.852 36.349C123.852 36.0494 123.609 35.8066 123.309 35.8066C123.01 35.8066 122.767 36.0494 122.767 36.349C122.767 36.6485 123.01 36.8913 123.309 36.8913Z"
        data-id="8cd77168cecf"
        fill="white" />
      <path
        d="M123.309 36.8913C123.609 36.8913 123.852 36.6485 123.852 36.349C123.852 36.0494 123.609 35.8066 123.309 35.8066C123.01 35.8066 122.767 36.0494 122.767 36.349C122.767 36.6485 123.01 36.8913 123.309 36.8913Z"
        data-id="0c7bad15377f"
        fill="white" />
      <path
        d="M31.2354 118.729C31.5351 118.729 31.778 118.486 31.778 118.187C31.778 117.887 31.5351 117.645 31.2354 117.645C30.9358 117.645 30.6929 117.887 30.6929 118.187C30.6929 118.486 30.9358 118.729 31.2354 118.729Z"
        data-id="cfe77b79d0c9"
        fill="white" />
      <path
        d="M31.2354 118.729C31.5351 118.729 31.778 118.486 31.778 118.187C31.778 117.887 31.5351 117.645 31.2354 117.645C30.9358 117.645 30.6929 117.887 30.6929 118.187C30.6929 118.486 30.9358 118.729 31.2354 118.729Z"
        data-id="24943964d26e"
        fill="white" />
      <path
        d="M36.7337 113.453C36.5239 113.663 36.1767 113.663 35.9669 113.453C35.7571 113.243 35.7571 112.896 35.9669 112.687C36.1767 112.477 36.5239 112.477 36.7337 112.687C36.9435 112.896 36.9435 113.243 36.7337 113.453Z"
        data-id="792bb0d22e53"
        fill="white" />
      <path
        d="M36.7337 113.453C36.5239 113.663 36.1767 113.663 35.9669 113.453C35.7571 113.243 35.7571 112.896 35.9669 112.687C36.1767 112.477 36.5239 112.477 36.7337 112.687C36.9435 112.896 36.9435 113.243 36.7337 113.453Z"
        data-id="dd18b2250cca"
        fill="white" />
      <path
        d="M41.848 108.34C41.6382 108.549 41.291 108.549 41.0812 108.34C40.8714 108.13 40.8714 107.783 41.0812 107.573C41.291 107.364 41.6382 107.364 41.848 107.573C42.0578 107.79 42.0578 108.13 41.848 108.34Z"
        data-id="f05a8d154f95"
        fill="white" />
      <path
        d="M41.848 108.34C41.6382 108.549 41.291 108.549 41.0812 108.34C40.8714 108.13 40.8714 107.783 41.0812 107.573C41.291 107.364 41.6382 107.364 41.848 107.573C42.0578 107.79 42.0578 108.13 41.848 108.34Z"
        data-id="74204212231f"
        fill="white" />
      <path
        d="M46.5787 103.385C46.8783 103.385 47.1212 103.143 47.1212 102.843C47.1212 102.544 46.8783 102.301 46.5787 102.301C46.279 102.301 46.0361 102.544 46.0361 102.843C46.0361 103.143 46.279 103.385 46.5787 103.385Z"
        data-id="044b122a98cb"
        fill="white" />
      <path
        d="M46.5787 103.385C46.8783 103.385 47.1212 103.143 47.1212 102.843C47.1212 102.544 46.8783 102.301 46.5787 102.301C46.279 102.301 46.0361 102.544 46.0361 102.843C46.0361 103.143 46.279 103.385 46.5787 103.385Z"
        data-id="09f288a2c1d3"
        fill="white" />
      <path
        d="M52.077 98.1112C51.8672 98.3209 51.52 98.3209 51.3102 98.1112C51.1004 97.9015 51.1004 97.5545 51.3102 97.3448C51.52 97.1351 51.8672 97.1351 52.077 97.3448C52.294 97.5545 52.294 97.9015 52.077 98.1112Z"
        data-id="75f15510b069"
        fill="white" />
      <path
        d="M52.077 98.1112C51.8672 98.3209 51.52 98.3209 51.3102 98.1112C51.1004 97.9015 51.1004 97.5545 51.3102 97.3448C51.52 97.1351 51.8672 97.1351 52.077 97.3448C52.294 97.5545 52.294 97.9015 52.077 98.1112Z"
        data-id="d836d3fae45d"
        fill="white" />
      <path
        d="M56.8082 93.153C57.1078 93.153 57.3507 92.9102 57.3507 92.6107C57.3507 92.3112 57.1078 92.0684 56.8082 92.0684C56.5085 92.0684 56.2656 92.3112 56.2656 92.6107C56.2656 92.9102 56.5085 93.153 56.8082 93.153Z"
        data-id="918bcef3b514"
        fill="white" />
      <path
        d="M56.8082 93.153C57.1078 93.153 57.3507 92.9102 57.3507 92.6107C57.3507 92.3112 57.1078 92.0684 56.8082 92.0684C56.5085 92.0684 56.2656 92.3112 56.2656 92.6107C56.2656 92.9102 56.5085 93.153 56.8082 93.153Z"
        data-id="e6b1610c86cc"
        fill="white" />
      <path
        d="M62.3055 87.8828C62.0957 88.0925 61.7485 88.0925 61.5387 87.8828C61.3289 87.6731 61.3289 87.326 61.5387 87.1163C61.7485 86.9066 62.0957 86.9066 62.3055 87.1163C62.5225 87.326 62.5225 87.6731 62.3055 87.8828Z"
        data-id="8038996923ea"
        fill="white" />
      <path
        d="M62.3055 87.8828C62.0957 88.0925 61.7485 88.0925 61.5387 87.8828C61.3289 87.6731 61.3289 87.326 61.5387 87.1163C61.7485 86.9066 62.0957 86.9066 62.3055 87.1163C62.5225 87.326 62.5225 87.6731 62.3055 87.8828Z"
        data-id="bfb7ea48e24e"
        fill="white" />
      <path
        d="M67.4271 82.7675C67.2173 82.9772 66.8701 82.9772 66.6603 82.7675C66.4505 82.5578 66.4505 82.2107 66.6603 82.001C66.8701 81.7913 67.2173 81.7913 67.4271 82.001C67.6369 82.2107 67.6369 82.5506 67.4271 82.7675Z"
        data-id="597567f1a560"
        fill="white" />
      <path
        d="M67.4271 82.7675C67.2173 82.9772 66.8701 82.9772 66.6603 82.7675C66.4505 82.5578 66.4505 82.2107 66.6603 82.001C66.8701 81.7913 67.2173 81.7913 67.4271 82.001C67.6369 82.2107 67.6369 82.5506 67.4271 82.7675Z"
        data-id="98675a166a45"
        fill="white" />
      <path
        d="M72.1588 77.8131C72.4584 77.8131 72.7013 77.5703 72.7013 77.2708C72.7013 76.9713 72.4584 76.7285 72.1588 76.7285C71.8591 76.7285 71.6162 76.9713 71.6162 77.2708C71.6162 77.5703 71.8591 77.8131 72.1588 77.8131Z"
        data-id="92fe49a5c448"
        fill="white" />
      <path
        d="M72.1588 77.8131C72.4584 77.8131 72.7013 77.5703 72.7013 77.2708C72.7013 76.9713 72.4584 76.7285 72.1588 76.7285C71.8591 76.7285 71.6162 76.9713 71.6162 77.2708C71.6162 77.5703 71.8591 77.8131 72.1588 77.8131Z"
        data-id="db416765022f"
        fill="white" />
      <path
        d="M77.6561 72.539C77.4463 72.7487 77.0991 72.7487 76.8893 72.539C76.6795 72.3293 76.6795 71.9822 76.8893 71.7725C77.0991 71.5628 77.4463 71.5628 77.6561 71.7725C77.8659 71.9822 77.8659 72.3221 77.6561 72.539Z"
        data-id="7151636ad715"
        fill="white" />
      <path
        d="M77.6561 72.539C77.4463 72.7487 77.0991 72.7487 76.8893 72.539C76.6795 72.3293 76.6795 71.9822 76.8893 71.7725C77.0991 71.5628 77.4463 71.5628 77.6561 71.7725C77.8659 71.9822 77.8659 72.3221 77.6561 72.539Z"
        data-id="166785c7e0be"
        fill="white" />
      <path
        d="M82.7703 67.4159C82.5605 67.6256 82.2133 67.6256 82.0035 67.4159C81.7937 67.2062 81.7937 66.8592 82.0035 66.6495C82.2133 66.4398 82.5605 66.4398 82.7703 66.6495C82.9801 66.8664 82.9801 67.2062 82.7703 67.4159Z"
        data-id="7af3068b27c7"
        fill="white" />
      <path
        d="M82.7703 67.4159C82.5605 67.6256 82.2133 67.6256 82.0035 67.4159C81.7937 67.2062 81.7937 66.8592 82.0035 66.6495C82.2133 66.4398 82.5605 66.4398 82.7703 66.6495C82.9801 66.8664 82.9801 67.2062 82.7703 67.4159Z"
        data-id="98b34cb46212"
        fill="white" />
      <path
        d="M87.501 62.4655C87.8007 62.4655 88.0436 62.2227 88.0436 61.9232C88.0436 61.6237 87.8007 61.3809 87.501 61.3809C87.2014 61.3809 86.9585 61.6237 86.9585 61.9232C86.9585 62.2227 87.2014 62.4655 87.501 62.4655Z"
        data-id="0d52b4190e46"
        fill="white" />
      <path
        d="M87.501 62.4655C87.8007 62.4655 88.0436 62.2227 88.0436 61.9232C88.0436 61.6237 87.8007 61.3809 87.501 61.3809C87.2014 61.3809 86.9585 61.6237 86.9585 61.9232C86.9585 62.2227 87.2014 62.4655 87.501 62.4655Z"
        data-id="b3eb0062a6ff"
        fill="white" />
      <path
        d="M92.9993 57.1894C92.7895 57.3991 92.4423 57.3991 92.2325 57.1894C92.0227 56.9797 92.0227 56.6326 92.2325 56.4229C92.4423 56.2132 92.7895 56.2132 92.9993 56.4229C93.2091 56.6326 93.2091 56.9797 92.9993 57.1894Z"
        data-id="1176b116c1c2"
        fill="white" />
      <path
        d="M92.9993 57.1894C92.7895 57.3991 92.4423 57.3991 92.2325 57.1894C92.0227 56.9797 92.0227 56.6326 92.2325 56.4229C92.4423 56.2132 92.7895 56.2132 92.9993 56.4229C93.2091 56.6326 93.2091 56.9797 92.9993 57.1894Z"
        data-id="35fd6b1eaa0f"
        fill="white" />
      <path
        d="M97.7296 52.235C98.0292 52.235 98.2721 51.9922 98.2721 51.6927C98.2721 51.3932 98.0292 51.1504 97.7296 51.1504C97.4299 51.1504 97.187 51.3932 97.187 51.6927C97.187 51.9922 97.4299 52.235 97.7296 52.235Z"
        data-id="a852c68c1ef4"
        fill="white" />
      <path
        d="M97.7296 52.235C98.0292 52.235 98.2721 51.9922 98.2721 51.6927C98.2721 51.3932 98.0292 51.1504 97.7296 51.1504C97.4299 51.1504 97.187 51.3932 97.187 51.6927C97.187 51.9922 97.4299 52.235 97.7296 52.235Z"
        data-id="cb6c196a6401"
        fill="white" />
      <path
        d="M102.845 47.1198C103.144 47.1198 103.387 46.877 103.387 46.5775C103.387 46.278 103.144 46.0352 102.845 46.0352C102.545 46.0352 102.302 46.278 102.302 46.5775C102.302 46.877 102.545 47.1198 102.845 47.1198Z"
        data-id="cc5ff8b0043e"
        fill="white" />
      <path
        d="M102.845 47.1198C103.144 47.1198 103.387 46.877 103.387 46.5775C103.387 46.278 103.144 46.0352 102.845 46.0352C102.545 46.0352 102.302 46.278 102.302 46.5775C102.302 46.877 102.545 47.1198 102.845 47.1198Z"
        data-id="13010e2d0567"
        fill="white" />
      <path
        d="M108.342 41.8456C108.132 42.0553 107.785 42.0553 107.575 41.8456C107.366 41.6359 107.366 41.2888 107.575 41.0791C107.785 40.8695 108.132 40.8695 108.342 41.0791C108.559 41.2888 108.559 41.6359 108.342 41.8456Z"
        data-id="ee296e147a00"
        fill="white" />
      <path
        d="M108.342 41.8456C108.132 42.0553 107.785 42.0553 107.575 41.8456C107.366 41.6359 107.366 41.2888 107.575 41.0791C107.785 40.8695 108.132 40.8695 108.342 41.0791C108.559 41.2888 108.559 41.6359 108.342 41.8456Z"
        data-id="ddf758c13513"
        fill="white" />
      <path
        d="M113.464 36.7323C113.254 36.942 112.907 36.942 112.697 36.7323C112.487 36.5226 112.487 36.1756 112.697 35.9659C112.907 35.7562 113.254 35.7562 113.464 35.9659C113.673 36.1756 113.673 36.5154 113.464 36.7323Z"
        data-id="8715ae91757d"
        fill="white" />
      <path
        d="M113.464 36.7323C113.254 36.942 112.907 36.942 112.697 36.7323C112.487 36.5226 112.487 36.1756 112.697 35.9659C112.907 35.7562 113.254 35.7562 113.464 35.9659C113.673 36.1756 113.673 36.5154 113.464 36.7323Z"
        data-id="7feeddb05306"
        fill="white" />
      <path
        d="M118.195 31.776C118.495 31.776 118.738 31.5332 118.738 31.2337C118.738 30.9342 118.495 30.6914 118.195 30.6914C117.896 30.6914 117.653 30.9342 117.653 31.2337C117.653 31.5332 117.896 31.776 118.195 31.776Z"
        data-id="643a34187beb"
        fill="white" />
      <path
        d="M118.195 31.776C118.495 31.776 118.738 31.5332 118.738 31.2337C118.738 30.9342 118.495 30.6914 118.195 30.6914C117.896 30.6914 117.653 30.9342 117.653 31.2337C117.653 31.5332 117.896 31.776 118.195 31.776Z"
        data-id="fdc0931fbbfc"
        fill="white" />
      <path
        d="M26.5037 113.455C26.2939 113.665 25.9467 113.665 25.7369 113.455C25.5271 113.245 25.5271 112.898 25.7369 112.689C25.9467 112.479 26.2939 112.479 26.5037 112.689C26.7135 112.905 26.7135 113.245 26.5037 113.455Z"
        data-id="7af9c1ed8780"
        fill="white" />
      <path
        d="M26.5037 113.455C26.2939 113.665 25.9467 113.665 25.7369 113.455C25.5271 113.245 25.5271 112.898 25.7369 112.689C25.9467 112.479 26.2939 112.479 26.5037 112.689C26.7135 112.905 26.7135 113.245 26.5037 113.455Z"
        data-id="edee40874cdf"
        fill="white" />
      <path
        d="M31.2349 108.499C31.5346 108.499 31.7775 108.256 31.7775 107.956C31.7775 107.657 31.5346 107.414 31.2349 107.414C30.9353 107.414 30.6924 107.657 30.6924 107.956C30.6924 108.256 30.9353 108.499 31.2349 108.499Z"
        data-id="dd2bb4eb4589"
        fill="white" />
      <path
        d="M31.2349 108.499C31.5346 108.499 31.7775 108.256 31.7775 107.956C31.7775 107.657 31.5346 107.414 31.2349 107.414C30.9353 107.414 30.6924 107.657 30.6924 107.956C30.6924 108.256 30.9353 108.499 31.2349 108.499Z"
        data-id="d863429a03da"
        fill="white" />
      <path
        d="M36.3497 103.385C36.6493 103.385 36.8922 103.143 36.8922 102.843C36.8922 102.544 36.6493 102.301 36.3497 102.301C36.05 102.301 35.8071 102.544 35.8071 102.843C35.8071 103.143 36.05 103.385 36.3497 103.385Z"
        data-id="11520fa038c7"
        fill="white" />
      <path
        d="M36.3497 103.385C36.6493 103.385 36.8922 103.143 36.8922 102.843C36.8922 102.544 36.6493 102.301 36.3497 102.301C36.05 102.301 35.8071 102.544 35.8071 102.843C35.8071 103.143 36.05 103.385 36.3497 103.385Z"
        data-id="ca81d6a108a8"
        fill="white" />
      <path
        d="M41.847 98.1112C41.6372 98.3209 41.29 98.3209 41.0802 98.1112C40.8704 97.9015 40.8704 97.5545 41.0802 97.3448C41.29 97.1351 41.6372 97.1351 41.847 97.3448C42.0568 97.5545 42.0568 97.9015 41.847 98.1112Z"
        data-id="bd1fe21e0e78"
        fill="white" />
      <path
        d="M41.847 98.1112C41.6372 98.3209 41.29 98.3209 41.0802 98.1112C40.8704 97.9015 40.8704 97.5545 41.0802 97.3448C41.29 97.1351 41.6372 97.1351 41.847 97.3448C42.0568 97.5545 42.0568 97.9015 41.847 98.1112Z"
        data-id="bbf4684e5d5f"
        fill="white" />
      <path
        d="M46.5777 93.153C46.8773 93.153 47.1203 92.9102 47.1203 92.6107C47.1203 92.3112 46.8773 92.0684 46.5777 92.0684C46.2781 92.0684 46.0352 92.3112 46.0352 92.6107C46.0352 92.9102 46.2781 93.153 46.5777 93.153Z"
        data-id="72646fbb287b"
        fill="white" />
      <path
        d="M46.5777 93.153C46.8773 93.153 47.1203 92.9102 47.1203 92.6107C47.1203 92.3112 46.8773 92.0684 46.5777 92.0684C46.2781 92.0684 46.0352 92.3112 46.0352 92.6107C46.0352 92.9102 46.2781 93.153 46.5777 93.153Z"
        data-id="dbc109510942"
        fill="white" />
      <path
        d="M52.076 87.8828C51.8662 88.0925 51.519 88.0925 51.3092 87.8828C51.0994 87.6731 51.0994 87.326 51.3092 87.1163C51.519 86.9066 51.8662 86.9066 52.076 87.1163C52.293 87.326 52.293 87.6731 52.076 87.8828Z"
        data-id="b48d0213284c"
        fill="white" />
      <path
        d="M52.076 87.8828C51.8662 88.0925 51.519 88.0925 51.3092 87.8828C51.0994 87.6731 51.0994 87.326 51.3092 87.1163C51.519 86.9066 51.8662 86.9066 52.076 87.1163C52.293 87.326 52.293 87.6731 52.076 87.8828Z"
        data-id="fd849fb38a3c"
        fill="white" />
      <path
        d="M57.1903 82.7675C56.9805 82.9772 56.6332 82.9772 56.4235 82.7675C56.2137 82.5578 56.2137 82.2107 56.4235 82.001C56.6332 81.7913 56.9805 81.7913 57.1903 82.001C57.4 82.2107 57.4073 82.5506 57.1903 82.7675Z"
        data-id="572664a04fea"
        fill="white" />
      <path
        d="M57.1903 82.7675C56.9805 82.9772 56.6332 82.9772 56.4235 82.7675C56.2137 82.5578 56.2137 82.2107 56.4235 82.001C56.6332 81.7913 56.9805 81.7913 57.1903 82.001C57.4 82.2107 57.4073 82.5506 57.1903 82.7675Z"
        data-id="e25ac4eb1e1b"
        fill="white" />
      <path
        d="M61.9215 77.8131C62.2211 77.8131 62.464 77.5703 62.464 77.2708C62.464 76.9713 62.2211 76.7285 61.9215 76.7285C61.6218 76.7285 61.3789 76.9713 61.3789 77.2708C61.3789 77.5703 61.6218 77.8131 61.9215 77.8131Z"
        data-id="d13b516b64e7"
        fill="white" />
      <path
        d="M61.9215 77.8131C62.2211 77.8131 62.464 77.5703 62.464 77.2708C62.464 76.9713 62.2211 76.7285 61.9215 76.7285C61.6218 76.7285 61.3789 76.9713 61.3789 77.2708C61.3789 77.5703 61.6218 77.8131 61.9215 77.8131Z"
        data-id="10d26ea96fde"
        fill="white" />
      <path
        d="M67.4266 72.5409C67.2168 72.7506 66.8696 72.7506 66.6598 72.5409C66.45 72.3312 66.45 71.9842 66.6598 71.7745C66.8696 71.5648 67.2168 71.5648 67.4266 71.7745C67.6364 71.9842 67.6364 72.324 67.4266 72.5409Z"
        data-id="f660e937ab18"
        fill="white" />
      <path
        d="M67.4266 72.5409C67.2168 72.7506 66.8696 72.7506 66.6598 72.5409C66.45 72.3312 66.45 71.9842 66.6598 71.7745C66.8696 71.5648 67.2168 71.5648 67.4266 71.7745C67.6364 71.9842 67.6364 72.324 67.4266 72.5409Z"
        data-id="8217d2696e3d"
        fill="white" />
      <path
        d="M72.5403 67.4179C72.3306 67.6276 71.9833 67.6276 71.7735 67.4179C71.5638 67.2082 71.5638 66.8611 71.7735 66.6514C71.9833 66.4417 72.3306 66.4417 72.5403 66.6514C72.7501 66.8611 72.7501 67.2082 72.5403 67.4179Z"
        data-id="ecb16be056fc"
        fill="white" />
      <path
        d="M72.5403 67.4179C72.3306 67.6276 71.9833 67.6276 71.7735 67.4179C71.5638 67.2082 71.5638 66.8611 71.7735 66.6514C71.9833 66.4417 72.3306 66.4417 72.5403 66.6514C72.7501 66.8611 72.7501 67.2082 72.5403 67.4179Z"
        data-id="1b5d0453dade"
        fill="white" />
      <path
        d="M77.272 62.4655C77.5717 62.4655 77.8146 62.2227 77.8146 61.9232C77.8146 61.6237 77.5717 61.3809 77.272 61.3809C76.9724 61.3809 76.7295 61.6237 76.7295 61.9232C76.7295 62.2227 76.9724 62.4655 77.272 62.4655Z"
        data-id="0596e72a8eec"
        fill="white" />
      <path
        d="M77.272 62.4655C77.5717 62.4655 77.8146 62.2227 77.8146 61.9232C77.8146 61.6237 77.5717 61.3809 77.272 61.3809C76.9724 61.3809 76.7295 61.6237 76.7295 61.9232C76.7295 62.2227 76.9724 62.4655 77.272 62.4655Z"
        data-id="83740e99524b"
        fill="white" />
      <path
        d="M82.7694 57.1913C82.5596 57.401 82.2123 57.401 82.0026 57.1913C81.7928 56.9816 81.7928 56.6345 82.0026 56.4248C82.2123 56.2152 82.5596 56.2152 82.7694 56.4248C82.9791 56.6345 82.9791 56.9816 82.7694 57.1913Z"
        data-id="fd0a90d7046c"
        fill="white" />
      <path
        d="M82.7694 57.1913C82.5596 57.401 82.2123 57.401 82.0026 57.1913C81.7928 56.9816 81.7928 56.6345 82.0026 56.4248C82.2123 56.2152 82.5596 56.2152 82.7694 56.4248C82.9791 56.6345 82.9791 56.9816 82.7694 57.1913Z"
        data-id="b4b0a27d8f21"
        fill="white" />
      <path
        d="M87.501 52.235C87.8007 52.235 88.0436 51.9922 88.0436 51.6927C88.0436 51.3932 87.8007 51.1504 87.501 51.1504C87.2014 51.1504 86.9585 51.3932 86.9585 51.6927C86.9585 51.9922 87.2014 52.235 87.501 52.235Z"
        data-id="e7709a3d0fa0"
        fill="white" />
      <path
        d="M87.501 52.235C87.8007 52.235 88.0436 51.9922 88.0436 51.6927C88.0436 51.3932 87.8007 51.1504 87.501 51.1504C87.2014 51.1504 86.9585 51.3932 86.9585 51.6927C86.9585 51.9922 87.2014 52.235 87.501 52.235Z"
        data-id="35dd72d20cea"
        fill="white" />
      <path
        d="M92.6148 47.1198C92.9145 47.1198 93.1574 46.877 93.1574 46.5775C93.1574 46.278 92.9145 46.0352 92.6148 46.0352C92.3152 46.0352 92.0723 46.278 92.0723 46.5775C92.0723 46.877 92.3152 47.1198 92.6148 47.1198Z"
        data-id="63a87abb16ba"
        fill="white" />
      <path
        d="M92.6148 47.1198C92.9145 47.1198 93.1574 46.877 93.1574 46.5775C93.1574 46.278 92.9145 46.0352 92.6148 46.0352C92.3152 46.0352 92.0723 46.278 92.0723 46.5775C92.0723 46.877 92.3152 47.1198 92.6148 47.1198Z"
        data-id="8f1a029296a5"
        fill="white" />
      <path
        d="M98.1131 41.8476C97.9033 42.0573 97.5561 42.0573 97.3463 41.8476C97.1365 41.6379 97.1365 41.2908 97.3463 41.0811C97.5561 40.8714 97.9033 40.8714 98.1131 41.0811C98.3229 41.2908 98.3229 41.6379 98.1131 41.8476Z"
        data-id="2d523613b4ee"
        fill="white" />
      <path
        d="M98.1131 41.8476C97.9033 42.0573 97.5561 42.0573 97.3463 41.8476C97.1365 41.6379 97.1365 41.2908 97.3463 41.0811C97.5561 40.8714 97.9033 40.8714 98.1131 41.0811C98.3229 41.2908 98.3229 41.6379 98.1131 41.8476Z"
        data-id="04c488ac93a3"
        fill="white" />
      <path
        d="M102.844 36.8913C103.143 36.8913 103.386 36.6485 103.386 36.349C103.386 36.0494 103.143 35.8066 102.844 35.8066C102.544 35.8066 102.301 36.0494 102.301 36.349C102.301 36.6485 102.544 36.8913 102.844 36.8913Z"
        data-id="036c23133894"
        fill="white" />
      <path
        d="M102.844 36.8913C103.143 36.8913 103.386 36.6485 103.386 36.349C103.386 36.0494 103.143 35.8066 102.844 35.8066C102.544 35.8066 102.301 36.0494 102.301 36.349C102.301 36.6485 102.544 36.8913 102.844 36.8913Z"
        data-id="884691dceb9f"
        fill="white" />
      <path
        d="M107.959 31.776C108.258 31.776 108.501 31.5332 108.501 31.2337C108.501 30.9342 108.258 30.6914 107.959 30.6914C107.659 30.6914 107.416 30.9342 107.416 31.2337C107.416 31.5332 107.659 31.776 107.959 31.776Z"
        data-id="c95dcc1a8cf5"
        fill="white" />
      <path
        d="M107.959 31.776C108.258 31.776 108.501 31.5332 108.501 31.2337C108.501 30.9342 108.258 30.6914 107.959 30.6914C107.659 30.6914 107.416 30.9342 107.416 31.2337C107.416 31.5332 107.659 31.776 107.959 31.776Z"
        data-id="565eeb5c2e42"
        fill="white" />
      <path
        d="M113.463 26.5058C113.253 26.7155 112.906 26.7155 112.696 26.5058C112.487 26.2961 112.487 25.949 112.696 25.7393C112.906 25.5296 113.253 25.5296 113.463 25.7393C113.673 25.949 113.673 26.2888 113.463 26.5058Z"
        data-id="4fd2e1b08913"
        fill="white" />
      <path
        d="M113.463 26.5058C113.253 26.7155 112.906 26.7155 112.696 26.5058C112.487 26.2961 112.487 25.949 112.696 25.7393C112.906 25.5296 113.253 25.5296 113.463 25.7393C113.673 25.949 113.673 26.2888 113.463 26.5058Z"
        data-id="588084235456"
        fill="white" />
      <path
        d="M21.0064 108.501C21.3061 108.501 21.549 108.258 21.549 107.958C21.549 107.659 21.3061 107.416 21.0064 107.416C20.7068 107.416 20.4639 107.659 20.4639 107.958C20.4639 108.258 20.7068 108.501 21.0064 108.501Z"
        data-id="2a25556a5833"
        fill="white" />
      <path
        d="M21.0064 108.501C21.3061 108.501 21.549 108.258 21.549 107.958C21.549 107.659 21.3061 107.416 21.0064 107.416C20.7068 107.416 20.4639 107.659 20.4639 107.958C20.4639 108.258 20.7068 108.501 21.0064 108.501Z"
        data-id="ca91e7db2b1e"
        fill="white" />
      <path
        d="M26.5037 103.225C26.2939 103.434 25.9467 103.434 25.7369 103.225C25.5271 103.015 25.5271 102.668 25.7369 102.458C25.9467 102.248 26.2939 102.248 26.5037 102.458C26.7135 102.668 26.7135 103.015 26.5037 103.225Z"
        data-id="ce2672141a44"
        fill="white" />
      <path
        d="M26.5037 103.225C26.2939 103.434 25.9467 103.434 25.7369 103.225C25.5271 103.015 25.5271 102.668 25.7369 102.458C25.9467 102.248 26.2939 102.248 26.5037 102.458C26.7135 102.668 26.7135 103.015 26.5037 103.225Z"
        data-id="f1010b1118a5"
        fill="white" />
      <path
        d="M31.6199 98.1132C31.4102 98.3229 31.0629 98.3229 30.8531 98.1132C30.6434 97.9035 30.6434 97.5564 30.8531 97.3467C31.0629 97.137 31.4102 97.137 31.6199 97.3467C31.8297 97.5564 31.8297 97.9035 31.6199 98.1132Z"
        data-id="404d8fda2a6e"
        fill="white" />
      <path
        d="M31.6199 98.1132C31.4102 98.3229 31.0629 98.3229 30.8531 98.1132C30.6434 97.9035 30.6434 97.5564 30.8531 97.3467C31.0629 97.137 31.4102 97.137 31.6199 97.3467C31.8297 97.5564 31.8297 97.9035 31.6199 98.1132Z"
        data-id="d20cc703dc22"
        fill="white" />
      <path
        d="M36.3502 93.1549C36.6498 93.1549 36.8927 92.9121 36.8927 92.6126C36.8927 92.3131 36.6498 92.0703 36.3502 92.0703C36.0505 92.0703 35.8076 92.3131 35.8076 92.6126C35.8076 92.9121 36.0505 93.1549 36.3502 93.1549Z"
        data-id="8da1dc17b4c6"
        fill="white" />
      <path
        d="M36.3502 93.1549C36.6498 93.1549 36.8927 92.9121 36.8927 92.6126C36.8927 92.3131 36.6498 92.0703 36.3502 92.0703C36.0505 92.0703 35.8076 92.3131 35.8076 92.6126C35.8076 92.9121 36.0505 93.1549 36.3502 93.1549Z"
        data-id="289f3dde722c"
        fill="white" />
      <path
        d="M41.8475 87.8828C41.6377 88.0925 41.2905 88.0925 41.0807 87.8828C40.8709 87.6731 40.8709 87.326 41.0807 87.1163C41.2905 86.9066 41.6377 86.9066 41.8475 87.1163C42.0573 87.326 42.0573 87.6731 41.8475 87.8828Z"
        data-id="a07a28d4a9fd"
        fill="white" />
      <path
        d="M41.8475 87.8828C41.6377 88.0925 41.2905 88.0925 41.0807 87.8828C40.8709 87.6731 40.8709 87.326 41.0807 87.1163C41.2905 86.9066 41.6377 86.9066 41.8475 87.1163C42.0573 87.326 42.0573 87.6731 41.8475 87.8828Z"
        data-id="90ac0ec267fe"
        fill="white" />
      <path
        d="M46.9627 82.7694C46.7529 82.9791 46.4057 82.9791 46.1959 82.7694C45.9861 82.5597 45.9861 82.2127 46.1959 82.003C46.4057 81.7933 46.7529 81.7933 46.9627 82.003C47.1725 82.2127 47.1725 82.5525 46.9627 82.7694Z"
        data-id="536ac269db76"
        fill="white" />
      <path
        d="M46.9627 82.7694C46.7529 82.9791 46.4057 82.9791 46.1959 82.7694C45.9861 82.5597 45.9861 82.2127 46.1959 82.003C46.4057 81.7933 46.7529 81.7933 46.9627 82.003C47.1725 82.2127 47.1725 82.5525 46.9627 82.7694Z"
        data-id="d6cac148cffa"
        fill="white" />
      <path
        d="M51.6934 77.8131C51.9931 77.8131 52.236 77.5703 52.236 77.2708C52.236 76.9713 51.9931 76.7285 51.6934 76.7285C51.3938 76.7285 51.1509 76.9713 51.1509 77.2708C51.1509 77.5703 51.3938 77.8131 51.6934 77.8131Z"
        data-id="6f75b13dde4c"
        fill="white" />
      <path
        d="M51.6934 77.8131C51.9931 77.8131 52.236 77.5703 52.236 77.2708C52.236 76.9713 51.9931 76.7285 51.6934 76.7285C51.3938 76.7285 51.1509 76.9713 51.1509 77.2708C51.1509 77.5703 51.3938 77.8131 51.6934 77.8131Z"
        data-id="7aa86d7199f5"
        fill="white" />
      <path
        d="M57.1912 72.5409C56.9814 72.7506 56.6342 72.7506 56.4244 72.5409C56.2146 72.3312 56.2146 71.9842 56.4244 71.7745C56.6342 71.5648 56.9814 71.5648 57.1912 71.7745C57.4082 71.9842 57.4082 72.324 57.1912 72.5409Z"
        data-id="f5935c3dcf7d"
        fill="white" />
      <path
        d="M57.1912 72.5409C56.9814 72.7506 56.6342 72.7506 56.4244 72.5409C56.2146 72.3312 56.2146 71.9842 56.4244 71.7745C56.6342 71.5648 56.9814 71.5648 57.1912 71.7745C57.4082 71.9842 57.4082 72.324 57.1912 72.5409Z"
        data-id="4ba7c031ceaa"
        fill="white" />
      <path
        d="M62.3055 67.4198C62.0957 67.6295 61.7485 67.6295 61.5387 67.4198C61.3289 67.2101 61.3289 66.8631 61.5387 66.6534C61.7485 66.4437 62.0957 66.4437 62.3055 66.6534C62.5225 66.8703 62.5225 67.2101 62.3055 67.4198Z"
        data-id="cf20bdc23f75"
        fill="white" />
      <path
        d="M62.3055 67.4198C62.0957 67.6295 61.7485 67.6295 61.5387 67.4198C61.3289 67.2101 61.3289 66.8631 61.5387 66.6534C61.7485 66.4437 62.0957 66.4437 62.3055 66.6534C62.5225 66.8703 62.5225 67.2101 62.3055 67.4198Z"
        data-id="867495f6d8cc"
        fill="white" />
      <path
        d="M67.044 62.4655C67.3437 62.4655 67.5866 62.2227 67.5866 61.9232C67.5866 61.6237 67.3437 61.3809 67.044 61.3809C66.7444 61.3809 66.5015 61.6237 66.5015 61.9232C66.5015 62.2227 66.7444 62.4655 67.044 62.4655Z"
        data-id="4cc8869590d3"
        fill="white" />
      <path
        d="M67.044 62.4655C67.3437 62.4655 67.5866 62.2227 67.5866 61.9232C67.5866 61.6237 67.3437 61.3809 67.044 61.3809C66.7444 61.3809 66.5015 61.6237 66.5015 61.9232C66.5015 62.2227 66.7444 62.4655 67.044 62.4655Z"
        data-id="1549389fd932"
        fill="white" />
      <path
        d="M72.5413 57.1913C72.3315 57.401 71.9843 57.401 71.7745 57.1913C71.5647 56.9816 71.5647 56.6345 71.7745 56.4248C71.9843 56.2152 72.3315 56.2152 72.5413 56.4248C72.7511 56.6345 72.7511 56.9816 72.5413 57.1913Z"
        data-id="b812de071929"
        fill="white" />
      <path
        d="M72.5413 57.1913C72.3315 57.401 71.9843 57.401 71.7745 57.1913C71.5647 56.9816 71.5647 56.6345 71.7745 56.4248C71.9843 56.2152 72.3315 56.2152 72.5413 56.4248C72.7511 56.6345 72.7511 56.9816 72.5413 57.1913Z"
        data-id="75ab173d24b4"
        fill="white" />
      <path
        d="M77.273 52.235C77.5727 52.235 77.8156 51.9922 77.8156 51.6927C77.8156 51.3932 77.5727 51.1504 77.273 51.1504C76.9734 51.1504 76.7305 51.3932 76.7305 51.6927C76.7305 51.9922 76.9734 52.235 77.273 52.235Z"
        data-id="13251d46e0ab"
        fill="white" />
      <path
        d="M77.273 52.235C77.5727 52.235 77.8156 51.9922 77.8156 51.6927C77.8156 51.3932 77.5727 51.1504 77.273 51.1504C76.9734 51.1504 76.7305 51.3932 76.7305 51.6927C76.7305 51.9922 76.9734 52.235 77.273 52.235Z"
        data-id="d1f81ac8ffcd"
        fill="white" />
      <path
        d="M82.3868 47.1198C82.6864 47.1198 82.9293 46.877 82.9293 46.5775C82.9293 46.278 82.6864 46.0352 82.3868 46.0352C82.0871 46.0352 81.8442 46.278 81.8442 46.5775C81.8442 46.877 82.0871 47.1198 82.3868 47.1198Z"
        data-id="9db1d3e3525f"
        fill="white" />
      <path
        d="M82.3868 47.1198C82.6864 47.1198 82.9293 46.877 82.9293 46.5775C82.9293 46.278 82.6864 46.0352 82.3868 46.0352C82.0871 46.0352 81.8442 46.278 81.8442 46.5775C81.8442 46.877 82.0871 47.1198 82.3868 47.1198Z"
        data-id="09d6e7cfd1c3"
        fill="white" />
      <path
        d="M87.8836 41.8476C87.6738 42.0573 87.3266 42.0573 87.1168 41.8476C86.907 41.6379 86.907 41.2908 87.1168 41.0811C87.3266 40.8714 87.6738 40.8714 87.8836 41.0811C88.0934 41.2908 88.0934 41.6379 87.8836 41.8476Z"
        data-id="956671e42ad7"
        fill="white" />
      <path
        d="M87.8836 41.8476C87.6738 42.0573 87.3266 42.0573 87.1168 41.8476C86.907 41.6379 86.907 41.2908 87.1168 41.0811C87.3266 40.8714 87.6738 40.8714 87.8836 41.0811C88.0934 41.2908 88.0934 41.6379 87.8836 41.8476Z"
        data-id="24be2bb646a2"
        fill="white" />
      <path
        d="M92.6148 36.8913C92.9145 36.8913 93.1574 36.6485 93.1574 36.349C93.1574 36.0494 92.9145 35.8066 92.6148 35.8066C92.3152 35.8066 92.0723 36.0494 92.0723 36.349C92.0723 36.6485 92.3152 36.8913 92.6148 36.8913Z"
        data-id="e44b6e0cac8c"
        fill="white" />
      <path
        d="M92.6148 36.8913C92.9145 36.8913 93.1574 36.6485 93.1574 36.349C93.1574 36.0494 92.9145 35.8066 92.6148 35.8066C92.3152 35.8066 92.0723 36.0494 92.0723 36.349C92.0723 36.6485 92.3152 36.8913 92.6148 36.8913Z"
        data-id="f0a79f65b544"
        fill="white" />
      <path
        d="M97.7296 31.776C98.0292 31.776 98.2721 31.5332 98.2721 31.2337C98.2721 30.9342 98.0292 30.6914 97.7296 30.6914C97.4299 30.6914 97.187 30.9342 97.187 31.2337C97.187 31.5332 97.4299 31.776 97.7296 31.776Z"
        data-id="76bddd2a545d"
        fill="white" />
      <path
        d="M97.7296 31.776C98.0292 31.776 98.2721 31.5332 98.2721 31.2337C98.2721 30.9342 98.0292 30.6914 97.7296 30.6914C97.4299 30.6914 97.187 30.9342 97.187 31.2337C97.187 31.5332 97.4299 31.776 97.7296 31.776Z"
        data-id="3ac73ab86a4d"
        fill="white" />
      <path
        d="M102.844 26.6647C103.144 26.6647 103.387 26.4219 103.387 26.1224C103.387 25.8229 103.144 25.5801 102.844 25.5801C102.545 25.5801 102.302 25.8229 102.302 26.1224C102.302 26.4219 102.545 26.6647 102.844 26.6647Z"
        data-id="38a8a54edb82"
        fill="white" />
      <path
        d="M102.844 26.6647C103.144 26.6647 103.387 26.4219 103.387 26.1224C103.387 25.8229 103.144 25.5801 102.844 25.5801C102.545 25.5801 102.302 25.8229 102.302 26.1224C102.302 26.4219 102.545 26.6647 102.844 26.6647Z"
        data-id="dd49dabb95b1"
        fill="white" />
      <path
        d="M107.959 21.5417C108.258 21.5417 108.501 21.2989 108.501 20.9993C108.501 20.6998 108.258 20.457 107.959 20.457C107.659 20.457 107.416 20.6998 107.416 20.9993C107.416 21.2989 107.659 21.5417 107.959 21.5417Z"
        data-id="18c6ad98ef4d"
        fill="white" />
      <path
        d="M107.959 21.5417C108.258 21.5417 108.501 21.2989 108.501 20.9993C108.501 20.6998 108.258 20.457 107.959 20.457C107.659 20.457 107.416 20.6998 107.416 20.9993C107.416 21.2989 107.659 21.5417 107.959 21.5417Z"
        data-id="1b3d69e9c169"
        fill="white" />
      <path
        d="M16.2679 103.225C16.0581 103.434 15.7109 103.434 15.5011 103.225C15.2913 103.015 15.2913 102.668 15.5011 102.458C15.7109 102.248 16.0581 102.248 16.2679 102.458C16.4777 102.668 16.4849 103.015 16.2679 103.225Z"
        data-id="30134c38dfcc"
        fill="white" />
      <path
        d="M16.2679 103.225C16.0581 103.434 15.7109 103.434 15.5011 103.225C15.2913 103.015 15.2913 102.668 15.5011 102.458C15.7109 102.248 16.0581 102.248 16.2679 102.458C16.4777 102.668 16.4849 103.015 16.2679 103.225Z"
        data-id="3e75d30d7187"
        fill="white" />
      <path
        d="M21.3895 98.1112C21.1797 98.3209 20.8325 98.3209 20.6227 98.1112C20.4129 97.9015 20.4129 97.5545 20.6227 97.3448C20.8325 97.1351 21.1797 97.1351 21.3895 97.3448C21.5993 97.5545 21.5993 97.9015 21.3895 98.1112Z"
        data-id="a314894725bb"
        fill="white" />
      <path
        d="M21.3895 98.1112C21.1797 98.3209 20.8325 98.3209 20.6227 98.1112C20.4129 97.9015 20.4129 97.5545 20.6227 97.3448C20.8325 97.1351 21.1797 97.1351 21.3895 97.3448C21.5993 97.5545 21.5993 97.9015 21.3895 98.1112Z"
        data-id="22f4252650b0"
        fill="white" />
      <path
        d="M26.5037 92.996C26.2939 93.2057 25.9467 93.2057 25.7369 92.996C25.5271 92.7863 25.5271 92.4392 25.7369 92.2295C25.9467 92.0198 26.2939 92.0198 26.5037 92.2295C26.7135 92.4392 26.7135 92.7863 26.5037 92.996Z"
        data-id="e2108fa5bd3c"
        fill="white" />
      <path
        d="M26.5037 92.996C26.2939 93.2057 25.9467 93.2057 25.7369 92.996C25.5271 92.7863 25.5271 92.4392 25.7369 92.2295C25.9467 92.0198 26.2939 92.0198 26.5037 92.2295C26.7135 92.4392 26.7135 92.7863 26.5037 92.996Z"
        data-id="a5222e209c97"
        fill="white" />
      <path
        d="M31.6185 87.8828C31.4087 88.0925 31.0615 88.0925 30.8517 87.8828C30.6419 87.6731 30.6419 87.326 30.8517 87.1163C31.0615 86.9066 31.4087 86.9066 31.6185 87.1163C31.8283 87.326 31.8283 87.6731 31.6185 87.8828Z"
        data-id="b7c86153825d"
        fill="white" />
      <path
        d="M31.6185 87.8828C31.4087 88.0925 31.0615 88.0925 30.8517 87.8828C30.6419 87.6731 30.6419 87.326 30.8517 87.1163C31.0615 86.9066 31.4087 86.9066 31.6185 87.1163C31.8283 87.326 31.8283 87.6731 31.6185 87.8828Z"
        data-id="2e535900c2fa"
        fill="white" />
      <path
        d="M36.7327 82.7694C36.5229 82.9791 36.1757 82.9791 35.9659 82.7694C35.7561 82.5597 35.7561 82.2127 35.9659 82.003C36.1757 81.7933 36.5229 81.7933 36.7327 82.003C36.9425 82.2127 36.9425 82.5525 36.7327 82.7694Z"
        data-id="d7fdfde4f4e9"
        fill="white" />
      <path
        d="M36.7327 82.7694C36.5229 82.9791 36.1757 82.9791 35.9659 82.7694C35.7561 82.5597 35.7561 82.2127 35.9659 82.003C36.1757 81.7933 36.5229 81.7933 36.7327 82.003C36.9425 82.2127 36.9425 82.5525 36.7327 82.7694Z"
        data-id="0469fc7c2add"
        fill="white" />
      <path
        d="M41.8475 77.6542C41.6377 77.8639 41.2905 77.8639 41.0807 77.6542C40.8709 77.4445 40.8709 77.0974 41.0807 76.8877C41.2905 76.678 41.6377 76.678 41.8475 76.8877C42.0573 77.0974 42.0573 77.4373 41.8475 77.6542Z"
        data-id="e492f0eca901"
        fill="white" />
      <path
        d="M41.8475 77.6542C41.6377 77.8639 41.2905 77.8639 41.0807 77.6542C40.8709 77.4445 40.8709 77.0974 41.0807 76.8877C41.2905 76.678 41.6377 76.678 41.8475 76.8877C42.0573 77.0974 42.0573 77.4373 41.8475 77.6542Z"
        data-id="6c9e675735db"
        fill="white" />
      <path
        d="M46.9617 72.5409C46.752 72.7506 46.4047 72.7506 46.1949 72.5409C45.9852 72.3312 45.9852 71.9842 46.1949 71.7745C46.4047 71.5648 46.752 71.5648 46.9617 71.7745C47.1715 71.9842 47.1715 72.324 46.9617 72.5409Z"
        data-id="74e0805d69da"
        fill="white" />
      <path
        d="M46.9617 72.5409C46.752 72.7506 46.4047 72.7506 46.1949 72.5409C45.9852 72.3312 45.9852 71.9842 46.1949 71.7745C46.4047 71.5648 46.752 71.5648 46.9617 71.7745C47.1715 71.9842 47.1715 72.324 46.9617 72.5409Z"
        data-id="4a52f8bd2b94"
        fill="white" />
      <path
        d="M52.0765 67.4179C51.8667 67.6276 51.5195 67.6276 51.3097 67.4179C51.0999 67.2082 51.0999 66.8611 51.3097 66.6514C51.5195 66.4417 51.8667 66.4417 52.0765 66.6514C52.2935 66.8683 52.2935 67.2082 52.0765 67.4179Z"
        data-id="222f8ab3e5b7"
        fill="white" />
      <path
        d="M52.0765 67.4179C51.8667 67.6276 51.5195 67.6276 51.3097 67.4179C51.0999 67.2082 51.0999 66.8611 51.3097 66.6514C51.5195 66.4417 51.8667 66.4417 52.0765 66.6514C52.2935 66.8683 52.2935 67.2082 52.0765 67.4179Z"
        data-id="c07ca1569ce7"
        fill="white" />
      <path
        d="M56.8072 62.4655C57.1068 62.4655 57.3497 62.2227 57.3497 61.9232C57.3497 61.6237 57.1068 61.3809 56.8072 61.3809C56.5076 61.3809 56.2646 61.6237 56.2646 61.9232C56.2646 62.2227 56.5076 62.4655 56.8072 62.4655Z"
        data-id="3380bc064a12"
        fill="white" />
      <path
        d="M56.8072 62.4655C57.1068 62.4655 57.3497 62.2227 57.3497 61.9232C57.3497 61.6237 57.1068 61.3809 56.8072 61.3809C56.5076 61.3809 56.2646 61.6237 56.2646 61.9232C56.2646 62.2227 56.5076 62.4655 56.8072 62.4655Z"
        data-id="ce19fc70ca42"
        fill="white" />
      <path
        d="M62.305 57.1913C62.0952 57.401 61.748 57.401 61.5382 57.1913C61.3284 56.9816 61.3284 56.6345 61.5382 56.4248C61.748 56.2152 62.0952 56.2152 62.305 56.4248C62.522 56.6345 62.522 56.9816 62.305 57.1913Z"
        data-id="2f0e2280f049"
        fill="white" />
      <path
        d="M62.305 57.1913C62.0952 57.401 61.748 57.401 61.5382 57.1913C61.3284 56.9816 61.3284 56.6345 61.5382 56.4248C61.748 56.2152 62.0952 56.2152 62.305 56.4248C62.522 56.6345 62.522 56.9816 62.305 57.1913Z"
        data-id="bd4ed3a81f92"
        fill="white" />
      <path
        d="M67.0425 52.235C67.3422 52.235 67.5851 51.9922 67.5851 51.6927C67.5851 51.3932 67.3422 51.1504 67.0425 51.1504C66.7429 51.1504 66.5 51.3932 66.5 51.6927C66.5 51.9922 66.7429 52.235 67.0425 52.235Z"
        data-id="0bed6fbd8079"
        fill="white" />
      <path
        d="M67.0425 52.235C67.3422 52.235 67.5851 51.9922 67.5851 51.6927C67.5851 51.3932 67.3422 51.1504 67.0425 51.1504C66.7429 51.1504 66.5 51.3932 66.5 51.6927C66.5 51.9922 66.7429 52.235 67.0425 52.235Z"
        data-id="05e75a4ad348"
        fill="white" />
      <path
        d="M72.1578 47.1217C72.4574 47.1217 72.7003 46.8789 72.7003 46.5794C72.7003 46.2799 72.4574 46.0371 72.1578 46.0371C71.8581 46.0371 71.6152 46.2799 71.6152 46.5794C71.6152 46.8789 71.8581 47.1217 72.1578 47.1217Z"
        data-id="7b73c42c4cae"
        fill="white" />
      <path
        d="M72.1578 47.1217C72.4574 47.1217 72.7003 46.8789 72.7003 46.5794C72.7003 46.2799 72.4574 46.0371 72.1578 46.0371C71.8581 46.0371 71.6152 46.2799 71.6152 46.5794C71.6152 46.8789 71.8581 47.1217 72.1578 47.1217Z"
        data-id="0ba571e6cdbc"
        fill="white" />
      <path
        d="M77.6551 41.8476C77.4453 42.0573 77.0981 42.0573 76.8883 41.8476C76.6785 41.6379 76.6785 41.2908 76.8883 41.0811C77.0981 40.8714 77.4453 40.8714 77.6551 41.0811C77.8649 41.2908 77.8649 41.6379 77.6551 41.8476Z"
        data-id="0d37e0347b25"
        fill="white" />
      <path
        d="M77.6551 41.8476C77.4453 42.0573 77.0981 42.0573 76.8883 41.8476C76.6785 41.6379 76.6785 41.2908 76.8883 41.0811C77.0981 40.8714 77.4453 40.8714 77.6551 41.0811C77.8649 41.2908 77.8649 41.6379 77.6551 41.8476Z"
        data-id="5323ebe30a9d"
        fill="white" />
      <path
        d="M82.3858 36.8913C82.6855 36.8913 82.9284 36.6485 82.9284 36.349C82.9284 36.0494 82.6855 35.8066 82.3858 35.8066C82.0862 35.8066 81.8433 36.0494 81.8433 36.349C81.8433 36.6485 82.0862 36.8913 82.3858 36.8913Z"
        data-id="b61b74b32f97"
        fill="white" />
      <path
        d="M82.3858 36.8913C82.6855 36.8913 82.9284 36.6485 82.9284 36.349C82.9284 36.0494 82.6855 35.8066 82.3858 35.8066C82.0862 35.8066 81.8433 36.0494 81.8433 36.349C81.8433 36.6485 82.0862 36.8913 82.3858 36.8913Z"
        data-id="e5d93eba684b"
        fill="white" />
      <path
        d="M87.4996 31.778C87.7992 31.778 88.0421 31.5352 88.0421 31.2357C88.0421 30.9362 87.7992 30.6934 87.4996 30.6934C87.1999 30.6934 86.957 30.9362 86.957 31.2357C86.957 31.5352 87.1999 31.778 87.4996 31.778Z"
        data-id="3aee0846b77a"
        fill="white" />
      <path
        d="M87.4996 31.778C87.7992 31.778 88.0421 31.5352 88.0421 31.2357C88.0421 30.9362 87.7992 30.6934 87.4996 30.6934C87.1999 30.6934 86.957 30.9362 86.957 31.2357C86.957 31.5352 87.1999 31.778 87.4996 31.778Z"
        data-id="8e54f9669562"
        fill="white" />
      <path
        d="M92.6143 26.6647C92.914 26.6647 93.1569 26.4219 93.1569 26.1224C93.1569 25.8229 92.914 25.5801 92.6143 25.5801C92.3147 25.5801 92.0718 25.8229 92.0718 26.1224C92.0718 26.4219 92.3147 26.6647 92.6143 26.6647Z"
        data-id="2d5bd1648d80"
        fill="white" />
      <path
        d="M92.6143 26.6647C92.914 26.6647 93.1569 26.4219 93.1569 26.1224C93.1569 25.8229 92.914 25.5801 92.6143 25.5801C92.3147 25.5801 92.0718 25.8229 92.0718 26.1224C92.0718 26.4219 92.3147 26.6647 92.6143 26.6647Z"
        data-id="f45bcb787d7b"
        fill="white" />
      <path
        d="M97.7296 21.5417C98.0292 21.5417 98.2721 21.2989 98.2721 20.9993C98.2721 20.6998 98.0292 20.457 97.7296 20.457C97.4299 20.457 97.187 20.6998 97.187 20.9993C97.187 21.2989 97.4299 21.5417 97.7296 21.5417Z"
        data-id="886a12752701"
        fill="white" />
      <path
        d="M97.7296 21.5417C98.0292 21.5417 98.2721 21.2989 98.2721 20.9993C98.2721 20.6998 98.0292 20.457 97.7296 20.457C97.4299 20.457 97.187 20.6998 97.187 20.9993C97.187 21.2989 97.4299 21.5417 97.7296 21.5417Z"
        data-id="55c3750a8847"
        fill="white" />
      <path
        d="M102.843 16.4264C103.143 16.4264 103.386 16.1836 103.386 15.8841C103.386 15.5846 103.143 15.3418 102.843 15.3418C102.544 15.3418 102.301 15.5846 102.301 15.8841C102.301 16.1836 102.544 16.4264 102.843 16.4264Z"
        data-id="a16a5486b7e7"
        fill="white" />
      <path
        d="M102.843 16.4264C103.143 16.4264 103.386 16.1836 103.386 15.8841C103.386 15.5846 103.143 15.3418 102.843 15.3418C102.544 15.3418 102.301 15.5846 102.301 15.8841C102.301 16.1836 102.544 16.4264 102.843 16.4264Z"
        data-id="bfda63b42c02"
        fill="white" />
      <path
        d="M11.1531 98.1112C10.9434 98.3209 10.5961 98.3209 10.3863 98.1112C10.1766 97.9015 10.1766 97.5545 10.3863 97.3448C10.5961 97.1351 10.9434 97.1351 11.1531 97.3448C11.3702 97.5545 11.3702 97.9015 11.1531 98.1112Z"
        data-id="b7dd7e4d635e"
        fill="white" />
      <path
        d="M11.1531 98.1112C10.9434 98.3209 10.5961 98.3209 10.3863 98.1112C10.1766 97.9015 10.1766 97.5545 10.3863 97.3448C10.5961 97.1351 10.9434 97.1351 11.1531 97.3448C11.3702 97.5545 11.3702 97.9015 11.1531 98.1112Z"
        data-id="9bd2385ae5ef"
        fill="white" />
      <path
        d="M16.2684 92.996C16.0586 93.2057 15.7114 93.2057 15.5016 92.996C15.2918 92.7863 15.2918 92.4392 15.5016 92.2295C15.7114 92.0198 16.0586 92.0198 16.2684 92.2295C16.4782 92.4392 16.4854 92.7863 16.2684 92.996Z"
        data-id="117e34a3d38f"
        fill="white" />
      <path
        d="M16.2684 92.996C16.0586 93.2057 15.7114 93.2057 15.5016 92.996C15.2918 92.7863 15.2918 92.4392 15.5016 92.2295C15.7114 92.0198 16.0586 92.0198 16.2684 92.2295C16.4782 92.4392 16.4854 92.7863 16.2684 92.996Z"
        data-id="1335aa8dca14"
        fill="white" />
      <path
        d="M21.3895 87.8828C21.1797 88.0925 20.8325 88.0925 20.6227 87.8828C20.4129 87.6731 20.4129 87.326 20.6227 87.1163C20.8325 86.9066 21.1797 86.9066 21.3895 87.1163C21.5993 87.326 21.5993 87.6731 21.3895 87.8828Z"
        data-id="4a5dc44a2467"
        fill="white" />
      <path
        d="M21.3895 87.8828C21.1797 88.0925 20.8325 88.0925 20.6227 87.8828C20.4129 87.6731 20.4129 87.326 20.6227 87.1163C20.8325 86.9066 21.1797 86.9066 21.3895 87.1163C21.5993 87.326 21.5993 87.6731 21.3895 87.8828Z"
        data-id="d46a773781c3"
        fill="white" />
      <path
        d="M26.5037 82.7694C26.2939 82.9791 25.9467 82.9791 25.7369 82.7694C25.5271 82.5597 25.5271 82.2127 25.7369 82.003C25.9467 81.7933 26.2939 81.7933 26.5037 82.003C26.7135 82.2127 26.7135 82.5525 26.5037 82.7694Z"
        data-id="b25596fa6346"
        fill="white" />
      <path
        d="M26.5037 82.7694C26.2939 82.9791 25.9467 82.9791 25.7369 82.7694C25.5271 82.5597 25.5271 82.2127 25.7369 82.003C25.9467 81.7933 26.2939 81.7933 26.5037 82.003C26.7135 82.2127 26.7135 82.5525 26.5037 82.7694Z"
        data-id="7f19905c6a99"
        fill="white" />
      <path
        d="M31.2354 77.8131C31.5351 77.8131 31.778 77.5703 31.778 77.2708C31.778 76.9713 31.5351 76.7285 31.2354 76.7285C30.9358 76.7285 30.6929 76.9713 30.6929 77.2708C30.6929 77.5703 30.9358 77.8131 31.2354 77.8131Z"
        data-id="c0cbb1280fc1"
        fill="white" />
      <path
        d="M31.2354 77.8131C31.5351 77.8131 31.778 77.5703 31.778 77.2708C31.778 76.9713 31.5351 76.7285 31.2354 76.7285C30.9358 76.7285 30.6929 76.9713 30.6929 77.2708C30.6929 77.5703 30.9358 77.8131 31.2354 77.8131Z"
        data-id="76fe4f7a3cd8"
        fill="white" />
      <path
        d="M36.7327 72.539C36.5229 72.7487 36.1757 72.7487 35.9659 72.539C35.7561 72.3293 35.7561 71.9822 35.9659 71.7725C36.1757 71.5628 36.5229 71.5628 36.7327 71.7725C36.9425 71.9822 36.9425 72.3221 36.7327 72.539Z"
        data-id="601d14eca55a"
        fill="white" />
      <path
        d="M36.7327 72.539C36.5229 72.7487 36.1757 72.7487 35.9659 72.539C35.7561 72.3293 35.7561 71.9822 35.9659 71.7725C36.1757 71.5628 36.5229 71.5628 36.7327 71.7725C36.9425 71.9822 36.9425 72.3221 36.7327 72.539Z"
        data-id="d0660d583d9e"
        fill="white" />
      <path
        d="M41.8475 67.4179C41.6377 67.6276 41.2905 67.6276 41.0807 67.4179C40.8709 67.2082 40.8709 66.8611 41.0807 66.6514C41.2905 66.4417 41.6377 66.4417 41.8475 66.6514C42.0573 66.8683 42.0573 67.2082 41.8475 67.4179Z"
        data-id="3227b086251f"
        fill="white" />
      <path
        d="M41.8475 67.4179C41.6377 67.6276 41.2905 67.6276 41.0807 67.4179C40.8709 67.2082 40.8709 66.8611 41.0807 66.6514C41.2905 66.4417 41.6377 66.4417 41.8475 66.6514C42.0573 66.8683 42.0573 67.2082 41.8475 67.4179Z"
        data-id="f06f57b0d2d2"
        fill="white" />
      <path
        d="M46.5777 62.4655C46.8773 62.4655 47.1203 62.2227 47.1203 61.9232C47.1203 61.6237 46.8773 61.3809 46.5777 61.3809C46.2781 61.3809 46.0352 61.6237 46.0352 61.9232C46.0352 62.2227 46.2781 62.4655 46.5777 62.4655Z"
        data-id="f095ff964b99"
        fill="white" />
      <path
        d="M46.5777 62.4655C46.8773 62.4655 47.1203 62.2227 47.1203 61.9232C47.1203 61.6237 46.8773 61.3809 46.5777 61.3809C46.2781 61.3809 46.0352 61.6237 46.0352 61.9232C46.0352 62.2227 46.2781 62.4655 46.5777 62.4655Z"
        data-id="6b15261f5f7a"
        fill="white" />
      <path
        d="M52.0765 57.1913C51.8667 57.401 51.5195 57.401 51.3097 57.1913C51.0999 56.9816 51.0999 56.6345 51.3097 56.4248C51.5195 56.2152 51.8667 56.2152 52.0765 56.4248C52.2935 56.6345 52.2935 56.9816 52.0765 57.1913Z"
        data-id="f41085e21dad"
        fill="white" />
      <path
        d="M52.0765 57.1913C51.8667 57.401 51.5195 57.401 51.3097 57.1913C51.0999 56.9816 51.0999 56.6345 51.3097 56.4248C51.5195 56.2152 51.8667 56.2152 52.0765 56.4248C52.2935 56.6345 52.2935 56.9816 52.0765 57.1913Z"
        data-id="8f377645a6e3"
        fill="white" />
      <path
        d="M56.8072 52.235C57.1068 52.235 57.3497 51.9922 57.3497 51.6927C57.3497 51.3932 57.1068 51.1504 56.8072 51.1504C56.5076 51.1504 56.2646 51.3932 56.2646 51.6927C56.2646 51.9922 56.5076 52.235 56.8072 52.235Z"
        data-id="9ff54456115a"
        fill="white" />
      <path
        d="M56.8072 52.235C57.1068 52.235 57.3497 51.9922 57.3497 51.6927C57.3497 51.3932 57.1068 51.1504 56.8072 51.1504C56.5076 51.1504 56.2646 51.3932 56.2646 51.6927C56.2646 51.9922 56.5076 52.235 56.8072 52.235Z"
        data-id="642c8ab96122"
        fill="white" />
      <path
        d="M61.9215 47.1198C62.2211 47.1198 62.464 46.877 62.464 46.5775C62.464 46.278 62.2211 46.0352 61.9215 46.0352C61.6218 46.0352 61.3789 46.278 61.3789 46.5775C61.3789 46.877 61.6218 47.1198 61.9215 47.1198Z"
        data-id="02138f9aa95e"
        fill="white" />
      <path
        d="M61.9215 47.1198C62.2211 47.1198 62.464 46.877 62.464 46.5775C62.464 46.278 62.2211 46.0352 61.9215 46.0352C61.6218 46.0352 61.3789 46.278 61.3789 46.5775C61.3789 46.877 61.6218 47.1198 61.9215 47.1198Z"
        data-id="2a9c0193b920"
        fill="white" />
      <path
        d="M67.4261 41.8476C67.2163 42.0573 66.8691 42.0573 66.6593 41.8476C66.4495 41.6379 66.4495 41.2908 66.6593 41.0811C66.8691 40.8714 67.2163 40.8714 67.4261 41.0811C67.6359 41.2908 67.6359 41.6379 67.4261 41.8476Z"
        data-id="f41091ade665"
        fill="white" />
      <path
        d="M67.4261 41.8476C67.2163 42.0573 66.8691 42.0573 66.6593 41.8476C66.4495 41.6379 66.4495 41.2908 66.6593 41.0811C66.8691 40.8714 67.2163 40.8714 67.4261 41.0811C67.6359 41.2908 67.6359 41.6379 67.4261 41.8476Z"
        data-id="102ccbc57462"
        fill="white" />
      <path
        d="M72.1573 36.8913C72.4569 36.8913 72.6998 36.6485 72.6998 36.349C72.6998 36.0494 72.4569 35.8066 72.1573 35.8066C71.8577 35.8066 71.6147 36.0494 71.6147 36.349C71.6147 36.6485 71.8577 36.8913 72.1573 36.8913Z"
        data-id="4061cb2af5ef"
        fill="white" />
      <path
        d="M72.1573 36.8913C72.4569 36.8913 72.6998 36.6485 72.6998 36.349C72.6998 36.0494 72.4569 35.8066 72.1573 35.8066C71.8577 35.8066 71.6147 36.0494 71.6147 36.349C71.6147 36.6485 71.8577 36.8913 72.1573 36.8913Z"
        data-id="7fa8e33e3b2e"
        fill="white" />
      <path
        d="M77.272 31.776C77.5717 31.776 77.8146 31.5332 77.8146 31.2337C77.8146 30.9342 77.5717 30.6914 77.272 30.6914C76.9724 30.6914 76.7295 30.9342 76.7295 31.2337C76.7295 31.5332 76.9724 31.776 77.272 31.776Z"
        data-id="f1bea8c60d2c"
        fill="white" />
      <path
        d="M77.272 31.776C77.5717 31.776 77.8146 31.5332 77.8146 31.2337C77.8146 30.9342 77.5717 30.6914 77.272 30.6914C76.9724 30.6914 76.7295 30.9342 76.7295 31.2337C76.7295 31.5332 76.9724 31.776 77.272 31.776Z"
        data-id="5f5534376d0e"
        fill="white" />
      <path
        d="M82.3863 26.6647C82.6859 26.6647 82.9288 26.4219 82.9288 26.1224C82.9288 25.8229 82.6859 25.5801 82.3863 25.5801C82.0867 25.5801 81.8438 25.8229 81.8438 26.1224C81.8438 26.4219 82.0867 26.6647 82.3863 26.6647Z"
        data-id="5782f42d265d"
        fill="white" />
      <path
        d="M82.3863 26.6647C82.6859 26.6647 82.9288 26.4219 82.9288 26.1224C82.9288 25.8229 82.6859 25.5801 82.3863 25.5801C82.0867 25.5801 81.8438 25.8229 81.8438 26.1224C81.8438 26.4219 82.0867 26.6647 82.3863 26.6647Z"
        data-id="e35eee05ca87"
        fill="white" />
      <path
        d="M87.5001 21.5417C87.7997 21.5417 88.0426 21.2989 88.0426 20.9993C88.0426 20.6998 87.7997 20.457 87.5001 20.457C87.2004 20.457 86.9575 20.6998 86.9575 20.9993C86.9575 21.2989 87.2004 21.5417 87.5001 21.5417Z"
        data-id="57989c6652a6"
        fill="white" />
      <path
        d="M87.5001 21.5417C87.7997 21.5417 88.0426 21.2989 88.0426 20.9993C88.0426 20.6998 87.7997 20.457 87.5001 20.457C87.2004 20.457 86.9575 20.6998 86.9575 20.9993C86.9575 21.2989 87.2004 21.5417 87.5001 21.5417Z"
        data-id="e5bf523eef30"
        fill="white" />
      <path
        d="M92.6148 16.4245C92.9145 16.4245 93.1574 16.1817 93.1574 15.8822C93.1574 15.5826 92.9145 15.3398 92.6148 15.3398C92.3152 15.3398 92.0723 15.5826 92.0723 15.8822C92.0723 16.1817 92.3152 16.4245 92.6148 16.4245Z"
        data-id="1b49fc6488ba"
        fill="white" />
      <path
        d="M92.6148 16.4245C92.9145 16.4245 93.1574 16.1817 93.1574 15.8822C93.1574 15.5826 92.9145 15.3398 92.6148 15.3398C92.3152 15.3398 92.0723 15.5826 92.0723 15.8822C92.0723 16.1817 92.3152 16.4245 92.6148 16.4245Z"
        data-id="253590f9a6c2"
        fill="white" />
      <path
        d="M97.7296 11.3131C98.0292 11.3131 98.2721 11.0703 98.2721 10.7708C98.2721 10.4713 98.0292 10.2285 97.7296 10.2285C97.4299 10.2285 97.187 10.4713 97.187 10.7708C97.187 11.0703 97.4299 11.3131 97.7296 11.3131Z"
        data-id="f46c26c6f3a8"
        fill="white" />
      <path
        d="M97.7296 11.3131C98.0292 11.3131 98.2721 11.0703 98.2721 10.7708C98.2721 10.4713 98.0292 10.2285 97.7296 10.2285C97.4299 10.2285 97.187 10.4713 97.187 10.7708C97.187 11.0703 97.4299 11.3131 97.7296 11.3131Z"
        data-id="659f8a6dacb0"
        fill="white" />
      <path
        d="M5.65485 93.153C5.95449 93.153 6.1974 92.9102 6.1974 92.6107C6.1974 92.3112 5.95449 92.0684 5.65485 92.0684C5.35521 92.0684 5.1123 92.3112 5.1123 92.6107C5.1123 92.9102 5.35521 93.153 5.65485 93.153Z"
        data-id="62ee39664442"
        fill="white" />
      <path
        d="M5.65485 93.153C5.95449 93.153 6.1974 92.9102 6.1974 92.6107C6.1974 92.3112 5.95449 92.0684 5.65485 92.0684C5.35521 92.0684 5.1123 92.3112 5.1123 92.6107C5.1123 92.9102 5.35521 93.153 5.65485 93.153Z"
        data-id="ce5133c9b608"
        fill="white" />
      <path
        d="M11.1531 87.8808C10.9434 88.0905 10.5961 88.0905 10.3863 87.8808C10.1766 87.6711 10.1766 87.324 10.3863 87.1143C10.5961 86.9046 10.9434 86.9046 11.1531 87.1143C11.3702 87.324 11.3702 87.6711 11.1531 87.8808Z"
        data-id="a709b2b5f404"
        fill="white" />
      <path
        d="M11.1531 87.8808C10.9434 88.0905 10.5961 88.0905 10.3863 87.8808C10.1766 87.6711 10.1766 87.324 10.3863 87.1143C10.5961 86.9046 10.9434 86.9046 11.1531 87.1143C11.3702 87.324 11.3702 87.6711 11.1531 87.8808Z"
        data-id="4c6acef1f29f"
        fill="white" />
      <path
        d="M16.2679 82.7675C16.0581 82.9772 15.7109 82.9772 15.5011 82.7675C15.2913 82.5578 15.2913 82.2107 15.5011 82.001C15.7109 81.7913 16.0581 81.7913 16.2679 82.001C16.4777 82.2107 16.4849 82.5506 16.2679 82.7675Z"
        data-id="76807cf747a9"
        fill="white" />
      <path
        d="M16.2679 82.7675C16.0581 82.9772 15.7109 82.9772 15.5011 82.7675C15.2913 82.5578 15.2913 82.2107 15.5011 82.001C15.7109 81.7913 16.0581 81.7913 16.2679 82.001C16.4777 82.2107 16.4849 82.5506 16.2679 82.7675Z"
        data-id="a75eb6bb4adb"
        fill="white" />
      <path
        d="M21.005 77.8112C21.3046 77.8112 21.5475 77.5684 21.5475 77.2689C21.5475 76.9694 21.3046 76.7266 21.005 76.7266C20.7053 76.7266 20.4624 76.9694 20.4624 77.2689C20.4624 77.5684 20.7053 77.8112 21.005 77.8112Z"
        data-id="7635f6a009a8"
        fill="white" />
      <path
        d="M21.005 77.8112C21.3046 77.8112 21.5475 77.5684 21.5475 77.2689C21.5475 76.9694 21.3046 76.7266 21.005 76.7266C20.7053 76.7266 20.4624 76.9694 20.4624 77.2689C20.4624 77.5684 20.7053 77.8112 21.005 77.8112Z"
        data-id="a4b6afd2eda8"
        fill="white" />
      <path
        d="M26.5032 72.539C26.2935 72.7487 25.9462 72.7487 25.7364 72.539C25.5267 72.3293 25.5267 71.9822 25.7364 71.7725C25.9462 71.5628 26.2935 71.5628 26.5032 71.7725C26.713 71.9822 26.713 72.3221 26.5032 72.539Z"
        data-id="847099654a98"
        fill="white" />
      <path
        d="M26.5032 72.539C26.2935 72.7487 25.9462 72.7487 25.7364 72.539C25.5267 72.3293 25.5267 71.9822 25.7364 71.7725C25.9462 71.5628 26.2935 71.5628 26.5032 71.7725C26.713 71.9822 26.713 72.3221 26.5032 72.539Z"
        data-id="d8bf9976675a"
        fill="white" />
      <path
        d="M31.6185 67.4179C31.4087 67.6276 31.0615 67.6276 30.8517 67.4179C30.6419 67.2082 30.6419 66.8611 30.8517 66.6514C31.0615 66.4417 31.4087 66.4417 31.6185 66.6514C31.8283 66.8683 31.8283 67.2082 31.6185 67.4179Z"
        data-id="b2b33ad00ee4"
        fill="white" />
      <path
        d="M31.6185 67.4179C31.4087 67.6276 31.0615 67.6276 30.8517 67.4179C30.6419 67.2082 30.6419 66.8611 30.8517 66.6514C31.0615 66.4417 31.4087 66.4417 31.6185 66.6514C31.8283 66.8683 31.8283 67.2082 31.6185 67.4179Z"
        data-id="7f39b4809649"
        fill="white" />
      <path
        d="M36.3492 62.4655C36.6488 62.4655 36.8917 62.2227 36.8917 61.9232C36.8917 61.6237 36.6488 61.3809 36.3492 61.3809C36.0495 61.3809 35.8066 61.6237 35.8066 61.9232C35.8066 62.2227 36.0495 62.4655 36.3492 62.4655Z"
        data-id="6b43412a787d"
        fill="white" />
      <path
        d="M36.3492 62.4655C36.6488 62.4655 36.8917 62.2227 36.8917 61.9232C36.8917 61.6237 36.6488 61.3809 36.3492 61.3809C36.0495 61.3809 35.8066 61.6237 35.8066 61.9232C35.8066 62.2227 36.0495 62.4655 36.3492 62.4655Z"
        data-id="f21042590dd7"
        fill="white" />
      <path
        d="M41.8465 57.1913C41.6367 57.401 41.2895 57.401 41.0797 57.1913C40.8699 56.9816 40.8699 56.6345 41.0797 56.4248C41.2895 56.2152 41.6367 56.2152 41.8465 56.4248C42.0563 56.6345 42.0563 56.9816 41.8465 57.1913Z"
        data-id="f5d6a21f0383"
        fill="white" />
      <path
        d="M41.8465 57.1913C41.6367 57.401 41.2895 57.401 41.0797 57.1913C40.8699 56.9816 40.8699 56.6345 41.0797 56.4248C41.2895 56.2152 41.6367 56.2152 41.8465 56.4248C42.0563 56.6345 42.0563 56.9816 41.8465 57.1913Z"
        data-id="73c9dd66f8f7"
        fill="white" />
      <path
        d="M46.5777 52.235C46.8773 52.235 47.1203 51.9922 47.1203 51.6927C47.1203 51.3932 46.8773 51.1504 46.5777 51.1504C46.2781 51.1504 46.0352 51.3932 46.0352 51.6927C46.0352 51.9922 46.2781 52.235 46.5777 52.235Z"
        data-id="f5bf83124551"
        fill="white" />
      <path
        d="M46.5777 52.235C46.8773 52.235 47.1203 51.9922 47.1203 51.6927C47.1203 51.3932 46.8773 51.1504 46.5777 51.1504C46.2781 51.1504 46.0352 51.3932 46.0352 51.6927C46.0352 51.9922 46.2781 52.235 46.5777 52.235Z"
        data-id="69b436bc3742"
        fill="white" />
      <path
        d="M51.692 47.1198C51.9916 47.1198 52.2345 46.877 52.2345 46.5775C52.2345 46.278 51.9916 46.0352 51.692 46.0352C51.3923 46.0352 51.1494 46.278 51.1494 46.5775C51.1494 46.877 51.3923 47.1198 51.692 47.1198Z"
        data-id="719e5d4124ee"
        fill="white" />
      <path
        d="M51.692 47.1198C51.9916 47.1198 52.2345 46.877 52.2345 46.5775C52.2345 46.278 51.9916 46.0352 51.692 46.0352C51.3923 46.0352 51.1494 46.278 51.1494 46.5775C51.1494 46.877 51.3923 47.1198 51.692 47.1198Z"
        data-id="7a8db4d3f3a3"
        fill="white" />
      <path
        d="M57.1903 41.8476C56.9805 42.0573 56.6332 42.0573 56.4235 41.8476C56.2137 41.6379 56.2137 41.2908 56.4235 41.0811C56.6332 40.8714 56.9805 40.8714 57.1903 41.0811C57.4073 41.2908 57.4073 41.6379 57.1903 41.8476Z"
        data-id="b90e26194af3"
        fill="white" />
      <path
        d="M57.1903 41.8476C56.9805 42.0573 56.6332 42.0573 56.4235 41.8476C56.2137 41.6379 56.2137 41.2908 56.4235 41.0811C56.6332 40.8714 56.9805 40.8714 57.1903 41.0811C57.4073 41.2908 57.4073 41.6379 57.1903 41.8476Z"
        data-id="a702bd8bb58e"
        fill="white" />
      <path
        d="M61.9215 36.8893C62.2211 36.8893 62.464 36.6465 62.464 36.347C62.464 36.0475 62.2211 35.8047 61.9215 35.8047C61.6218 35.8047 61.3789 36.0475 61.3789 36.347C61.3789 36.6465 61.6218 36.8893 61.9215 36.8893Z"
        data-id="4eb0d5b4e063"
        fill="white" />
      <path
        d="M61.9215 36.8893C62.2211 36.8893 62.464 36.6465 62.464 36.347C62.464 36.0475 62.2211 35.8047 61.9215 35.8047C61.6218 35.8047 61.3789 36.0475 61.3789 36.347C61.3789 36.6465 61.6218 36.8893 61.9215 36.8893Z"
        data-id="64abfa9f0a2e"
        fill="white" />
      <path
        d="M67.0425 31.776C67.3422 31.776 67.5851 31.5332 67.5851 31.2337C67.5851 30.9342 67.3422 30.6914 67.0425 30.6914C66.7429 30.6914 66.5 30.9342 66.5 31.2337C66.5 31.5332 66.7429 31.776 67.0425 31.776Z"
        data-id="1260e0fae6a8"
        fill="white" />
      <path
        d="M67.0425 31.776C67.3422 31.776 67.5851 31.5332 67.5851 31.2337C67.5851 30.9342 67.3422 30.6914 67.0425 30.6914C66.7429 30.6914 66.5 30.9342 66.5 31.2337C66.5 31.5332 66.7429 31.776 67.0425 31.776Z"
        data-id="57c9eb5946bc"
        fill="white" />
      <path
        d="M72.1583 26.6647C72.4579 26.6647 72.7008 26.4219 72.7008 26.1224C72.7008 25.8229 72.4579 25.5801 72.1583 25.5801C71.8586 25.5801 71.6157 25.8229 71.6157 26.1224C71.6157 26.4219 71.8586 26.6647 72.1583 26.6647Z"
        data-id="ab574a5525cf"
        fill="white" />
      <path
        d="M72.1583 26.6647C72.4579 26.6647 72.7008 26.4219 72.7008 26.1224C72.7008 25.8229 72.4579 25.5801 72.1583 25.5801C71.8586 25.5801 71.6157 25.8229 71.6157 26.1224C71.6157 26.4219 71.8586 26.6647 72.1583 26.6647Z"
        data-id="e7a2d85665b5"
        fill="white" />
      <path
        d="M77.2716 21.5397C77.5712 21.5397 77.8141 21.2969 77.8141 20.9974C77.8141 20.6979 77.5712 20.4551 77.2716 20.4551C76.9719 20.4551 76.729 20.6979 76.729 20.9974C76.729 21.2969 76.9719 21.5397 77.2716 21.5397Z"
        data-id="b87506246527"
        fill="white" />
      <path
        d="M77.2716 21.5397C77.5712 21.5397 77.8141 21.2969 77.8141 20.9974C77.8141 20.6979 77.5712 20.4551 77.2716 20.4551C76.9719 20.4551 76.729 20.6979 76.729 20.9974C76.729 21.2969 76.9719 21.5397 77.2716 21.5397Z"
        data-id="eb6e9bc8c0a2"
        fill="white" />
      <path
        d="M82.3858 16.4245C82.6855 16.4245 82.9284 16.1817 82.9284 15.8822C82.9284 15.5826 82.6855 15.3398 82.3858 15.3398C82.0862 15.3398 81.8433 15.5826 81.8433 15.8822C81.8433 16.1817 82.0862 16.4245 82.3858 16.4245Z"
        data-id="b9b0e8799c77"
        fill="white" />
      <path
        d="M82.3858 16.4245C82.6855 16.4245 82.9284 16.1817 82.9284 15.8822C82.9284 15.5826 82.6855 15.3398 82.3858 15.3398C82.0862 15.3398 81.8433 15.5826 81.8433 15.8822C81.8433 16.1817 82.0862 16.4245 82.3858 16.4245Z"
        data-id="3453695b9f3d"
        fill="white" />
      <path
        d="M87.5001 11.3112C87.7997 11.3112 88.0426 11.0684 88.0426 10.7689C88.0426 10.4694 87.7997 10.2266 87.5001 10.2266C87.2004 10.2266 86.9575 10.4694 86.9575 10.7689C86.9575 11.0684 87.2004 11.3112 87.5001 11.3112Z"
        data-id="de8eb27c1020"
        fill="white" />
      <path
        d="M87.5001 11.3112C87.7997 11.3112 88.0426 11.0684 88.0426 10.7689C88.0426 10.4694 87.7997 10.2266 87.5001 10.2266C87.2004 10.2266 86.9575 10.4694 86.9575 10.7689C86.9575 11.0684 87.2004 11.3112 87.5001 11.3112Z"
        data-id="0473799c67cc"
        fill="white" />
      <path
        d="M92.6143 6.19596C92.914 6.19596 93.1569 5.95316 93.1569 5.65364C93.1569 5.35413 92.914 5.11133 92.6143 5.11133C92.3147 5.11133 92.0718 5.35413 92.0718 5.65364C92.0718 5.95316 92.3147 6.19596 92.6143 6.19596Z"
        data-id="5e9635937aab"
        fill="white" />
      <path
        d="M92.6143 6.19596C92.914 6.19596 93.1569 5.95316 93.1569 5.65364C93.1569 5.35413 92.914 5.11133 92.6143 5.11133C92.3147 5.11133 92.0718 5.35413 92.0718 5.65364C92.0718 5.95316 92.3147 6.19596 92.6143 6.19596Z"
        data-id="649f7e61b7d5"
        fill="white" />
      <path
        d="M0.924139 87.8828C0.714353 88.0925 0.367124 88.0925 0.157339 87.8828C-0.0524463 87.6731 -0.0524463 87.326 0.157339 87.1163C0.367124 86.9066 0.714353 86.9066 0.924139 87.1163C1.13392 87.326 1.13392 87.6731 0.924139 87.8828Z"
        data-id="1bce28a6fd7b"
        fill="white" />
      <path
        d="M0.924139 87.8828C0.714353 88.0925 0.367124 88.0925 0.157339 87.8828C-0.0524463 87.6731 -0.0524463 87.326 0.157339 87.1163C0.367124 86.9066 0.714353 86.9066 0.924139 87.1163C1.13392 87.326 1.13392 87.6731 0.924139 87.8828Z"
        data-id="799c6adccd42"
        fill="white" />
      <path
        d="M6.03888 82.7694C5.8291 82.9791 5.48187 82.9791 5.27209 82.7694C5.0623 82.5597 5.0623 82.2127 5.27209 82.003C5.48187 81.7933 5.8291 81.7933 6.03888 82.003C6.24867 82.2127 6.2559 82.5525 6.03888 82.7694Z"
        data-id="f77ddee0642e"
        fill="white" />
      <path
        d="M6.03888 82.7694C5.8291 82.9791 5.48187 82.9791 5.27209 82.7694C5.0623 82.5597 5.0623 82.2127 5.27209 82.003C5.48187 81.7933 5.8291 81.7933 6.03888 82.003C6.24867 82.2127 6.2559 82.5525 6.03888 82.7694Z"
        data-id="bedfe2890673"
        fill="white" />
      <path
        d="M11.1531 77.6542C10.9434 77.8639 10.5961 77.8639 10.3863 77.6542C10.1766 77.4445 10.1766 77.0974 10.3863 76.8877C10.5961 76.678 10.9434 76.678 11.1531 76.8877C11.3702 77.0974 11.3702 77.4373 11.1531 77.6542Z"
        data-id="7942c919e65c"
        fill="white" />
      <path
        d="M11.1531 77.6542C10.9434 77.8639 10.5961 77.8639 10.3863 77.6542C10.1766 77.4445 10.1766 77.0974 10.3863 76.8877C10.5961 76.678 10.9434 76.678 11.1531 76.8877C11.3702 77.0974 11.3702 77.4373 11.1531 77.6542Z"
        data-id="33e5680bef56"
        fill="white" />
      <path
        d="M16.2669 72.5409C16.0571 72.7506 15.7099 72.7506 15.5001 72.5409C15.2903 72.3312 15.2903 71.9842 15.5001 71.7745C15.7099 71.5648 16.0571 71.5648 16.2669 71.7745C16.4839 71.9842 16.4839 72.324 16.2669 72.5409Z"
        data-id="418567444f5a"
        fill="white" />
      <path
        d="M16.2669 72.5409C16.0571 72.7506 15.7099 72.7506 15.5001 72.5409C15.2903 72.3312 15.2903 71.9842 15.5001 71.7745C15.7099 71.5648 16.0571 71.5648 16.2669 71.7745C16.4839 71.9842 16.4839 72.324 16.2669 72.5409Z"
        data-id="ed3fba621dd1"
        fill="white" />
      <path
        d="M21.3885 67.4198C21.1787 67.6295 20.8315 67.6295 20.6217 67.4198C20.4119 67.2101 20.4119 66.8631 20.6217 66.6534C20.8315 66.4437 21.1787 66.4437 21.3885 66.6534C21.5983 66.8631 21.5983 67.2101 21.3885 67.4198Z"
        data-id="bc8d416e468c"
        fill="white" />
      <path
        d="M21.3885 67.4198C21.1787 67.6295 20.8315 67.6295 20.6217 67.4198C20.4119 67.2101 20.4119 66.8631 20.6217 66.6534C20.8315 66.4437 21.1787 66.4437 21.3885 66.6534C21.5983 66.8631 21.5983 67.2101 21.3885 67.4198Z"
        data-id="7dd450480100"
        fill="white" />
      <path
        d="M26.5037 62.3046C26.2939 62.5143 25.9467 62.5143 25.7369 62.3046C25.5271 62.0949 25.5271 61.7478 25.7369 61.5381C25.9467 61.3284 26.2939 61.3284 26.5037 61.5381C26.7135 61.7551 26.7135 62.0949 26.5037 62.3046Z"
        data-id="b2016e0fe6b1"
        fill="white" />
      <path
        d="M26.5037 62.3046C26.2939 62.5143 25.9467 62.5143 25.7369 62.3046C25.5271 62.0949 25.5271 61.7478 25.7369 61.5381C25.9467 61.3284 26.2939 61.3284 26.5037 61.5381C26.7135 61.7551 26.7135 62.0949 26.5037 62.3046Z"
        data-id="ed5c91163fec"
        fill="white" />
      <path
        d="M31.6185 57.1913C31.4087 57.401 31.0615 57.401 30.8517 57.1913C30.6419 56.9816 30.6419 56.6345 30.8517 56.4248C31.0615 56.2152 31.4087 56.2152 31.6185 56.4248C31.8283 56.6345 31.8283 56.9816 31.6185 57.1913Z"
        data-id="dfb6a18fe287"
        fill="white" />
      <path
        d="M31.6185 57.1913C31.4087 57.401 31.0615 57.401 30.8517 57.1913C30.6419 56.9816 30.6419 56.6345 30.8517 56.4248C31.0615 56.2152 31.4087 56.2152 31.6185 56.4248C31.8283 56.6345 31.8283 56.9816 31.6185 57.1913Z"
        data-id="cf5381a130eb"
        fill="white" />
      <path
        d="M36.3492 52.235C36.6488 52.235 36.8917 51.9922 36.8917 51.6927C36.8917 51.3932 36.6488 51.1504 36.3492 51.1504C36.0495 51.1504 35.8066 51.3932 35.8066 51.6927C35.8066 51.9922 36.0495 52.235 36.3492 52.235Z"
        data-id="49433a010e56"
        fill="white" />
      <path
        d="M36.3492 52.235C36.6488 52.235 36.8917 51.9922 36.8917 51.6927C36.8917 51.3932 36.6488 51.1504 36.3492 51.1504C36.0495 51.1504 35.8066 51.3932 35.8066 51.6927C35.8066 51.9922 36.0495 52.235 36.3492 52.235Z"
        data-id="5d28295ecda5"
        fill="white" />
      <path
        d="M41.8475 46.9628C41.6377 47.1725 41.2905 47.1725 41.0807 46.9628C40.8709 46.7531 40.8709 46.406 41.0807 46.1963C41.2905 45.9866 41.6377 45.9866 41.8475 46.1963C42.0573 46.406 42.0573 46.7531 41.8475 46.9628Z"
        data-id="1ab93a7eecb2"
        fill="white" />
      <path
        d="M41.8475 46.9628C41.6377 47.1725 41.2905 47.1725 41.0807 46.9628C40.8709 46.7531 40.8709 46.406 41.0807 46.1963C41.2905 45.9866 41.6377 45.9866 41.8475 46.1963C42.0573 46.406 42.0573 46.7531 41.8475 46.9628Z"
        data-id="24859001a173"
        fill="white" />
      <path
        d="M46.9617 41.8476C46.752 42.0573 46.4047 42.0573 46.1949 41.8476C45.9852 41.6379 45.9852 41.2908 46.1949 41.0811C46.4047 40.8714 46.752 40.8714 46.9617 41.0811C47.1715 41.2908 47.1715 41.6379 46.9617 41.8476Z"
        data-id="ec2e434e9afc"
        fill="white" />
      <path
        d="M46.9617 41.8476C46.752 42.0573 46.4047 42.0573 46.1949 41.8476C45.9852 41.6379 45.9852 41.2908 46.1949 41.0811C46.4047 40.8714 46.752 40.8714 46.9617 41.0811C47.1715 41.2908 47.1715 41.6379 46.9617 41.8476Z"
        data-id="be61e76b50c2"
        fill="white" />
      <path
        d="M51.6929 36.8913C51.9926 36.8913 52.2355 36.6485 52.2355 36.349C52.2355 36.0494 51.9926 35.8066 51.6929 35.8066C51.3933 35.8066 51.1504 36.0494 51.1504 36.349C51.1504 36.6485 51.3933 36.8913 51.6929 36.8913Z"
        data-id="f07c4925bfa0"
        fill="white" />
      <path
        d="M51.6929 36.8913C51.9926 36.8913 52.2355 36.6485 52.2355 36.349C52.2355 36.0494 51.9926 35.8066 51.6929 35.8066C51.3933 35.8066 51.1504 36.0494 51.1504 36.349C51.1504 36.6485 51.3933 36.8913 51.6929 36.8913Z"
        data-id="c2315ed1f09a"
        fill="white" />
      <path
        d="M56.8067 31.778C57.1063 31.778 57.3493 31.5352 57.3493 31.2357C57.3493 30.9362 57.1063 30.6934 56.8067 30.6934C56.5071 30.6934 56.2642 30.9362 56.2642 31.2357C56.2642 31.5352 56.5071 31.778 56.8067 31.778Z"
        data-id="fa1beb7ecc9e"
        fill="white" />
      <path
        d="M56.8067 31.778C57.1063 31.778 57.3493 31.5352 57.3493 31.2357C57.3493 30.9362 57.1063 30.6934 56.8067 30.6934C56.5071 30.6934 56.2642 30.9362 56.2642 31.2357C56.2642 31.5352 56.5071 31.778 56.8067 31.778Z"
        data-id="12ba87178b2a"
        fill="white" />
      <path
        d="M61.9219 26.6647C62.2216 26.6647 62.4645 26.4219 62.4645 26.1224C62.4645 25.8229 62.2216 25.5801 61.9219 25.5801C61.6223 25.5801 61.3794 25.8229 61.3794 26.1224C61.3794 26.4219 61.6223 26.6647 61.9219 26.6647Z"
        data-id="7b3d2f290bfb"
        fill="white" />
      <path
        d="M61.9219 26.6647C62.2216 26.6647 62.4645 26.4219 62.4645 26.1224C62.4645 25.8229 62.2216 25.5801 61.9219 25.5801C61.6223 25.5801 61.3794 25.8229 61.3794 26.1224C61.3794 26.4219 61.6223 26.6647 61.9219 26.6647Z"
        data-id="02951951eddd"
        fill="white" />
      <path
        d="M67.0425 21.5417C67.3422 21.5417 67.5851 21.2989 67.5851 20.9993C67.5851 20.6998 67.3422 20.457 67.0425 20.457C66.7429 20.457 66.5 20.6998 66.5 20.9993C66.5 21.2989 66.7429 21.5417 67.0425 21.5417Z"
        data-id="a210a52e510a"
        fill="white" />
      <path
        d="M67.0425 21.5417C67.3422 21.5417 67.5851 21.2989 67.5851 20.9993C67.5851 20.6998 67.3422 20.457 67.0425 20.457C66.7429 20.457 66.5 20.6998 66.5 20.9993C66.5 21.2989 66.7429 21.5417 67.0425 21.5417Z"
        data-id="610bb4ba9791"
        fill="white" />
      <path
        d="M72.1573 16.4264C72.4569 16.4264 72.6998 16.1836 72.6998 15.8841C72.6998 15.5846 72.4569 15.3418 72.1573 15.3418C71.8577 15.3418 71.6147 15.5846 71.6147 15.8841C71.6147 16.1836 71.8577 16.4264 72.1573 16.4264Z"
        data-id="e6dd52fc8924"
        fill="white" />
      <path
        d="M72.1573 16.4264C72.4569 16.4264 72.6998 16.1836 72.6998 15.8841C72.6998 15.5846 72.4569 15.3418 72.1573 15.3418C71.8577 15.3418 71.6147 15.5846 71.6147 15.8841C71.6147 16.1836 71.8577 16.4264 72.1573 16.4264Z"
        data-id="9e3a6c2df370"
        fill="white" />
      <path
        d="M77.2716 11.3131C77.5712 11.3131 77.8141 11.0703 77.8141 10.7708C77.8141 10.4713 77.5712 10.2285 77.2716 10.2285C76.9719 10.2285 76.729 10.4713 76.729 10.7708C76.729 11.0703 76.9719 11.3131 77.2716 11.3131Z"
        data-id="b4a671446102"
        fill="white" />
      <path
        d="M77.2716 11.3131C77.5712 11.3131 77.8141 11.0703 77.8141 10.7708C77.8141 10.4713 77.5712 10.2285 77.2716 10.2285C76.9719 10.2285 76.729 10.4713 76.729 10.7708C76.729 11.0703 76.9719 11.3131 77.2716 11.3131Z"
        data-id="be65b35ff082"
        fill="white" />
      <path
        d="M82.3858 6.19596C82.6855 6.19596 82.9284 5.95316 82.9284 5.65364C82.9284 5.35413 82.6855 5.11133 82.3858 5.11133C82.0862 5.11133 81.8433 5.35413 81.8433 5.65364C81.8433 5.95316 82.0862 6.19596 82.3858 6.19596Z"
        data-id="1fc781745a99"
        fill="white" />
      <path
        d="M82.3858 6.19596C82.6855 6.19596 82.9284 5.95316 82.9284 5.65364C82.9284 5.35413 82.6855 5.11133 82.3858 5.11133C82.0862 5.11133 81.8433 5.35413 81.8433 5.65364C81.8433 5.95316 82.0862 6.19596 82.3858 6.19596Z"
        data-id="52fc2d64bc13"
        fill="white" />
      <path
        d="M87.4996 1.08463C87.7992 1.08463 88.0421 0.841828 88.0421 0.542315C88.0421 0.242803 87.7992 0 87.4996 0C87.1999 0 86.957 0.242803 86.957 0.542315C86.957 0.841828 87.1999 1.08463 87.4996 1.08463Z"
        data-id="e59a4707b91a"
        fill="white" />
      <path
        d="M87.4996 1.08463C87.7992 1.08463 88.0421 0.841828 88.0421 0.542315C88.0421 0.242803 87.7992 0 87.4996 0C87.1999 0 86.957 0.242803 86.957 0.542315C86.957 0.841828 87.1999 1.08463 87.4996 1.08463Z"
        data-id="116d4404a9b4"
        fill="white" />
    </g>
  ),
});

export default InsightsCardDots;
