import { createIcon } from '@chakra-ui/icons';

const SearchIcon = createIcon({
  path: (
    <>
      <path
        d="M2.12968 9.85866C2.76081 11.3437 3.95603 12.5172 5.4524 13.121C6.94876 13.7248 8.6237 13.7095 10.1088 13.0784C11.5938 12.4472 12.7673 11.252 13.3711 9.75566C13.9749 8.25929 13.9596 6.58435 13.3285 5.0993C12.6973 3.61425 11.5021 2.44074 10.0057 1.83693C8.50938 1.23312 6.83444 1.24846 5.34939 1.87959C3.86434 2.51073 2.69083 3.70594 2.08702 5.20231C1.4832 6.69867 1.49855 8.37361 2.12968 9.85866V9.85866Z"
        data-id="433ccae0dcde"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.0308 11.7808L16.7495 16.5001"
        data-id="dfdc55177db2"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 18 18',
});

export default SearchIcon;
