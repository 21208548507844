import { createIcon } from '@chakra-ui/icons';

const UnsafeCondition = createIcon({
  viewBox: '0 0 21 23',
  path: (
    <g data-id="e862f81a42e4">
      <path
        d="M17 9L17 7.66667M10.3333 1H2.06667C1.47756 1 1 1.63675 1 2.42222V20.9111C1 21.6966 1.47756 22.3333 2.06667 22.3333L17 22.3333V21M10.3333 1V6.33333C10.3333 7 11 7.66667 11.6667 7.66667H17M10.3333 1L17 7.66667"
        data-id="b5ec64df5c14"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 17H8"
        data-id="b961bcd19d6c"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 14H10"
        data-id="9def4e5607f0"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4 11H11"
        data-id="5abeb6a14e07"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M14.4031 17.688C14.8892 18.2098 15.4974 18.5882 16.3409 18.8895C16.4436 18.9261 16.5564 18.9261 16.6591 18.8895C17.5026 18.5882 18.1108 18.2098 18.5969 17.688C19.9259 16.2613 20 14.1181 20 12.1683C20 12.0782 19.936 12.0009 19.8478 11.9828C18.6904 11.7462 18.0088 11.4421 17.2829 10.7959C16.8378 10.3997 16.1622 10.3997 15.7171 10.7959C14.9912 11.4421 14.3096 11.7462 13.1522 11.9828C13.064 12.0009 13 12.0782 13 12.1683C13 14.1181 13.0741 16.2613 14.4031 17.688Z"
        data-id="2f24bda30317" />
      <path
        d="M15.3333 14.6667L16.1111 15.4444L17.6667 13.8889"
        data-id="3a17080775d4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75" />
    </g>
  ),
});

export default UnsafeCondition;
