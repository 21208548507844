import { createIcon } from '@chakra-ui/icons';

const SendMessageIcon = createIcon({
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M1.58652 6.95988C1.38947 6.9043 1.21542 6.78704 1.08988 6.6253C0.964343 6.46357 0.893925 6.26587 0.888954 6.06119C0.883983 5.85652 0.94472 5.65563 1.06226 5.48799C1.1798 5.32036 1.34795 5.19479 1.54207 5.1297L14.4719 0.912739C14.5566 0.885129 14.6473 0.88144 14.7339 0.90208C14.8206 0.922721 14.8999 0.966888 14.963 1.02971C15.0262 1.09254 15.0708 1.17158 15.0919 1.25813C15.113 1.34468 15.1098 1.43538 15.0826 1.52022L10.8689 14.4565C10.8041 14.6509 10.6786 14.8195 10.5108 14.9373C10.3431 15.055 10.142 15.1159 9.93709 15.1108C9.73218 15.1058 9.53431 15.0351 9.37259 14.9092C9.21086 14.7833 9.09381 14.6088 9.03867 14.4114L7.59179 8.40032L1.58652 6.95988Z"
        data-id="dabb3aae79a7"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M14.9634 1.02881L7.5918 8.40044"
        data-id="b50aec8bf153"
        fill="none"
        stroke="#282F36"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
});

export default SendMessageIcon;
