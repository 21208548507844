import { createIcon } from '@chakra-ui/icons';

const AtTheRateIcon = createIcon({
  viewBox: '0 0 11 11',
  path: (
    <g data-id="ed4f665fc398">
      <path
        d="M7.40885 5.49984C7.40885 5.87745 7.29688 6.24659 7.08708 6.56056C6.87729 6.87454 6.57911 7.11925 6.23023 7.26376C5.88136 7.40827 5.49747 7.44608 5.12711 7.37241C4.75675 7.29874 4.41656 7.1169 4.14954 6.84989C3.88253 6.58287 3.70069 6.24267 3.62702 5.87231C3.55335 5.50195 3.59116 5.11807 3.73567 4.76919C3.88017 4.42032 4.12489 4.12214 4.43886 3.91234C4.75284 3.70255 5.12198 3.59058 5.49959 3.59058C6.00596 3.59058 6.49159 3.79173 6.84964 4.14979C7.2077 4.50784 7.40885 4.99347 7.40885 5.49984Z"
        data-id="b28c631d188e"
        fill="none"
        stroke="#A2171E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M7.40899 5.49991V6.31816C7.40899 6.60751 7.52393 6.88502 7.72853 7.08962C7.93314 7.29422 8.21064 7.40917 8.49999 7.40917C8.78934 7.40917 9.06685 7.29422 9.27145 7.08962C9.47605 6.88502 9.591 6.60751 9.591 6.31816V5.49991C9.59096 4.57837 9.27977 3.68383 8.70785 2.96123C8.13594 2.23864 7.33679 1.73031 6.4399 1.5186C5.54301 1.30689 4.6009 1.40421 3.76623 1.79479C2.93155 2.18537 2.2532 2.84633 1.84107 3.67058C1.42895 4.49483 1.30719 5.43409 1.49553 6.33618C1.68388 7.23827 2.17128 8.05034 2.87878 8.64083C3.58629 9.23132 4.47243 9.56564 5.39366 9.58961C6.31489 9.61359 7.21723 9.32582 7.95449 8.77292"
        data-id="af07acc4092b"
        fill="none"
        stroke="#A2171E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
    </g>
  ),
});

export default AtTheRateIcon;
