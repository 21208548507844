import { createIcon } from '@chakra-ui/icons';

const OpenMenuArrow = createIcon({
  path: (
    <path
        d="M1 1L8 8L15 1"
        data-id="bc9ba95757d7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
  ),
  viewBox: '0 0 16 9',
});

export default OpenMenuArrow;
