import { createIcon } from '@chakra-ui/icons';

const SaveIcon = createIcon({
  path: (
    <>
      <path
        d="M21.5333 23H2.46667C2.07768 23 1.70463 22.8455 1.42958 22.5704C1.15452 22.2954 1 21.9223 1 21.5333V7.34871C1.00017 6.57081 1.30932 5.82482 1.85947 5.27484L5.27387 1.85947C5.54631 1.58695 5.86977 1.37077 6.22578 1.2233C6.58179 1.07583 6.96337 0.999954 7.34871 1H21.5333C21.9223 1 22.2954 1.15452 22.5704 1.42958C22.8455 1.70463 23 2.07768 23 2.46667V21.5333C23 21.9223 22.8455 22.2954 22.5704 22.5704C22.2954 22.8455 21.9223 23 21.5333 23Z"
        data-id="52cba1649293"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.79999 12.7333C9.79999 13.5113 10.109 14.2574 10.6591 14.8075C11.2092 15.3576 11.9554 15.6667 12.7333 15.6667C13.5113 15.6667 14.2574 15.3576 14.8075 14.8075C15.3576 14.2574 15.6667 13.5113 15.6667 12.7333C15.6667 11.9554 15.3576 11.2093 14.8075 10.6592C14.2574 10.109 13.5113 9.8 12.7333 9.8C11.9554 9.8 11.2092 10.109 10.6591 10.6592C10.109 11.2093 9.79999 11.9554 9.79999 12.7333Z"
        data-id="e18b3a1ec498"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8.33334 1V3.93333C8.33334 4.32232 8.48787 4.69537 8.76292 4.97042C9.03797 5.24548 9.41103 5.4 9.80001 5.4H17.1333C17.5223 5.4 17.8954 5.24548 18.1704 4.97042C18.4455 4.69537 18.6 4.32232 18.6 3.93333V1"
        data-id="3c4f3fe9d91c"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />

    </>
  ),
  viewBox: "0 0 24 24",
});

export default SaveIcon;
