import { createIcon } from '@chakra-ui/icons';

const FileRightIcon = createIcon({
  viewBox: '0 0 14 17',
  path: (
    <g data-id="7f429d88dbf3">
      <path
        d="M7.76953 0H1.72656C1.26865 0 0.829492 0.179107 0.505698 0.497918C0.181905 0.81673 0 1.24913 0 1.7V15.3C0 15.7509 0.181905 16.1833 0.505698 16.5021C0.829492 16.8209 1.26865 17 1.72656 17H12.0859C12.5438 17 12.983 16.8209 13.3068 16.5021C13.6306 16.1833 13.8125 15.7509 13.8125 15.3V5.95L7.76953 0Z"
        data-id="7e5fd02d6d66"
        fill="#A2171E" />
      <path
        d="M10.625 7.4375L5.51172 12.75L3.1875 10.3352"
        data-id="110fbe46099b"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default FileRightIcon;
