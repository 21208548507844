import { createIcon } from '@chakra-ui/icons';

const Asterisk = createIcon({
  viewBox: '0 0 9 10',
  displayName: 'Asterisk',
  path: (
    <g data-id="6ec37c53d7bb">
      <path
        d="M0.617518 2.29646L3.3635 3.19912V0H5.6365V3.19912L8.38248 2.29646L9 4.27434L6.20146 5.15044L8.02774 7.79204L6.34599 9L4.51971 6.50443L2.64088 9L0.959124 7.79204L2.79854 5.15044L0 4.27434L0.617518 2.29646Z"
        data-id="fd184119ac21"
        strokeWidth="0.3" />
    </g>
  ),
});

export default Asterisk;
