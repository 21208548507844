import { createIcon } from '@chakra-ui/icons';

const ShareIcon = createIcon({
  path: (
    <>
      <path
        d="M8.82812 5.63037H9.74479C9.98791 5.63037 10.2211 5.72695 10.393 5.89886C10.5649 6.07076 10.6615 6.30392 10.6615 6.54704V13.8804C10.6615 14.1235 10.5649 14.3566 10.393 14.5286C10.2211 14.7005 9.98791 14.797 9.74479 14.797H1.49479C1.25168 14.797 1.01852 14.7005 0.84661 14.5286C0.674702 14.3566 0.578125 14.1235 0.578125 13.8804V6.54704C0.578125 6.30392 0.674702 6.07076 0.84661 5.89886C1.01852 5.72695 1.25168 5.63037 1.49479 5.63037H2.41146"
        data-id="eb6ebb86f235"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.61987 1.04688V7.46354"
        data-id="423e6400a92b"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M3.32812 3.33854L5.61979 1.04688L7.91146 3.33854"
        data-id="67db8b4fee8f"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 12 16',
});

export default ShareIcon;
