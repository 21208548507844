import { createIcon } from '@chakra-ui/icons';

const QuestionDateIcon = createIcon({
  viewBox: '0 0 32 32',
  path: (
    <g data-id="40c7a9dec9f5">
      <path
        d="M15 23C15 25.1217 15.8429 27.1566 17.3431 28.6569C18.8434 30.1571 20.8783 31 23 31C25.1217 31 27.1566 30.1571 28.6569 28.6569C30.1571 27.1566 31 25.1217 31 23C31 20.8783 30.1571 18.8434 28.6569 17.3431C27.1566 15.8429 25.1217 15 23 15C20.8783 15 18.8434 15.8429 17.3431 17.3431C15.8429 18.8434 15 20.8783 15 23Z"
        data-id="90efff224c84"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M23 27V23"
        data-id="08cd852484ed"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M23 19C22.9011 19 22.8044 19.0293 22.7222 19.0843C22.64 19.1392 22.5759 19.2173 22.5381 19.3087C22.5002 19.4 22.4903 19.5006 22.5096 19.5975C22.5289 19.6945 22.5765 19.7836 22.6464 19.8536C22.7164 19.9235 22.8055 19.9711 22.9025 19.9904C22.9994 20.0097 23.1 19.9998 23.1913 19.9619C23.2827 19.9241 23.3608 19.86 23.4157 19.7778C23.4707 19.6956 23.5 19.5989 23.5 19.5C23.5 19.3674 23.4473 19.2402 23.3536 19.1464C23.2598 19.0527 23.1326 19 23 19Z"
        data-id="62a1a6beee92"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M11 23H3C2.46957 23 1.96086 22.7893 1.58579 22.4142C1.21071 22.0392 1 21.5305 1 21V5.02002C1 4.48959 1.21071 3.98088 1.58579 3.60581C1.96086 3.23073 2.46957 3.02002 3 3.02002H21C21.5304 3.02002 22.0391 3.23073 22.4142 3.60581C22.7893 3.98088 23 4.48959 23 5.02002V11"
        data-id="23137020b257"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M1 9H23"
        data-id="b6e8f6dc7d8a"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M6.99072 5V1"
        data-id="3f954ad66582"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M16.9907 5V1"
        data-id="f2d2e34fccfa"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
    </g>
  ),
});

export default QuestionDateIcon;
