import { Icon, IconProps } from '@chakra-ui/icons';

function ExportIcon(props: IconProps) {
  return <Icon data-id="2b417842201a" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2V13.7333"
      data-id="7bd3d9de8439"
      fill="none"
      stroke="#282F36"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M7.60001 9.33334L12 13.7333L16.4 9.33334"
      data-id="f1ea7efd367b"
      fill="none"
      stroke="#282F36"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M23 16.7333V18.2C23 18.978 22.691 19.7241 22.1408 20.2742C21.5907 20.8243 20.8446 21.1333 20.0667 21.1333H3.93333C3.15536 21.1333 2.40926 20.8243 1.85915 20.2742C1.30905 19.7241 1 18.978 1 18.2V16.7333"
      data-id="8be29f1cc8d3"
      fill="none"
      stroke="#282F36"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </Icon>
}

export default ExportIcon;
