import { createIcon } from '@chakra-ui/icons';

const CrossIcon = createIcon({
  viewBox: '0 0 16 15',
  displayName: 'Cross Icon',
  path: (
    <g data-id="b7008e974e07">
      <path
        d="M12.4168 1.58325L1.5835 12.4166"
        data-id="07e827d264cc"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1.5835 1.58325L12.4168 12.4166"
        data-id="71219bf02195"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default CrossIcon;
