import { createIcon } from '@chakra-ui/icons';

const Bar = createIcon({
  displayName: 'Bar',
  viewBox: '0 0 46 2',

  path: (
    <g data-id="7ccc75f71370">
      <line
        data-id="1048ee32eafa"
        stroke="#9A9EA1"
        strokeWidth="2"
        x2="46"
        y1="1"
        y2="1" />
    </g>
  ),
});

export default Bar;
