import { createIcon } from '@chakra-ui/icons';

const Insights = createIcon({
  viewBox: '0 0 14 14',
  path: (
    <g data-id="ae7c930fb41b">
      <path
        d="M9.75989 11.563C9.75989 12.0031 9.9347 12.4251 10.2459 12.7363C10.557 13.0475 10.9791 13.2223 11.4191 13.2223C11.8592 13.2223 12.2812 13.0475 12.5924 12.7363C12.9036 12.4251 13.0784 12.0031 13.0784 11.563V2.43709C13.0784 1.99703 12.9036 1.57499 12.5924 1.26382C12.2812 0.952646 11.8592 0.777832 11.4191 0.777832C10.9791 0.777832 10.557 0.952646 10.2459 1.26382C9.9347 1.57499 9.75989 1.99703 9.75989 2.43709V11.563Z"
        data-id="1d274fa0cc3a"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.20245 11.5631C5.20245 12.0031 5.37727 12.4252 5.68844 12.7363C5.99961 13.0475 6.42165 13.2223 6.86171 13.2223C7.30178 13.2223 7.72382 13.0475 8.03499 12.7363C8.34616 12.4252 8.52097 12.0031 8.52097 11.5631V8.52108C8.52097 8.08101 8.34616 7.65897 8.03499 7.3478C7.72382 7.03663 7.30178 6.86182 6.86171 6.86182C6.42165 6.86182 5.99961 7.03663 5.68844 7.3478C5.37727 7.65897 5.20245 8.08101 5.20245 8.52108V11.5631Z"
        data-id="a506f9986814"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M2.43703 13.2223C3.35341 13.2223 4.09629 12.4795 4.09629 11.5631C4.09629 10.6467 3.35341 9.90381 2.43703 9.90381C1.52065 9.90381 0.777771 10.6467 0.777771 11.5631C0.777771 12.4795 1.52065 13.2223 2.43703 13.2223Z"
        data-id="8acfad55191b"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default Insights;
