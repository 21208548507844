import { createIcon } from '@chakra-ui/icons';

const ProgressIcon = createIcon({
  displayName: 'Progress',
  viewBox: '0 0 14 14',

  path: (
    <g data-id="dd1fb55c5144">
      <path
        d="M2.58535 8.67071C3.36886 8.67071 4.00402 8.03555 4.00402 7.25204C4.00402 6.46853 3.36886 5.83337 2.58535 5.83337C1.80185 5.83337 1.16669 6.46853 1.16669 7.25204C1.16669 8.03555 1.80185 8.67071 2.58535 8.67071Z"
        data-id="aa761ba6d685"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M7.00003 8.67071C7.78353 8.67071 8.41869 8.03555 8.41869 7.25204C8.41869 6.46853 7.78353 5.83337 7.00003 5.83337C6.21652 5.83337 5.58136 6.46853 5.58136 7.25204C5.58136 8.03555 6.21652 8.67071 7.00003 8.67071Z"
        data-id="2d1cc171bb80"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.4147 8.67071C12.1982 8.67071 12.8334 8.03555 12.8334 7.25204C12.8334 6.46853 12.1982 5.83337 11.4147 5.83337C10.6312 5.83337 9.99603 6.46853 9.99603 7.25204C9.99603 8.03555 10.6312 8.67071 11.4147 8.67071Z"
        data-id="96908a9ec65c"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4.00452 7.25208H5.58133"
        data-id="23a06a4f223e"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M8.4187 7.25208H9.99551"
        data-id="4d1dbb4d3c3d"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default ProgressIcon;
