import { createIcon } from '@chakra-ui/icons';

const AuditWhiteIcon = createIcon({
  viewBox: '0 0 16 16',
  path: (
    <g data-id="cd2c529d419d">
      <path
        d="M13.333 12.9556L14.3027 14.895L15.2724 12.9556V6.65256C15.2724 6.39538 15.1702 6.14873 14.9884 5.96688C14.8065 5.78503 14.5599 5.68286 14.3027 5.68286C14.0455 5.68286 13.7989 5.78503 13.617 5.96688C13.4352 6.14873 13.333 6.39538 13.333 6.65256V12.9556Z"
        data-id="a3bee9c29ad9"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M8.48463 3.74358H10.424C10.6812 3.74358 10.9278 3.84574 11.1097 4.0276C11.2916 4.20945 11.3937 4.4561 11.3937 4.71328V13.9254C11.3937 14.1826 11.2916 14.4292 11.1097 14.6111C10.9278 14.7929 10.6812 14.8951 10.424 14.8951H1.69675C1.43957 14.8951 1.19292 14.7929 1.01107 14.6111C0.829215 14.4292 0.727051 14.1826 0.727051 13.9254V4.71328C0.727051 4.4561 0.829215 4.20945 1.01107 4.0276C1.19292 3.84574 1.43957 3.74358 1.69675 3.74358H3.63614C3.63614 3.10063 3.89155 2.48401 4.34619 2.02938C4.80082 1.57475 5.41743 1.31934 6.06038 1.31934C6.70333 1.31934 7.31995 1.57475 7.77458 2.02938C8.22922 2.48401 8.48463 3.10063 8.48463 3.74358V3.74358Z"
        data-id="8a73228d84b8"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M6.0603 3.25879C6.10824 3.25879 6.15511 3.27301 6.19498 3.29964C6.23485 3.32628 6.26592 3.36414 6.28427 3.40844C6.30261 3.45274 6.30742 3.50148 6.29806 3.54851C6.28871 3.59553 6.26562 3.63873 6.23172 3.67263C6.19781 3.70654 6.15462 3.72963 6.10759 3.73898C6.06056 3.74833 6.01182 3.74353 5.96752 3.72518C5.92323 3.70684 5.88536 3.67576 5.85873 3.6359C5.83209 3.59603 5.81787 3.54916 5.81787 3.50121C5.81787 3.43692 5.84341 3.37526 5.88888 3.32979C5.93434 3.28433 5.996 3.25879 6.0603 3.25879"
        data-id="57191cacc345"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
    </g>
  ),
});

export default AuditWhiteIcon;
