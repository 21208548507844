import { createIcon } from '@chakra-ui/icons';

const QuestionMarkIcon = createIcon({
  path: (
    <g data-id="8e9fa9aad8c0">
      <circle
        cx="11.25"
        cy="10.5"
        data-id="d66440abed7a"
        fill="none"
        r="9.75"
        stroke="#fff"
        strokeWidth="1.5" />
      <mask data-id="09ef12b7025c" fill="#fff" id="a">
        <path
          clipRule="evenodd"
          d="M7.969 8.552A3.285 3.285 0 0111.25 5.27a3.285 3.285 0 013.281 3.28 3.287 3.287 0 01-2.46 3.178v1.335a.82.82 0 01-1.641 0v-2.051a.82.82 0 01.82-.82c.905 0 1.64-.737 1.64-1.641 0-.905-.735-1.64-1.64-1.64-.905 0-1.64.735-1.64 1.64a.82.82 0 11-1.641 0zm4.306 6.972a1.025 1.025 0 11-2.05 0 1.025 1.025 0 012.05 0z"
          data-id="f5304e535b73"
          fillRule="evenodd" />
      </mask>
      <path
        clipRule="evenodd"
        d="M7.969 8.552A3.285 3.285 0 0111.25 5.27a3.285 3.285 0 013.281 3.28 3.287 3.287 0 01-2.46 3.178v1.335a.82.82 0 01-1.641 0v-2.051a.82.82 0 01.82-.82c.905 0 1.64-.737 1.64-1.641 0-.905-.735-1.64-1.64-1.64-.905 0-1.64.735-1.64 1.64a.82.82 0 11-1.641 0zm4.306 6.972a1.025 1.025 0 11-2.05 0 1.025 1.025 0 012.05 0z"
        data-id="a736c6319c51"
        fill="#fff"
        fillRule="evenodd" />
      <path
        d="M12.07 11.73l-.375-1.453-1.125.29v1.162h1.5zm-.82-7.96A4.785 4.785 0 006.47 8.553h3c0-.981.8-1.781 1.781-1.781v-3zm4.781 4.782a4.785 4.785 0 00-4.78-4.781v3c.98 0 1.78.8 1.78 1.78h3zm-3.585 4.63a4.787 4.787 0 003.585-4.63h-3c0 .824-.567 1.526-1.336 1.725l.75 2.905zm1.124-.119V11.73h-3v1.335h3zm-2.32 2.32a2.32 2.32 0 002.32-2.32h-3a.68.68 0 01.68-.68v3zm-2.32-2.32a2.32 2.32 0 002.32 2.32v-3a.68.68 0 01.68.68h-3zm0-2.05v2.05h3v-2.05h-3zm2.32-2.32a2.32 2.32 0 00-2.32 2.32h3a.68.68 0 01-.68.68v-3zm.14-.141c0 .076-.064.14-.14.14v3a3.143 3.143 0 003.14-3.14h-3zm-.14-.14c.076 0 .14.064.14.14h3a3.143 3.143 0 00-3.14-3.14v3zm-.14.14c0-.076.064-.14.14-.14v-3a3.142 3.142 0 00-3.14 3.14h3zm-2.32 2.32a2.32 2.32 0 002.32-2.32h-3a.68.68 0 01.68-.68v3zm-2.321-2.32a2.32 2.32 0 002.32 2.32v-3a.68.68 0 01.68.68h-3zm4.781 9.498a2.525 2.525 0 002.525-2.526h-3c0-.262.213-.474.475-.474v3zm-2.525-2.526a2.525 2.525 0 002.525 2.526v-3c.262 0 .475.212.475.474h-3zM11.25 13a2.525 2.525 0 00-2.525 2.525h3a.475.475 0 01-.475.475v-3zm2.525 2.525A2.525 2.525 0 0011.25 13v3a.475.475 0 01-.475-.475h3z"
        data-id="85e3f595aa8e"
        fill="#fff"
        mask="url(#a)" />
    </g>
  ),
  viewBox: '0 0 22 21',
});

export default QuestionMarkIcon;
