import { createIcon } from '@chakra-ui/icons';

const AssetsIcon = createIcon({
  path: (
    <>
      <path
        d="M1 11C1 13.6522 2.05357 16.1957 3.92893 18.0711C5.8043 19.9464 8.34784 21 11 21C13.6522 21 16.1957 19.9464 18.0711 18.0711C19.9464 16.1957 21 13.6522 21 11C21 8.34784 19.9464 5.8043 18.0711 3.92893C16.1957 2.05357 13.6522 1 11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11V11Z"
        data-id="d593bf7bdaf1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 11C5 12.5913 5.63214 14.1174 6.75736 15.2426C7.88258 16.3679 9.4087 17 11 17C12.5913 17 14.1174 16.3679 15.2426 15.2426C16.3679 14.1174 17 12.5913 17 11C17 9.4087 16.3679 7.88258 15.2426 6.75736C14.1174 5.63214 12.5913 5 11 5C9.4087 5 7.88258 5.63214 6.75736 6.75736C5.63214 7.88258 5 9.4087 5 11V11Z"
        data-id="7f71bc6bfc56"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M9 11C9 11.5304 9.21071 12.0391 9.58579 12.4142C9.96086 12.7893 10.4696 13 11 13C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9C10.4696 9 9.96086 9.21071 9.58579 9.58579C9.21071 9.96086 9 10.4696 9 11V11Z"
        data-id="fb17137702a3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.408 9.58048L18.1342 3.99292"
        data-id="7a9bda452e72"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18 15.6667C18.0884 15.6667 18.1732 15.7019 18.2357 15.7644C18.2982 15.8269 18.3333 15.9117 18.3333 16.0001"
        data-id="e58d1a04a497"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M17.6667 16.0001C17.6667 15.9117 17.7019 15.8269 17.7644 15.7644C17.8269 15.7019 17.9117 15.6667 18.0001 15.6667"
        data-id="760be2517ce6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18.0001 16.3333C17.9117 16.3333 17.8269 16.2982 17.7644 16.2357C17.7019 16.1732 17.6667 16.0884 17.6667 16"
        data-id="6a54fc5fd1cd"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18.3333 16C18.3333 16.0884 18.2982 16.1732 18.2357 16.2357C18.1732 16.2982 18.0884 16.3333 18 16.3333"
        data-id="e6831dacd38c"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.66675 12.3333C6.75515 12.3333 6.83994 12.3684 6.90245 12.4309C6.96496 12.4934 7.00008 12.5782 7.00008 12.6666"
        data-id="80ac89cd79b3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.33325 12.6666C6.33325 12.5782 6.36837 12.4934 6.43088 12.4309C6.4934 12.3684 6.57818 12.3333 6.66659 12.3333"
        data-id="2cad7c4d3f85"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.66659 13.0001C6.57818 13.0001 6.4934 12.965 6.43088 12.9025C6.36837 12.8399 6.33325 12.7552 6.33325 12.6667"
        data-id="6a806d4ac22b"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.00008 12.6667C7.00008 12.7552 6.96496 12.8399 6.90245 12.9025C6.83994 12.965 6.75515 13.0001 6.66675 13.0001"
        data-id="4559225c9ee8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12 2.33325C12.0884 2.33325 12.1732 2.36837 12.2357 2.43088C12.2982 2.4934 12.3333 2.57818 12.3333 2.66659"
        data-id="0d9b56093249"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M11.6667 2.66659C11.6667 2.57818 11.7019 2.4934 11.7644 2.43088C11.8269 2.36837 11.9117 2.33325 12.0001 2.33325"
        data-id="2d98682e46cf"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.0001 3.00008C11.9117 3.00008 11.8269 2.96496 11.7644 2.90245C11.7019 2.83994 11.6667 2.75515 11.6667 2.66675"
        data-id="8b5cee2946d3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.3333 2.66675C12.3333 2.75515 12.2982 2.83994 12.2357 2.90245C12.1732 2.96496 12.0884 3.00008 12 3.00008"
        data-id="54ae9ca69e84"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.66675 6.66675C3.75515 6.66675 3.83994 6.70187 3.90245 6.76438C3.96496 6.82689 4.00008 6.91168 4.00008 7.00008"
        data-id="17e28ad24b36"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.33325 7.00008C3.33325 6.91168 3.36837 6.82689 3.43088 6.76438C3.4934 6.70187 3.57818 6.66675 3.66659 6.66675"
        data-id="23f803c86115"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.66659 7.33333C3.57818 7.33333 3.4934 7.29821 3.43088 7.2357C3.36837 7.17319 3.33325 7.08841 3.33325 7"
        data-id="6314da2d17fd"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M4.00008 7C4.00008 7.08841 3.96496 7.17319 3.90245 7.2357C3.83994 7.29821 3.75515 7.33333 3.66675 7.33333"
        data-id="a4b8890d6215"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </>
  ),
  viewBox: '0 0 22 22',
});

export default AssetsIcon;
