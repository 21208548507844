import { createIcon } from '@chakra-ui/icons';

const FollowIcon = createIcon({
  path: (
    <>
      <path
        d="M7.49908 3.93824C5.03566 3.89668 2.48791 5.6182 0.886169 7.38128C0.71804 7.56793 0.625 7.81024 0.625 8.06145C0.625 8.31267 0.71804 8.55497 0.886169 8.74163C2.45308 10.4674 4.9935 12.2287 7.49908 12.1865C10.0047 12.2287 12.5457 10.4674 14.1138 8.74163C14.282 8.55497 14.375 8.31267 14.375 8.06145C14.375 7.81024 14.282 7.56793 14.1138 7.38128C12.5103 5.6182 9.9625 3.89668 7.49908 3.93824Z"
        data-id="be8ddaddf777"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.79079 8.06269C9.79067 8.51592 9.65616 8.95893 9.40427 9.33572C9.15238 9.71251 8.79443 10.0061 8.37566 10.1795C7.9569 10.3529 7.49614 10.3982 7.05163 10.3097C6.60713 10.2212 6.19884 10.0029 5.8784 9.68234C5.55797 9.36181 5.33976 8.95347 5.25139 8.50894C5.16301 8.06441 5.20843 7.60366 5.3819 7.18495C5.55537 6.76623 5.84911 6.40835 6.22596 6.15656C6.60281 5.90478 7.04586 5.77039 7.49909 5.77039C7.80012 5.77031 8.09821 5.82955 8.37632 5.94473C8.65444 6.0599 8.90713 6.22876 9.11996 6.44164C9.33279 6.65453 9.50158 6.90727 9.61668 7.18542C9.73179 7.46356 9.79095 7.76167 9.79079 8.06269Z"
        data-id="7c371e2329e5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 15 16',
});

export default FollowIcon;
