import { createIcon } from '@chakra-ui/icons';

const Copy = createIcon({
  path: (
    <g data-id="2d07812c2e0a">
      <path
        d="M13.3158 3.44141H5.23691C4.24538 3.44141 3.4416 4.24519 3.4416 5.23672V13.3156C3.4416 14.3071 4.24538 15.1109 5.23691 15.1109H13.3158C14.3073 15.1109 15.1111 14.3071 15.1111 13.3156V5.23672C15.1111 4.24519 14.3073 3.44141 13.3158 3.44141Z"
        data-id="fde383d05579"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M2.6842 12.5582C2.20805 12.5582 1.75141 12.369 1.41472 12.0324C1.07804 11.6957 0.888889 11.239 0.888889 10.7629V2.68398C0.888889 2.20784 1.07804 1.75119 1.41472 1.41451C1.75141 1.07782 2.20805 0.888672 2.6842 0.888672H10.7631C11.2392 0.888672 11.6959 1.07782 12.0326 1.41451C12.3693 1.75119 12.5584 2.20784 12.5584 2.68398"
        data-id="20bd0125a1df"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 16 16',
});

export default Copy;
