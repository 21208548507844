import { createIcon } from '@chakra-ui/icons';

const Share = createIcon({
  path: (
    <path
      d="M14.5 6.4a2.7 2.7 0 100-5.4 2.7 2.7 0 000 5.4zM3.7 12.7a2.7 2.7 0 100-5.4 2.7 2.7 0 000 5.4zM14.5 19a2.7 2.7 0 100-5.4 2.7 2.7 0 000 5.4zM6.031 11.359l6.147 3.582M12.17 5.059L6.03 8.64"
      data-id="897f17eac8ba"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5" />
  ),
  viewBox: '0 0 18 20',
});

export default Share;
