import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

import Header from '../components/Header';

function Terms() {
  return <Flex data-id="a24cd4541fa6" direction="column" h="full" w="full">
    <Header
      breadcrumbs={['Home', 'Terms and conditions']}
      data-id="41d6581d9a09"
      mobileBreadcrumbs={['Terms and conditions']} />
    <Flex
      bg="white"
      borderRadius="20px"
      data-id="47576d8445e6"
      flexDirection="column"
      h="full"
      maxWidth="full"
      mb={['25px', '25px']}
      ml="7"
      mr="25px"
      p="25px 30px 25px 30px">
      <Text data-id="f35087369091" fontSize="14px" mb="30px">
        To be added.
      </Text>
    </Flex>
  </Flex>
}

export default Terms;
