import { createIcon } from '@chakra-ui/icons';

const ActionsIcon = createIcon({
  viewBox: '0 0 15 19',
  path: (
    <g data-id="4cd8a77ceaf5">
      <path
        d="M7.3335 8.83325L7.3335 13.4999"
        data-id="bfb746b7f5f9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 11.1667H9.66667"
        data-id="2b03f60d317a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M10.0667 4.16667H12.7333C13.0162 4.16667 13.2875 4.27905 13.4876 4.47909C13.6876 4.67912 13.8 4.95044 13.8 5.23333V16.4333C13.8 16.7162 13.6876 16.9875 13.4876 17.1876C13.2875 17.3876 13.0162 17.5 12.7333 17.5H2.06667C1.78377 17.5 1.51246 17.3876 1.31242 17.1876C1.11238 16.9875 1 16.7162 1 16.4333V5.23333C1 4.95044 1.11238 4.67912 1.31242 4.47909C1.51246 4.27905 1.78377 4.16667 2.06667 4.16667H4.73333C4.73333 3.45942 5.01428 2.78115 5.51438 2.28105C6.01448 1.78095 6.69276 1.5 7.4 1.5C8.10724 1.5 8.78552 1.78095 9.28562 2.28105C9.78571 2.78115 10.0667 3.45942 10.0667 4.16667Z"
        data-id="169e4645762d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.39997 3.6333C7.45271 3.6333 7.50427 3.64894 7.54812 3.67824C7.59197 3.70754 7.62615 3.74919 7.64634 3.79792C7.66652 3.84665 7.6718 3.90026 7.66151 3.95199C7.65122 4.00372 7.62582 4.05124 7.58853 4.08853C7.55124 4.12582 7.50372 4.15122 7.45199 4.16151C7.40026 4.1718 7.34665 4.16652 7.29792 4.14634C7.24919 4.12615 7.20754 4.09197 7.17824 4.04812C7.14894 4.00427 7.1333 3.95271 7.1333 3.89997C7.1333 3.82924 7.1614 3.76142 7.21141 3.71141C7.26142 3.6614 7.32924 3.6333 7.39997 3.6333"
        data-id="8f0dcaad9055"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default ActionsIcon;
