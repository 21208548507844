import { createIcon } from '@chakra-ui/icons';

const Assets = createIcon({
  displayName: 'Assets',
  viewBox: '0 0 22 22',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <g data-id="6a0729a4d6fb">
      <path
        d="M1 11C1 13.6522 2.05357 16.1957 3.92893 18.0711C5.8043 19.9464 8.34784 21 11 21C13.6522 21 16.1957 19.9464 18.0711 18.0711C19.9464 16.1957 21 13.6522 21 11C21 8.34784 19.9464 5.8043 18.0711 3.92893C16.1957 2.05357 13.6522 1 11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11V11Z"
        data-id="0403eb0f2b30"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M5 11C5 12.5913 5.63214 14.1174 6.75736 15.2426C7.88258 16.3679 9.4087 17 11 17C12.5913 17 14.1174 16.3679 15.2426 15.2426C16.3679 14.1174 17 12.5913 17 11C17 9.4087 16.3679 7.88258 15.2426 6.75736C14.1174 5.63214 12.5913 5 11 5C9.4087 5 7.88258 5.63214 6.75736 6.75736C5.63214 7.88258 5 9.4087 5 11V11Z"
        data-id="d87a6e052d3a"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M9 11C9 11.5304 9.21071 12.0391 9.58579 12.4142C9.96086 12.7893 10.4696 13 11 13C11.5304 13 12.0391 12.7893 12.4142 12.4142C12.7893 12.0391 13 11.5304 13 11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9C10.4696 9 9.96086 9.21071 9.58579 9.58579C9.21071 9.96086 9 10.4696 9 11V11Z"
        data-id="da488ef897e6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.408 9.58048L18.1342 3.99292"
        data-id="97faa97e8543"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18 15.6666C18.0884 15.6666 18.1732 15.7017 18.2357 15.7643C18.2982 15.8268 18.3333 15.9116 18.3333 16"
        data-id="425d7c3b7a37"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M17.6667 16C17.6667 15.9116 17.7019 15.8268 17.7644 15.7643C17.8269 15.7017 17.9117 15.6666 18.0001 15.6666"
        data-id="d348fbcb0d8f"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18.0001 16.3333C17.9117 16.3333 17.8269 16.2982 17.7644 16.2357C17.7019 16.1732 17.6667 16.0884 17.6667 16"
        data-id="1cfaa2f2c9e7"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M18.3333 16C18.3333 16.0884 18.2982 16.1732 18.2357 16.2357C18.1732 16.2982 18.0884 16.3333 18 16.3333"
        data-id="c06c9a336d57"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.66675 12.3334C6.75515 12.3334 6.83994 12.3685 6.90245 12.431C6.96496 12.4935 7.00008 12.5783 7.00008 12.6667"
        data-id="53db25c4351c"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.33325 12.6667C6.33325 12.5783 6.36837 12.4935 6.43088 12.431C6.4934 12.3685 6.57818 12.3334 6.66659 12.3334"
        data-id="f61610a8ec56"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M6.66659 13C6.57818 13 6.4934 12.9648 6.43088 12.9023C6.36837 12.8398 6.33325 12.755 6.33325 12.6666"
        data-id="26e1681eaec9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.00008 12.6666C7.00008 12.755 6.96496 12.8398 6.90245 12.9023C6.83994 12.9648 6.75515 13 6.66675 13"
        data-id="ddfcbcebed06"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12 2.33337C12.0884 2.33337 12.1732 2.36849 12.2357 2.43101C12.2982 2.49352 12.3333 2.5783 12.3333 2.66671"
        data-id="d2c6dd32758f"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M11.6667 2.66671C11.6667 2.5783 11.7019 2.49352 11.7644 2.43101C11.8269 2.36849 11.9117 2.33337 12.0001 2.33337"
        data-id="ca67e7b160c4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.0001 2.99996C11.9117 2.99996 11.8269 2.96484 11.7644 2.90233C11.7019 2.83982 11.6667 2.75503 11.6667 2.66663"
        data-id="674394add742"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.3333 2.66663C12.3333 2.75503 12.2982 2.83982 12.2357 2.90233C12.1732 2.96484 12.0884 2.99996 12 2.99996"
        data-id="f9113a66b68e"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.66675 6.66663C3.75515 6.66663 3.83994 6.70175 3.90245 6.76426C3.96496 6.82677 4.00008 6.91155 4.00008 6.99996"
        data-id="d15788d2fefc"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.33325 6.99996C3.33325 6.91155 3.36837 6.82677 3.43088 6.76426C3.4934 6.70175 3.57818 6.66663 3.66659 6.66663"
        data-id="1478911169f8"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M3.66659 7.33333C3.57818 7.33333 3.4934 7.29821 3.43088 7.2357C3.36837 7.17319 3.33325 7.08841 3.33325 7"
        data-id="509426951283"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M4.00008 7C4.00008 7.08841 3.96496 7.17319 3.90245 7.2357C3.83994 7.29821 3.75515 7.33333 3.66675 7.33333"
        data-id="9a7556b0f0c0"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default Assets;
