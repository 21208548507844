import { createIcon } from '@chakra-ui/icons';

const Home = createIcon({
  viewBox: '0 0 14 14',
  path: (
    <g data-id="0e8835f7ede6">
      <path
        d="M0.777771 5.13339L6.99999 0.777832L13.2222 5.13339V11.9778C13.2222 12.6651 12.6031 13.2223 11.8395 13.2223H2.16049C1.39684 13.2223 0.777771 12.6651 0.777771 11.9778V5.13339Z"
        data-id="355e169a3311"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M4.77991 13.2219V6.56152H9.22017V13.2219"
        data-id="f79e851ef0cc"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default Home;
