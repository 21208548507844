import { createIcon } from '@chakra-ui/icons';

const UnFollowIcon = createIcon({
  path: (
    <>
      <path
        d="M1.86514 13.0633L13.3243 2.0625"
        data-id="0bbb80a59c23"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.66653 11.8717C6.25995 12.0569 6.87835 12.1497 7.5 12.1467C10.0057 12.1895 12.5481 10.4294 14.1127 8.69981C14.2815 8.51418 14.375 8.27231 14.375 8.02142C14.375 7.77054 14.2815 7.52867 14.1127 7.34304C13.547 6.71775 12.9182 6.15249 12.2365 5.65625"
        data-id="94ed048dafb1"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M10.6108 4.67197C9.64902 4.17301 8.58343 3.90714 7.5 3.8958C5.05538 3.85302 2.48852 5.57648 0.887296 7.34272C0.71852 7.52834 0.625 7.77022 0.625 8.0211C0.625 8.27198 0.71852 8.51386 0.887296 8.69948C1.75506 9.66958 2.77221 10.4948 3.90029 11.1441"
        data-id="ed25f6d70210"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M5.66655 9.43927C5.33501 8.97932 5.1807 8.41512 5.23201 7.85046C5.28332 7.28581 5.5368 6.75867 5.94581 6.36601C6.35483 5.97336 6.89187 5.7416 7.45815 5.71337C8.02443 5.68514 8.58186 5.86234 9.0279 6.21236"
        data-id="262fa7ccdb47"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M9.79183 8.02148C9.79183 8.32245 9.73255 8.62047 9.61738 8.89853C9.5022 9.17659 9.33339 9.42924 9.12057 9.64206C8.90775 9.85487 8.6551 10.0237 8.37705 10.1389C8.09899 10.254 7.80097 10.3133 7.5 10.3133"
        data-id="387b48e0147c"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 15 16',
  displayName: 'UnFollow',
});

export default UnFollowIcon;
