import { createIcon } from '@chakra-ui/icons';

const EditIcon = createIcon({
  viewBox: '0 0 15 15',
  displayName: 'Edit Icon',
  path: (
    <g data-id="ce4912d5cfc0">
      <path
        d="M8.13748 8.80558L5.86914 9.13007L6.19301 6.86112L12.0264 1.02771C12.2843 0.76986 12.634 0.625 12.9987 0.625C13.1792 0.625 13.358 0.660564 13.5248 0.729662C13.6916 0.798759 13.8432 0.900037 13.9709 1.02771C14.0986 1.15539 14.1998 1.30696 14.2689 1.47378C14.338 1.64059 14.3736 1.81939 14.3736 1.99995C14.3736 2.18051 14.338 2.3593 14.2689 2.52612C14.1998 2.69293 14.0986 2.8445 13.9709 2.97218L8.13748 8.80558Z"
        data-id="17fd0460e0c4"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.3789 1.67627L13.3234 3.62074"
        data-id="1e0cd1bd20d4"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.6245 8.87524V13.4584C11.6245 13.7015 11.5279 13.9346 11.356 14.1065C11.1841 14.2784 10.951 14.375 10.7079 14.375H1.54163C1.29852 14.375 1.06537 14.2784 0.893473 14.1065C0.721573 13.9346 0.625 13.7015 0.625 13.4584V4.29211C0.625 4.04901 0.721573 3.81586 0.893473 3.64396C1.06537 3.47206 1.29852 3.37549 1.54163 3.37549H6.12475"
        data-id="1d6725ca25cf"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default EditIcon;
