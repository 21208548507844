import { createIcon } from '@chakra-ui/icons';

const CircleChecked = createIcon({
  viewBox: '0 0 21 21',
  path: (
    <g data-id="10a289744702">
      <circle cx="10.5" cy="10.5" data-id="66a9d749957f" fill="currentColor" r="10" />
      <path
        d="M14 8L8.5 13.6L6 11.0545"
        data-id="3795a9c9e568"
        fill="currentColor"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default CircleChecked;
