import { createIcon } from '@chakra-ui/icons';

const CircleEmpty = createIcon({
  viewBox: '0 0 21 21',
  path: (
    <g data-id="e18f67f572f3">
      <circle
        cx="10.5"
        cy="10.5"
        data-id="27a460931549"
        fill="white"
        r="10"
        stroke="currentColor"
        strokeWidth="1" />
    </g>
  ),
});

export default CircleEmpty;
