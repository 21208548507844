import { createIcon } from '@chakra-ui/icons';

const Filter = createIcon({
  viewBox: '0 0 12 18',
  path: (
    <g data-id="3394910fa31e">
      <path
        d="M0.5 17L0.499999 1"
        data-id="78f9e3cd5754"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M4.15381 15.2962L4.15381 2.70382"
        data-id="916a51a19c48"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M11.4612 11.3324L11.4612 6.66754"
        data-id="468c3a71ae69"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M7.80737 13.5916L7.80737 4.40836"
        data-id="7b01eabb511f"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default Filter;
