import { createIcon } from '@chakra-ui/icons';

const DangerIcon = createIcon({
  viewBox: '0 0 39 38',
  path: (
    <g data-id="3c4e39c5824b">
      <path
        d="M19.6416 17.2993V19.7018"
        data-id="a5875fa8205b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M14.4937 19.3816L16.2634 21.1434"
        data-id="38dec6eed1ca"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M12.4023 24.5071H14.8156"
        data-id="78e618299d2e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M14.4937 29.6322L16.2634 27.8704"
        data-id="71d2ec697a00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M19.6416 31.7143V29.3118"
        data-id="f6ba6598ef1d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M24.7902 29.6322L23.0205 27.8704"
        data-id="13672b4d3370"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M26.8815 24.5071H24.4683"
        data-id="d8116ac9869e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M24.7902 19.3816L23.0205 21.1434"
        data-id="671949e48dd0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
      <path
        d="M22.0552 2.40372C21.4116 1.12238 19.8028 0.641872 18.5157 1.28254C18.0331 1.60288 17.5504 1.92322 17.3895 2.40372L1.78381 33.156C1.14027 34.4373 1.62292 36.039 2.90999 36.6797C3.23176 36.8398 3.71441 37 4.03618 37H34.9259C36.3738 37 37.5 35.8788 37.5 34.4373C37.5 33.9568 37.3391 33.6365 37.1782 33.3161L22.0552 2.40372Z"
        data-id="35f1f928b57d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5" />
    </g>
  ),
});

export default DangerIcon;
