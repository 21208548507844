import { createIcon } from '@chakra-ui/icons';

const Close = createIcon({
  viewBox: '0 0 16 16',
  path: (
    <g data-id="29a72780372f">
      <path
        d="M14.75 1.25L1.25 14.75"
        data-id="61ed8ab0461a"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1.25 1.25L14.75 14.75"
        data-id="5b53d5f571d4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default Close;
