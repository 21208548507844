import { createIcon } from '@chakra-ui/icons';

const MessageIcon = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <g data-id="eea1f8d0f9c3">
      <path
        d="M12.7239 1.00002C10.8958 0.997379 9.10022 1.48403 7.5236 2.40947C5.94699 3.3349 4.64676 4.6654 3.75785 6.26289C2.86894 7.86038 2.42373 9.66665 2.46844 11.4943C2.51314 13.3219 3.04612 15.1042 4.01207 16.6563L1 22.9824L7.32505 19.9703C8.67399 20.809 10.1993 21.323 11.7807 21.4717C13.3621 21.6205 14.9565 21.4 16.4382 20.8277C17.9199 20.2553 19.2484 19.3467 20.3191 18.1734C21.3898 17.0001 22.1734 15.5942 22.6082 14.0664C23.043 12.5387 23.1171 10.9309 22.8246 9.3696C22.5321 7.80835 21.8811 6.33635 20.9229 5.06957C19.9646 3.80278 18.7252 2.77584 17.3024 2.06964C15.8797 1.36344 14.3123 0.99728 12.7239 1.00002V1.00002Z"
        data-id="2f6b5960c56d"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
});

export default MessageIcon;
