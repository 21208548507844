import { createIcon } from '@chakra-ui/icons';

const ListIcon = createIcon({
  path: (
    <g data-id="a23a2c192fdf">
      <path
        d="M6 1H20"
        data-id="640eea1717a4"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 1H3"
        data-id="7e853c4216c9"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6 5.87158H20"
        data-id="bdf8755e6289"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 5.87158H3"
        data-id="3641a0435eb6"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M6 10.7432H20"
        data-id="7faa96c2abf5"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1 10.7432H3"
        data-id="a29ae4b6901a"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 21 10',
});

export default ListIcon;
