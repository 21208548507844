import { Controller } from 'react-hook-form';

import { Box, Checkbox, Flex, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';

import { Asterisk } from '../../icons';
import { IField } from '../../interfaces/IField';

interface IMultipleChoices extends IField {
  placeholder?: string;
}

function MultipleChoices({
  control,
  name,
  label,
  required,
  tooltip = '',
  disabled = false,
  readMode = false,
  defaultvalue,
}: IMultipleChoices) {
  return <Box data-id="83291a9e33ef" id={name} w="full">
    {label && (
      <Flex
        align="center"
        data-id="265e2519f918"
        justify="space-between"
        mb="none"
        pb={2}
        pt={2}>
        <Box
          color="multipleChoices.labelFont.normal"
          data-id="0fef65a9b897"
          fontSize="ssm"
          fontWeight="bold"
          left="none"
          position="static"
          zIndex={2}>
          {label}
          {required && !readMode && (
            <Asterisk
              data-id="afa3b2a6d0b7"
              fill="questionListElement.iconAsterisk"
              h="9px"
              mb="8px"
              ml="5px"
              stroke="questionListElement.iconAsterisk"
              w="9px" />
          )}{' '}
          {tooltip && (
            <Tooltip data-id="64ab8f18bc92" hasArrow label={tooltip} placement="top">
              <Icon data-id="3b8a86af8606" h="14px" mb={1} name="info" />
            </Tooltip>
          )}
        </Box>
      </Flex>
    )}
    {defaultvalue?.map(({ label }: { label: string; isCorrect: boolean }, index) => (
      <Controller
        control={control}
        data-id="440993b55849"
        key={index}
        name={`${name}.${index}.isCorrect`}
        render={({ field }) => {
          const { onChange, value } = field;
          return (
            (<Stack data-id="7cf86a7868c6" direction="column">
              <Checkbox
                borderColor="multipleChoices.icon.border"
                colorScheme="form.checkbox.icon"
                css={{
                  '.chakra-checkbox__control': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                    width: '21px',
                    height: '21px',
                  },
                  '.chakra-checkbox__label': {
                    opacity: `${readMode ? 1 : disabled ? 0.4 : 1} !important`,
                  },
                }}
                data-id="fb2e5aff84d8"
                isChecked={value}
                isDisabled={disabled}
                onChange={() => onChange(!value)}
                py={1}>
                <Text
                  color={readMode ? 'multipleChoices.optionFont.readMode' : 'multipleChoices.optionFont.normal'}
                  data-id="f1c364f12de2"
                  fontSize="smm">
                  {label}{' '}
                </Text>
                {tooltip && (
                  <Tooltip data-id="bda78b83c857" hasArrow label={tooltip} placement="top">
                    <Icon data-id="8f69b3ada218" h="14px" mb={1} name="info" />
                  </Tooltip>
                )}
              </Checkbox>
            </Stack>)
          );
        }} />
    ))}
  </Box>
}

export default MultipleChoices;

export const multipleChoicesStyles = {
  multipleChoices: {
    labelFont: {
      normal: '#2B3236',
    },
    optionFont: {
      normal: '#818197',
      readMode: '#000',
    },
    iconAsterisk: '#E93C44',
    icon: {
      border: '#CBCCCD',
    },
  },
};
