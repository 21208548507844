import { createIcon } from '@chakra-ui/icons';

const InvestigationWhiteIcon = createIcon({
  viewBox: '0 0 15 16',
  path: (
    <g data-id="65a7666f42c1">
      <path
        d="M1.45461 2.77905V7.70103C1.45462 9.25677 1.92607 10.776 2.8068 12.0584C3.68754 13.3409 4.93621 14.3263 6.38823 14.8849L7.06117 15.1435C7.50931 15.3158 8.00546 15.3158 8.4536 15.1435L9.12654 14.8849C10.5786 14.3263 11.8272 13.3409 12.708 12.0584C13.5887 10.776 14.0601 9.25677 14.0602 7.70103V2.77905C14.0614 2.59383 14.0091 2.4122 13.9096 2.256C13.81 2.09981 13.6675 1.9757 13.499 1.8986C11.6889 1.10851 9.73235 0.709578 7.75738 0.727898C5.78242 0.709578 3.82585 1.10851 2.01572 1.8986C1.84731 1.9757 1.70476 2.09981 1.60521 2.256C1.50567 2.4122 1.45337 2.59383 1.45461 2.77905V2.77905Z"
        data-id="0b8f9a9a718d"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M4.36377 6.54593C4.36377 7.31744 4.67025 8.05735 5.21579 8.60289C5.76133 9.14843 6.50123 9.45491 7.27274 9.45491C8.04425 9.45491 8.78416 9.14843 9.32969 8.60289C9.87523 8.05735 10.1817 7.31744 10.1817 6.54593C10.1817 5.77443 9.87523 5.03452 9.32969 4.48898C8.78416 3.94344 8.04425 3.63696 7.27274 3.63696C6.50123 3.63696 5.76133 3.94344 5.21579 4.48898C4.67025 5.03452 4.36377 5.77443 4.36377 6.54593V6.54593Z"
        data-id="10bec76c3a45"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
      <path
        d="M9.32959 8.60278L10.9088 10.182"
        data-id="c03e595fb1b3"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2" />
    </g>
  ),
});

export default InvestigationWhiteIcon;
