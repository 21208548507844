import { Icon, IconProps } from '@chakra-ui/icons';

function RedirectIcon(props: IconProps) {
  return <Icon data-id="afe85114505c" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M5 18.4669L18.6889 5"
      data-id="86816eb34699"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M18.6889 12.6961V5H10.8667"
      data-id="0d4d4e80ca98"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </Icon>
}

export default RedirectIcon;
