import { createIcon } from '@chakra-ui/icons';

const UploadIcon = createIcon({
  path: (
    <g data-id="56fee60ab163">
      <path
        d="M16.0554 9.86659L12.4999 6.31104L8.94434 9.86659"
        data-id="d44f7eb630b8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.5 16.9777V6.31104"
        data-id="73fcb0449fff"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M1.83325 11.9999C1.83325 14.8289 2.95706 17.542 4.95745 19.5424C6.95783 21.5428 9.67094 22.6666 12.4999 22.6666C15.3289 22.6666 18.042 21.5428 20.0424 19.5424C22.0428 17.542 23.1666 14.8289 23.1666 11.9999C23.1666 9.17094 22.0428 6.45783 20.0424 4.45745C18.042 2.45706 15.3289 1.33325 12.4999 1.33325C9.67094 1.33325 6.95783 2.45706 4.95745 4.45745C2.95706 6.45783 1.83325 9.17094 1.83325 11.9999V11.9999Z"
        data-id="d2ffcfd35c8e"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 25 24',
});

export default UploadIcon;
